import React, { ComponentProps, useEffect, useState } from 'react'
import styled from 'styled-components'

import { QuestionnaireStateProvider, useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'
import { Questionnaire, QuestionnaireSummarySaved, questionnaireKeyLookup } from 'shared/questionnaires/types'

import Modal from 'app/Modal'
import { ConfirmModal } from 'common/ConfirmModal'
import { SpinnerWithLabel } from 'common/Spinner'
import { AppBackground, Button, Column, P, Padding, Panel, Row, Spacer } from 'common/ui'

import { userTypeThemeMap } from 'dashboards/public/PublicQuestionnaire'
import { QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'

import { deleteQuestionnaire, updateQuestionnaire } from 'api'
import { useUserState } from 'app/UserState'
import { LinkButton } from 'common/LinkButton'
import { observationalCodeQuestionnaire } from 'dashboards/constant/observationalCode'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { RichTextContainer } from 'session/common/RichText'
import { facilitatorDashboardBaseUrl } from '../constants'

interface Props {
  questionnaireUserData: QuestionnaireSummarySaved
  onClose: () => void
  disableDelete?: boolean
  disableOrphan?: boolean
  onDelete?: () => void
  onOrphan?: () => void
  isOrphan?: boolean
  disablePrint?: boolean
}

export const QuestionnaireQuickViewModal: React.FC<Props> = (props) => (
  <QuestionnaireStateProvider>
    <QuestionnaireQuickViewModalMain {...props} />
  </QuestionnaireStateProvider>
)

export const QuestionnaireQuickViewModalMain: React.FC<Props> = ({
  questionnaireUserData,
  onClose,
  onDelete,
  isOrphan,
  onOrphan,
  disableDelete,
  disableOrphan,
  disablePrint,
}) => {
  const { questionnaire_type: questionnaireKey, questionnaire_booklet } = questionnaireUserData
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in QuestionnaireQuickViewModalMain: ${questionnaireKey}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in QuestionnaireQuickViewModalMain: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { type: questionnaireType, userType } = questionnaireKeyLookup[questionnaireKey]
  const { accessToken } = useUserState()
  const [deleting, setDeleting] = useState<boolean>(false)
  const [orphaning, setOrphaning] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showOrphanModal, setShowOrphanModal] = useState<boolean>(false)
  const { dispatch, state: userData, setQuestionnaireData } = useQuestionnaireState()
  const [_questionnaire, { loading: loadingQuestionnaire, errorLoading: errorLoadingQuestionnaire }] =
    useEndpoint<Questionnaire>(`/api/v1/questionnaires/${questionnaire_booklet}/${userType}/${questionnaireType}`)
  const questionnaire =
    questionnaireUserData.questionnaire_type === 'observational-code' ? observationalCodeQuestionnaire : _questionnaire

  useEffect(() => {
    dispatch({ type: UPDATE_STATE, state: questionnaireUserData })
  }, [dispatch, questionnaireUserData])

  useEffect(() => {
    if (questionnaireUserData.questionnaire_type === 'observational-code') {
      setQuestionnaireData(observationalCodeQuestionnaire)
    } else {
      if (questionnaire) setQuestionnaireData(questionnaire)
    }
  }, [questionnaire, questionnaireUserData, setQuestionnaireData])

  const handleOrphan: ComponentProps<typeof ConfirmModal>['onSubmit'] = (confirmed) => {
    setShowOrphanModal(false)
    if (!confirmed) return
    setOrphaning(true)
    updateQuestionnaire(
      { ...questionnaireUserData, cadet_mentor_id: null, mentor_id: null, drupal_user_id: null },
      accessToken
    )
      .then(() => {
        console.log('orphaned!')
        setOrphaning(false)
        onOrphan && onOrphan()
      })
      .catch((e) => {
        alert(e.message || 'Failed to unlink questionnaire')
        setOrphaning(false)
      })
  }

  const handleDelete: ComponentProps<typeof ConfirmModal>['onSubmit'] = (confirmed) => {
    setShowDeleteModal(false)
    if (!confirmed) return
    setDeleting(true)
    deleteQuestionnaire(questionnaireUserData, accessToken)
      .then(() => {
        setDeleting(false)
        onDelete && onDelete()
      })
      .catch(() => {
        alert('Failed to delete questionnaire submission')
        setDeleting(false)
      })
  }

  const disableActions = !questionnaire && (loadingQuestionnaire || errorLoadingQuestionnaire)

  return (
    <>
      <Modal isOpen onRequestClose={onClose}>
        <Panel style={{ overflow: 'hidden', width: 960, maxWidth: 'calc(100vw - 40px)' }}>
          <QuestionnaireContainer>
            <AppBackground theme={userTypeThemeMap[userType!]}>
              <Padding size="m" style={{ flexDirection: 'column' }}>
                {!questionnaire && loadingQuestionnaire ? (
                  <SpinnerWithLabel label="Loading questionnaire content..." />
                ) : !questionnaire && errorLoadingQuestionnaire ? (
                  <P>Error loading questionnaire content!</P>
                ) : (
                  <QuestionnaireContent
                    userType={userType!}
                    booklet={userData.questionnaire_booklet || 1}
                    standalone
                    forceReadOnly
                  />
                )}
              </Padding>
            </AppBackground>
          </QuestionnaireContainer>
          <Padding size="m">
            <Row alignItems="flex-end">
              <Column flex="0 0 33.33%">
                {!disableOrphan && (
                  <div>
                    <Button
                      size="s"
                      theme="orange"
                      disabled={disableActions || orphaning}
                      children={orphaning ? 'Unlinking...' : 'Unlink'}
                      onClick={() => setShowOrphanModal(true)}
                    />
                  </div>
                )}
                {!disableDelete && !disableOrphan && <Spacer size="s" />}
                {!disableDelete && (
                  <div>
                    <Button
                      size="xs"
                      theme="red"
                      disabled={disableActions || deleting}
                      children={deleting ? 'Deleting...' : 'Delete'}
                      onClick={() => setShowDeleteModal(true)}
                    />
                  </div>
                )}
              </Column>
              <Column flex="0 0 33.33%" alignItems="center">
                <div>
                  <Button size="m" theme="grey" children="Close" onClick={() => onClose()} />
                </div>
              </Column>
              <Column flex="0 0 33.33%" alignItems="flex-end">
                <div>
                  {!disablePrint && (
                    <LinkButton
                      size="s"
                      theme="blue"
                      disabled={disableActions}
                      children="Print / Download"
                      to={
                        isOrphan
                          ? `${facilitatorDashboardBaseUrl}/print-questionnaire/unlinked/${questionnaireUserData.id}/${
                              userData.mentor_id || 'cadet'
                            }/${userData.questionnaire_booklet || 1}/${userData.questionnaire_type}`
                          : `${facilitatorDashboardBaseUrl}/print-questionnaire/${userData.cadet_mentor_id}/${
                              userData.mentor_id || 'cadet'
                            }/${userData.questionnaire_booklet || 1}/${userData.questionnaire_type}`
                      }
                      target="_blank"
                      rel="noopener"
                    />
                  )}
                </div>
              </Column>
            </Row>
          </Padding>
        </Panel>
      </Modal>
      {showDeleteModal && (
        <ConfirmModal
          isOpen
          reverseButtons
          width={520}
          confirmText="Confirm Deletion"
          label={
            <RichTextContainer>
              <p style={{ marginBottom: 15 }}>
                Are you sure you want to <strong>delete</strong> this questionnaire?
                <br />
                Once deleted, it will be permanently lost. Deleting is best for incorrect/void questionnaires or other
                ‘housekeeping’ purposes.
                <br />
                If this questionnaire is linked to a Cadet, you may like to <strong>unlink</strong> the questionnaire
                for later use.
              </p>
            </RichTextContainer>
          }
          onSubmit={handleDelete}
        />
      )}
      {showOrphanModal && (
        <ConfirmModal
          isOpen
          reverseButtons
          width={520}
          label={
            <RichTextContainer>
              <p>
                Are you sure you want to <strong>unlink</strong> this questionnaire?
                <br />
                This will move the questionnaire to the unlinked list and can be relinked afterwards.
                <br />
                The primary purpose of unlinking is to relocate it or link it elsewhere. For example:
              </p>
              <ul style={{ marginBottom: 15 }}>
                <li>It was linked to the wrong cadet</li>
                <li>It was linked to the wrong Booklet number</li>
              </ul>
            </RichTextContainer>
          }
          onSubmit={handleOrphan}
        />
      )}
    </>
  )
}

const QuestionnaireContainer = styled.div`
  width: 100%;
  max-height: 500px;
  overflow: auto;
`
