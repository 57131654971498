import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { purple } from 'themes'

import { GetProfile } from 'api'
import { GadgetPackKey } from 'shared/session/types'
import { GadgetTile, gadgetTiles } from 'gadget-pack/GadgetPack'

import { CollapseMargin, Container, H2, P, Panel, RowWrapPortrait } from 'common/ui'
import { GadgetModal } from 'session/gadget-tray/GadgetTray'
import { HeaderTemplate } from 'gadget-pack/common/HeaderTemplate'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

import { useUserState, UserStateContext } from 'app/UserState'
import { useMentorDashState, useMentorDashData, MentorDashStateProvider } from './MentorDashboardState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useGadgetPackState, GadgetPackStateProvider } from 'gadget-pack/GadgetPackState'
import { useProgressionEventsState, ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { mentorDashboardBaseUrl } from './constants'

export const MentorGadgetPack: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ThemeProvider theme={purple}>
      <MentorDashStateProvider>
        <MentorGadgetPackInner />
      </MentorDashStateProvider>
    </ThemeProvider>
  )
}

const MentorGadgetPackInner: React.FC = () => {
  const userState = useUserState()
  const { mentorUid } = useMentorDashState()
  const [primaryMentor] = useMentorDashData('primaryMentor')

  const [
    cadetProfile,
    { loading: loadingCadetProfile, loaded: loadedCadetProfile, errorMessage: cadetProfileErrorMessage },
  ] = useEndpoint<GetProfile>(`/api/v1/mentors/${mentorUid}/cadet`)

  const cadetName =
    primaryMentor && primaryMentor.cadet_first_name
      ? `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}`
      : ''

  return (
    <Main>
      {loadingCadetProfile ? (
        <CenteredBody>
          <LoadingWindow loadingCadet loadingGadgetPack loadingProgressionEvents />
        </CenteredBody>
      ) : !loadedCadetProfile || !cadetProfile ? (
        <CenteredBody>
          <Panel padding="l">
            <CollapseMargin>
              {cadetProfileErrorMessage === 'NO-PROFILE' ? (
                <P>This option will be available once the cadet commences their program</P>
              ) : (
                <>
                  <H2>Could not load cadet profile</H2>
                  <P>{cadetProfileErrorMessage}</P>
                </>
              )}
            </CollapseMargin>
          </Panel>
        </CenteredBody>
      ) : (
        <UserStateContext.Provider
          value={{
            ...userState,
            authProvider: 'sas',
            gameMode: 'SAS-SG-D',
            profileId: cadetProfile.id,
            profiles: [cadetProfile],
            profileName: cadetProfile.profile_name || cadetName,
          }}>
          <ProgressionEventsStateProvider>
            <GadgetPackStateProvider>
              <GadgetTilesWrapper />
            </GadgetPackStateProvider>
          </ProgressionEventsStateProvider>
        </UserStateContext.Provider>
      )}
    </Main>
  )
}

const getEndpointResource = (loading: boolean) => ({
  loading,
  loaded: !loading,
  errorLoading: false,
  errorMessage: null,
  fetch: () => new Promise<unknown>(() => {}),
})

export const LoadingWindow: React.FC<{
  loadingCadet: boolean
  loadingGadgetPack: boolean
  loadingProgressionEvents: boolean
}> = ({ loadingCadet, loadingGadgetPack, loadingProgressionEvents }) => {
  return (
    <ResourceLoadingStatusPanel
      title="Loading Gadget Pack..."
      resources={[
        { endpoint: getEndpointResource(loadingCadet), label: 'Loading cadet profile...' },
        { endpoint: getEndpointResource(loadingGadgetPack), label: 'Loading gadget pack data...' },
        { endpoint: getEndpointResource(loadingProgressionEvents), label: 'Loading cadet progression data...' },
      ]}
    />
  )
}

const GadgetTilesWrapper: React.FC = () => {
  const history = useHistory()
  const [activeModal, setActiveModal] = useState<GadgetPackKey | null>(null)
  const { loadingInitialState } = useGadgetPackState()
  const { loadingProgressionEvents } = useProgressionEventsState()
  if (loadingInitialState || loadingProgressionEvents)
    return (
      <CenteredBody>
        <LoadingWindow
          loadingCadet={false}
          loadingGadgetPack={loadingInitialState}
          loadingProgressionEvents={loadingProgressionEvents}
        />
      </CenteredBody>
    )
  return (
    <>
      <CenteredBody>
        <GadgetPackWrapper>
          <HeaderTemplate title="Gadgets" onExit={() => history.push(`${mentorDashboardBaseUrl}`)}>
            <RowWrapPortrait flexWrap style={{ width: '100%' }}>
              {gadgetTiles.map((gadgetTile, i) => (
                <GadgetTile
                  key={gadgetTile.path}
                  gadgetTile={gadgetTile}
                  baseUrl={mentorDashboardBaseUrl}
                  onClick={(gadgetTile) =>
                    setActiveModal(
                      `${gadgetTile.eventContext.category}${
                        gadgetTile.eventContext.subcategory ? `/${gadgetTile.eventContext.subcategory}` : ''
                      }` as GadgetPackKey
                    )
                  }
                />
              ))}
            </RowWrapPortrait>
          </HeaderTemplate>
        </GadgetPackWrapper>
      </CenteredBody>
      {activeModal && (
        <GadgetModal
          gadgetKey={activeModal}
          onClose={() => setActiveModal(null)}
          autoSelectEmotionometer
          mentorMode
          readOnly
        />
      )}
    </>
  )
}

const Main = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: #464b61;
  display: flex;
  flex-direction: column;

  ${Container} {
    width: 100%;
    max-width: 1024px;
  }
`

const CenteredBody = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GadgetPackWrapper = styled.div`
  & .gadget-header-container {
    padding-left: 10px;
    padding-right: 10px;
    & h1 {
      color: white;
    }
  }
`
