import styled, { css } from 'styled-components'
import { SectionPanel } from 'shared/session/types'
import { fontBold } from 'fonts'
import { themeStyleOptions } from './SectionsPanel'

export const PanelTitle = styled.div<{ panelTheme: Exclude<SectionPanel['theme'], null> }>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  padding: 25px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${(p) => themeStyleOptions[p.panelTheme].header_background};
  box-shadow: 0px -4px 10px rgba(255, 255, 255, 0.44);
  & h1 {
    color: ${(p) => themeStyleOptions[p.panelTheme].header_text_color} !important;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0.03em;
    ${(p) =>
      p.panelTheme === 'parentGreen'
        ? css`
            font-style: italic;
          `
        : ''}
  }
`

const WithPin = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background-image: url(${require('../assets/pin.svg')?.default});
    background-size: 100% 100%;
  }
`

export const CorkTitle = styled(WithPin)`
  width: fit-content;
  margin-top: 80px;
  margin-left: 100px;
  margin-bottom: -50px;
  padding: 20px 70px;
  background-color: #fff;
  transform: rotateZ(-6deg);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  & h1 {
    ${fontBold}
    display: inline-block;
    text-transform: initial;
    font-size: 42px;
  }
`

export const CorkSection = styled(WithPin)`
  padding: 45px 40px;
  background-color: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  margin-top: 25px;
`
