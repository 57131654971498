import { EmotionometerColors } from 'common/Emotionometer'

export type ThemeName =
  | 'blue'
  | 'purple'
  | 'pink'
  | 'orange'
  | 'teal'
  | 'gray'
  | 'white'
  | 'dark'
  | 'red'
  | 'parentGreen'

export interface Theme {
  name: string
  panelBg: string
  panelShadowColor: string
  borderRadius: string
  thinOutlineColor: string
  thinOutlineActiveColor: string
  darkBg: string
  highlightColor: string

  journalBackgroundBottomColor: string
  journalBackgroundTopColor: string

  appBackgroundTopColor: string
  appBackgroundBottomColor: string
  appPanelShadowColor: string

  buttonTextColor: string
  buttonBackgroundTopColor: string
  buttonBackgroundBottomColor: string
  buttonBorderTopColor: string
  buttonBorderBottomColor: string

  buttonFocusBackgroundTopColor: string
  buttonFocusBackgroundBottomColor: string
  buttonFocusBorderTopColor: string
  buttonFocusBorderBottomColor: string

  emotionometerColors: EmotionometerColors
  tipsheetPastelColor: string
}

const DEFAULTS = {
  borderRadius: '15px',
  panelBg: '#edf2fa',
  darkBg: '#001947',
  panelShadowColor: 'rgba(0,0,0,0.3)',
  buttonTextColor: 'white',
  journalBackgroundBottomColor: '#011A46 ',
  journalBackgroundTopColor: '#476498',
  highlightColor: 'yellow',
  thinOutlineColor: '#adb4d8',
  thinOutlineActiveColor: '#6777be',
}
export const gray: Theme = {
  name: 'gray',

  ...DEFAULTS,

  appBackgroundTopColor: '#a0a0a0',
  appBackgroundBottomColor: '#5c5c5c',
  appPanelShadowColor: '#5a5a5a',

  buttonBackgroundTopColor: '#4e4e4e',
  buttonBackgroundBottomColor: '#777777',
  buttonBorderTopColor: '#777777',
  buttonBorderBottomColor: '#4f4f4f',

  // focus colors are above with 10% black mixed in
  buttonFocusBackgroundTopColor: '#444444',
  buttonFocusBackgroundBottomColor: '#6c6c6c',
  buttonFocusBorderTopColor: '#6c6c6c',
  buttonFocusBorderBottomColor: '#434343',

  emotionometerColors: ['#9b9b9b', '#6d6d6d', '#444444'],
  tipsheetPastelColor: '#dedede',
}

export const blue: Theme = {
  name: 'blue',

  ...DEFAULTS,

  appBackgroundTopColor: '#4adaf4',
  appBackgroundBottomColor: '#1c8cde',
  appPanelShadowColor: '#4181d0',

  buttonBackgroundTopColor: '#177cd2',
  buttonBackgroundBottomColor: '#22acf3',
  buttonBorderTopColor: '#22acf3',
  buttonBorderBottomColor: '#0280d2',

  // focus colors are above with 10% black mixed in
  buttonFocusBackgroundTopColor: '#0471ce',
  buttonFocusBackgroundBottomColor: '#0ca1ee',
  buttonFocusBorderTopColor: '#0ca1ee',
  buttonFocusBorderBottomColor: '#0073bf',

  emotionometerColors: ['#47d6f3', '#259fe0', '#0471ce'],
  tipsheetPastelColor: '#ccedff',
}

export const purple: Theme = {
  name: 'purple',

  ...DEFAULTS,

  appBackgroundTopColor: '#8ac2ff',
  appBackgroundBottomColor: '#766dff',
  appPanelShadowColor: '#866ae0',

  buttonBackgroundTopColor: '#705ef0',
  buttonBackgroundBottomColor: '#7f8dff',
  buttonBorderTopColor: '#7f8dff',
  buttonBorderBottomColor: '#6661f8',

  buttonFocusBackgroundTopColor: '#6851eb',
  buttonFocusBackgroundBottomColor: '#757fff',
  buttonFocusBorderTopColor: '#757fff',
  buttonFocusBorderBottomColor: '#5c56e0',

  emotionometerColors: ['#88bbfd', '#7784f4', '#6851eb'],
  tipsheetPastelColor: '#fff', // not used
}

export const trainingPurple: Theme = {
  name: 'trainingPurple',

  ...DEFAULTS,

  appBackgroundTopColor: '#A873FF',
  appBackgroundBottomColor: '#7D41DF',
  appPanelShadowColor: '#866ae0',

  buttonBackgroundTopColor: '#6737B5',
  buttonBackgroundBottomColor: '#9A60F8',
  buttonBorderTopColor: '#7D41DF',
  buttonBorderBottomColor: '#7D41DF',

  buttonFocusBackgroundTopColor: '#522996',
  buttonFocusBackgroundBottomColor: '#8954E1',
  buttonFocusBorderTopColor: '#6131AF',
  buttonFocusBorderBottomColor: '#6131AF',

  emotionometerColors: ['#88bbfd', '#7784f4', '#6851eb'],
  tipsheetPastelColor: '#fff', // not used
}

export const pink: Theme = {
  name: 'pink',

  ...DEFAULTS,

  appBackgroundTopColor: '#ff9dff',
  appBackgroundBottomColor: '#eb51c5',
  appPanelShadowColor: '#d759a4',

  buttonBackgroundTopColor: '#dd45ae',
  buttonBackgroundBottomColor: '#ff68f0',
  buttonBorderTopColor: '#ff68f0',
  buttonBorderBottomColor: '#df42bc',

  buttonFocusBackgroundTopColor: '#da37a4',
  buttonFocusBackgroundBottomColor: '#fe5be7',
  buttonFocusBorderTopColor: '#fe5be7',
  buttonFocusBorderBottomColor: '#cb3aa9',

  emotionometerColors: ['#fd96f8', '#eb66ce', '#da37a4'],
  tipsheetPastelColor: '#ffc2fc',
}

export const orange: Theme = {
  name: 'orange',

  ...DEFAULTS,

  appBackgroundTopColor: '#ff9bb3',
  appBackgroundBottomColor: '#f1603c',
  appPanelShadowColor: '#cd693a',

  buttonBackgroundTopColor: '#FF4C00',
  buttonBackgroundBottomColor: '#FFAB48',
  buttonBorderTopColor: '#FF8A00',
  buttonBorderBottomColor: '#FF4D00',

  buttonFocusBackgroundTopColor: '#E54400',
  buttonFocusBackgroundBottomColor: '#FF9C29',
  buttonFocusBorderTopColor: '#F08100',
  buttonFocusBorderBottomColor: '#E54400',

  emotionometerColors: ['#fe98ad', '#e86e60', '#d64a12'],
  tipsheetPastelColor: '#ffd7a7',
}

export const teal: Theme = {
  name: 'teal',

  ...DEFAULTS,

  appBackgroundTopColor: '#40e59e',
  appBackgroundBottomColor: '#00a38d',
  appPanelShadowColor: '#0d9497',

  buttonBackgroundTopColor: '#009286',
  buttonBackgroundBottomColor: '#00c197',
  buttonBorderTopColor: '#00c197',
  buttonBorderBottomColor: '#00977e',

  buttonFocusBackgroundTopColor: '#00887f',
  buttonFocusBackgroundBottomColor: '#00b78e',
  buttonFocusBorderTopColor: '#00b78e',
  buttonFocusBorderBottomColor: '#008872',

  emotionometerColors: ['#3cde9b', '#1fb08d', '#00877e'],
  tipsheetPastelColor: '#fff', // not used
}

export const white: Theme = {
  name: 'white',

  ...DEFAULTS,

  appBackgroundTopColor: '#fff',
  appBackgroundBottomColor: '#eee',
  appPanelShadowColor: '#aaa',

  buttonTextColor: '#333',
  buttonBackgroundTopColor: '#eee',
  buttonBackgroundBottomColor: '#fff',
  buttonBorderTopColor: '#fff',
  buttonBorderBottomColor: '#ccc',

  buttonFocusBackgroundTopColor: '#ddd',
  buttonFocusBackgroundBottomColor: '#fff',
  buttonFocusBorderTopColor: '#fff',
  buttonFocusBorderBottomColor: '#ccc',

  emotionometerColors: ['#fff', '#ddd', '#bbb'],
  tipsheetPastelColor: '#fff', // not used
}

export const green: Theme = {
  name: 'green',

  ...DEFAULTS,

  appBackgroundTopColor: '#9ed63f',
  appBackgroundBottomColor: '#29a100',
  appPanelShadowColor: '#2e9831',

  buttonBackgroundTopColor: '#169200',
  buttonBackgroundBottomColor: '#4db900',
  buttonBorderTopColor: '#4db900',
  buttonBorderBottomColor: '#1a9300',

  buttonFocusBackgroundTopColor: '#008a00',
  buttonFocusBackgroundBottomColor: '#3ab000',
  buttonFocusBorderTopColor: '#3ab000',
  buttonFocusBorderBottomColor: '#148500',

  // fake
  emotionometerColors: ['#3cde9b', '#1fb08d', '#00877e'],
  tipsheetPastelColor: '#fff', // not used
}

export const dark: Theme = {
  name: 'dark',

  ...DEFAULTS,

  appBackgroundTopColor: '#476498',
  appBackgroundBottomColor: '#011A46',
  appPanelShadowColor: '#ffffff',

  buttonBackgroundTopColor: '#177cd2',
  buttonBackgroundBottomColor: '#22acf3',
  buttonBorderTopColor: '#22acf3',
  buttonBorderBottomColor: '#0280d2',

  // focus colors are above with 10% black mixed in
  buttonFocusBackgroundTopColor: '#0471ce',
  buttonFocusBackgroundBottomColor: '#0ca1ee',
  buttonFocusBorderTopColor: '#0ca1ee',
  buttonFocusBorderBottomColor: '#0073bf',

  emotionometerColors: ['#47d6f3', '#259fe0', '#0471ce'],
  tipsheetPastelColor: '#fff', // not used
}

export const red: Theme = {
  name: 'red',

  ...DEFAULTS,

  appBackgroundTopColor: '#ED5C61',
  appBackgroundBottomColor: '#CE1219',
  appPanelShadowColor: '#ffffff',

  buttonBackgroundTopColor: '#CE1219',
  buttonBackgroundBottomColor: '#ED5C61',
  buttonBorderTopColor: '#CE1219',
  buttonBorderBottomColor: '#CD0219',

  // focus colors are above with 10% black mixed in
  buttonFocusBackgroundTopColor: '#BE1219',
  buttonFocusBackgroundBottomColor: '#ED5C61',
  buttonFocusBorderTopColor: '#ED5C61',
  buttonFocusBorderBottomColor: '#BE1219',

  emotionometerColors: ['#EBC9D0', '#EA8C91', '#E82D30'],
  tipsheetPastelColor: '#fff', // not used
}

export const parentGreen: Theme = {
  name: 'parentGreen',

  ...DEFAULTS,

  appBackgroundTopColor: '#48DD35',
  appBackgroundBottomColor: '#189E07',
  appPanelShadowColor: '#189E07',

  buttonBackgroundTopColor: '#51AB46',
  buttonBackgroundBottomColor: '#65D058',
  buttonBorderTopColor: '#4EBE40',
  buttonBorderBottomColor: '#4EBE40',

  // focus colors are above with 10% black mixed in
  buttonFocusBackgroundTopColor: '#3F8637',
  buttonFocusBackgroundBottomColor: '#45BF36',
  buttonFocusBorderTopColor: '#3F9933',
  buttonFocusBorderBottomColor: '#3F9933',

  emotionometerColors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
  tipsheetPastelColor: '#fff', // not used
}

export const THEMES: Theme[] = [gray, blue, purple, pink, orange, teal]

export const ALL_THEMES: { [key in ThemeName]: Theme } = {
  gray,
  blue,
  purple,
  pink,
  orange,
  teal,
  white,
  dark,
  red,
  parentGreen,
}
