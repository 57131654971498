import { P, Spacer } from 'common/ui'
import { observationalCodeHeadings } from 'dashboards/constant/observationalCode'
import {
  getResponsesSubscale,
  getResponsesTotal,
  getSubscaleScores,
  getSubscaleTotal,
  getUserResponsesFromRange,
  observationalCodeRanges,
} from 'dashboards/utils/observationalCodeUtils'
import { useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { Table } from 'reporting/common/ui'
import { QuestionnaireResponse } from 'shared/questionnaires/types'

export const ObservationalCodeScores: React.FC = () => {
  const { state: userData } = useQuestionnaireState()

  const subscaleScores = getSubscaleScores(userData.responses)
  const subscaleTotal = getSubscaleTotal(subscaleScores)
  const applicableSubscaleScores = subscaleScores.filter((num) => num > 0)
  const subscaleOverall = subscaleTotal / applicableSubscaleScores.length || 0

  return (
    <>
      {observationalCodeRanges.map((range, i) => (
        <ObservationalCodeScoreSegment
          key={i}
          title={observationalCodeHeadings[i]}
          start={range[0]}
          end={range[1]}
          responses={getUserResponsesFromRange(range[0], range[1], userData.responses)}
        />
      ))}
      <Spacer size="s" />
      <Table style={{ borderWidth: 5, borderLeft: '5px solid #abb3dc' }}>
        <tbody>
          <tr>
            <th colSpan={2}>
              <strong>Total Social Skills Score</strong>
            </th>
          </tr>
          <tr>
            <td>Add all subscale scores</td>
            <td style={{ width: 200 }}>
              {String(subscaleTotal).indexOf('.') > 0 ? subscaleTotal.toFixed(2) : subscaleTotal}
            </td>
          </tr>
          <tr>
            <td>Divide by number of subscales for which a score could be obtained</td>
            <td>{applicableSubscaleScores.length}</td>
          </tr>
          <tr>
            <td>
              <strong style={{ fontSize: 20 }}>Total Social Skills Score</strong>
            </td>
            <td>
              <strong style={{ fontSize: 20 }}>
                {String(subscaleOverall).indexOf('.') > 0 ? subscaleOverall.toFixed(2) : subscaleOverall}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>
      <P>
        <i>
          Scores range from 1 to 3. Lower scores reflect greater levels of social skill. <br />
          If several items in a subscale are marked N/A, caution is warranted in interpreting the subscale and total
          scores. In those instances, the scores are unlikely to provide a valid indicator of a child’s skill level in
          the domain(s) being assessed.
        </i>
      </P>
    </>
  )
}

const ObservationalCodeScoreSegment: React.FC<{
  title: string
  start: number
  end: number
  responses: QuestionnaireResponse[]
}> = ({ title, start, end, responses }) => {
  const questionsCount = end - (start - 1)
  const total = getResponsesTotal(responses)
  const subscale = getResponsesSubscale(responses)
  const responsesCount = responses.filter(({ score }) => typeof score === 'number').length
  return (
    <>
      {/* <h2>{title}</h2> */}
      <Table style={{ marginTop: -2 }}>
        <tbody>
          <tr>
            <th colSpan={2}>{title}</th>
          </tr>
          <tr>
            <td>
              {responsesCount} / {questionsCount} questions answered, totalling
            </td>
            <td style={{ width: 200 }}>
              {total} <small>out of a possible {questionsCount * 3}</small>
            </td>
          </tr>
          <tr>
            <td>Divide by the number of questions answered</td>
            <td>{responsesCount}</td>
          </tr>
          <tr>
            <td>
              <strong>{title} Subscale Score is</strong>
            </td>
            <td>
              <strong>{!subscale ? 'N/A' : String(subscale).indexOf('.') > 0 ? subscale.toFixed(2) : subscale}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
