import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { P, H3, Column, Spacer, Hr } from 'common/ui'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells, Cell } from 'dashboards/common/Cell'
import { Page } from 'dashboards/common/Page'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { useGenericUser } from 'app/useGenericUser'
import { facilitatorDashboardBaseUrl } from './constants'

export const Contact: React.FC<NavRouteProps> = ({ route }) => {
  const user = useGenericUser()
  const hideProviders = () => {
    return (
      user.roles.indexOf('sas-sg facilitator beta') < 0 &&
      user.roles.indexOf('sas-sg facilitator') < 0 &&
      user.roles.indexOf('sas-sg senior facilitator') < 0 &&
      user.roles.indexOf('sas-sg assistant') < 0
    )
  }
  return (
    <Page route={route}>
      <P style={{ maxWidth: 360, margin: 0 }}>
        To assist you with any further secret agent detective work, please find the relevant contact details below.
      </P>
      <Spacer size="l" />
      {!hideProviders() && (
        <>
          {' '}
          <HeaderHr children="Contact your SAS Provider" />
          <Cells>
            <Cell>
              <Column padding={20}>
                <H3 style={{ color: '#6737B5' }}>
                  Please direct service level enquiries to your SAS Provider administrator
                </H3>
                <P style={{ marginBottom: 0 }}>
                  <LinkButton
                    size="xs"
                    style={{ display: 'inline-block' }}
                    children="My Providers"
                    to={`${facilitatorDashboardBaseUrl}/providers`}
                  />
                </P>
              </Column>
            </Cell>
          </Cells>
          <Spacer size="l" />
        </>
      )}
      <HeaderHr children="Technical Support" />
      <Cells>
        <Cell>
          <Column padding={20}>
            <H3 style={{ color: '#6737B5' }}>Please complete the web form to submit technical issues experienced</H3>
            <P style={{ marginBottom: 0 }}>
              <AnchorButton
                size="xs"
                style={{ display: 'inline-block' }}
                children="Support Web Form"
                target="_blank"
                href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}
              />
              <br />
              <br />
              Email: <a href="mailto:support@sst-institute.net" children="support@sst-institute.net" />
            </P>
          </Column>
        </Cell>
      </Cells>
      <Spacer size="l" />
      <HeaderHr children="Contact SST" />
      <Cells>
        <Cell>
          <Column padding={20}>
            <H3 style={{ color: '#6737B5' }}>Social Science Translated</H3>
            <P style={{ marginBottom: 0 }}>
              General enquiries: <a href="mailto:sas@sst-institute.net" children="sas@sst-institute.net" />
              <br />
              Professional Training enquiries:{' '}
              <a href="mailto:training@sst-institute.net" children="training@sst-institute.net" />
            </P>
            <Hr margin="m" />
            <P style={{ marginBottom: 0, marginTop: 0 }}>
              Postal Address: PO Box 6068, St Lucia, QLD 4067, Australia
              <br />
              Phone: <a href="tel:61737208740" children="+61 7 3720 8740" />
            </P>
          </Column>
        </Cell>
      </Cells>
    </Page>
  )
}
