/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { useParams, useHistory } from 'react-router-dom'

import { MentorEntityNew, MentorEntity, SessionEntity } from 'shared/dashboard/types'
import { FormDataSubsetProps } from 'dashboards/utils/formDataHooks'

import { ScreenPanel, GroupCadetNavRouteProps } from '../GroupCadetOverview'
import { Column, Spacer, Hr, P, OutlineButton, Button, Row } from 'common/ui'
import { Form } from 'dashboards/common/Form'
import { MentorEditFields, MentorCell, MentorPermissionKey } from 'dashboards/common/MentorProfileCell'
import { FormField } from 'dashboards/common/FormField'
import { Checkbox } from 'common/Checkbox'
import { SpinnerWithLabel, Spinner } from 'common/Spinner'
import { InfoTooltip } from 'common/Tooltip'
import { MentorRemoveModal } from './MentorRemoveModal'
import { MentorMoveModal } from './MentorMoveModal'

import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { postJson } from 'utils/apiUtils'
import { sessionsFilterCurrentUnfinalized } from 'dashboards/utils/reducers'
import { useFacDashData } from '../FacilitatorDashboardState'
import { facilitatorDashboardBaseUrl } from '../constants'
import { FEATURE_communityTipSheets_providerUuids } from 'dashboards/constant/FEATURE_consts'

export const MentorsEdit: React.FC<GroupCadetNavRouteProps> = ({ refetchGroup, editingCallback }) => {
  const history = useHistory()
  const { groupUid, primaryMentorUid } = useParams<{ groupUid: string; primaryMentorUid: string }>()
  const { accessToken, drupalProfile } = useUserState()
  const [groups] = useFacDashData('groups', [])
  const [refetchMentorsCount, setRefetchMentorsCount] = useState<number>(0)
  const [addingNewMentor, setAddingNewMentor] = useState<boolean>(false)
  const [editingPrimaryMentor, setEditingPrimaryMentor] = useState<boolean>(false)
  const [expandedMentorUids, setExpandedMentorUids] = useState<string[]>([])
  const [showMoveConfirmation, setShowMoveConfirmation] = useState<boolean>(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false)
  const [unsavedPermissionCounts, setUnsavedPermissionCounts] = useState<{ [key in MentorPermissionKey]?: number }>({})
  const [mentors, { loading: loadingMentors, errorLoading: errorLoadingMentors }] = useEndpoint<MentorEntity[]>(
    `/api/v1/mentors/cadet/${primaryMentorUid}?cache=${refetchMentorsCount}${
      refetchMentorsCount === 0 ? '&sync_emails=1' : ''
    }`,
    undefined, // default value
    { refetchDespiteCachedValue: true }
  )
  const [sessions, { loading: loadingSessions, errorLoading: errorLoadingSessions }] = useEndpoint<SessionEntity[]>(
    `/api/v1/groups/${groupUid}/sessions`
  )
  const currentUnfinalizedSessions = sessionsFilterCurrentUnfinalized(sessions || [])

  const initialPrimaryMentor = useRef<MentorEntityNew>()

  const currentGroup = groups.find(({ uid }) => uid === groupUid)
  const isCoFacilitator = currentGroup?.cofacilitator_id?.toString() === drupalProfile?.user_id

  const FEATURE_communityTipSheetsEnabled = FEATURE_communityTipSheets_providerUuids.includes(
    currentGroup?.provider_uid || '_NO_'
  )

  const permissionCounts = (mentors || []).reduce(
    (acc, mentor) => {
      const keys = Object.keys(mentor).filter((key) => key.startsWith('permission_')) as MentorPermissionKey[]
      for (const key of keys) if (mentor[key]) acc[key] = (acc[key] || 0) + 1
      return acc
    },
    {} as Record<MentorPermissionKey, number>
  )

  console.log('formStatePermissionCounts', JSON.stringify(permissionCounts, null, 2))
  console.log('unsavedPermissionCounts', JSON.stringify(unsavedPermissionCounts, null, 2))
  for (const key of Object.keys(unsavedPermissionCounts) as MentorPermissionKey[]) {
    permissionCounts[key] = (permissionCounts[key] || 0) + (unsavedPermissionCounts[key] || 0)
  }
  console.log('summedPermissionCounts', JSON.stringify(permissionCounts, null, 2))

  const disabledFields: (keyof MentorEntityNew)[] = []
  if (permissionCounts.permission_live_parent_sessions >= 2) disabledFields.push('permission_live_parent_sessions')
  if (permissionCounts.permission_past_parent_sessions >= 2) disabledFields.push('permission_past_parent_sessions')
  if (permissionCounts.permission_past_cadet_sessions >= 2) disabledFields.push('permission_past_cadet_sessions')
  if (permissionCounts.permission_reporting >= 4) disabledFields.push('permission_reporting')

  const confirmPermissionChange = useCallback((value: boolean, select: MentorPermissionKey): boolean => {
    console.log('confirmPermissionChange', select, value)
    setUnsavedPermissionCounts((prev) => ({ ...prev, [select]: (prev[select] || 0) + (value ? 1 : -1) }))
    return true
  }, [])

  const onPrimaryMentorSaveSuccess = useCallback(
    (savedEntity: MentorEntity) =>
      ReactDOM.unstable_batchedUpdates(() => {
        setEditingPrimaryMentor(false)
        setRefetchMentorsCount((v) => v + 1)
        setUnsavedPermissionCounts({})
      }),
    []
  )
  const onMentorSaveSuccess = useCallback(
    (savedEntity: MentorEntity) =>
      ReactDOM.unstable_batchedUpdates(() => {
        setRefetchMentorsCount((v) => v + 1)
        setUnsavedPermissionCounts({})
      }),
    []
  )

  const onNewMentorSubmitSuccess = useCallback(
    (savedEntity: MentorEntity) =>
      ReactDOM.unstable_batchedUpdates(() => {
        setAddingNewMentor(false)
        setRefetchMentorsCount((v) => v + 1)
        setUnsavedPermissionCounts({})
      }),
    []
  )

  const handleConfirmRemove = () => {
    setShowRemoveConfirmation(false)
    postJson(`/api/v1/mentors/${primaryMentorUid}/banish`, {}, getAuthRequestParams(accessToken), { retries: 0 })
      .then(() => {
        refetchGroup()
        history.replace(`${facilitatorDashboardBaseUrl}/groups/${groupUid}/overview`)
      })
      .catch((e) => {
        alert('Unable to remove cadet/primary mentor')
        console.warn(e)
      })
  }
  const handleMoveSubmission = (moved: boolean) => {
    setShowMoveConfirmation(false)
    if (moved) {
      refetchGroup()
      history.replace(`${facilitatorDashboardBaseUrl}/groups/${groupUid}/overview`)
    }
  }

  useEffect(() => {
    if (editingCallback) editingCallback(addingNewMentor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingNewMentor])

  return (
    <>
      <Form<MentorEntity, MentorEntityNew>
        initialDataUrl={`/api/v1/mentors/${primaryMentorUid}`}
        initialDataRequestOptions={getAuthRequestParams(accessToken)}
        submitUrl={`/api/v1/mentors/${primaryMentorUid}`}
        submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'PUT' }}
        onSubmitSuccess={onPrimaryMentorSaveSuccess}
        onInitialDataLoaded={(data) => (initialPrimaryMentor.current = data)}
        children={({
          loading,
          errorLoading,
          reloadInitialData,
          submitting,
          formData,
          // updateFormValue,
          setFormData,
          validationErrors,
          unsavedChanges,
        }) => {
          if (loading || errorLoading)
            return (
              <ScreenPanel style={{ padding: 12 }}>
                {loading ? (
                  <SpinnerWithLabel label="Fetching licence holder..." color="#ddeaff" />
                ) : (
                  <P>An error occurred while loading data, try refreshing.</P>
                )}
              </ScreenPanel>
            )
          const formDataProps: Omit<FormDataSubsetProps<MentorEntityNew>, 'select'> = {
            formData,
            setFormData,
            validationErrors,
          }
          return (
            <MentorCell
              index={0}
              groupUid={groupUid}
              mentor={formData}
              context="cadet-overview"
              editing={editingPrimaryMentor}
              onUpdate={(newData) => setFormData(newData)}
              onReloadRequested={() => reloadInitialData()}
              onEdit={() => setEditingPrimaryMentor((v) => !v)}
              hideEdit={isCoFacilitator}
              children={
                !editingPrimaryMentor ? undefined : (
                  <>
                    <Column padding="10px 12px">
                      <MentorEditFields
                        {...{
                          formDataProps,
                          disabledFields,
                          confirmPermissionChange,
                          FEATURE_communityTipSheetsEnabled,
                        }}
                      />
                    </Column>
                    <Hr margin="xs" />
                    <Column padding="10px 12px">
                      <Row alignItems="center">
                        <Column>
                          <Button
                            type="submit"
                            disabled={submitting || !unsavedChanges}
                            children={submitting ? 'Saving...' : 'Save'}
                          />
                        </Column>
                        <Spacer size="m" />
                        <Spacer flex="1 1 auto" />
                        <Row alignItems="center">
                          <InfoTooltip
                            placement="left"
                            content={`Move the Cadet Place, including consenting adult and mentor(s) to a different group. \n\nNOTE: All current meetings in both the current and new group must be finalised before moving a Cadet (this excludes future sessions), otherwise this feature will be disabled.`}
                          />
                          <Spacer size="s" />
                          <Button
                            type="button"
                            size="xs"
                            theme="blue"
                            disabled={loadingSessions || errorLoadingSessions || currentUnfinalizedSessions.length > 0}
                            children={loadingSessions ? <Spinner /> : `Move to another group...`}
                            onClick={() => setShowMoveConfirmation(true)}
                          />
                          <Spacer size="m" />
                          <InfoTooltip
                            placement="left"
                            content={`Remove the Cadet Place from the SAS Small Group Program.`}
                          />
                          <Spacer size="s" />
                          <Button
                            type="button"
                            size="xs"
                            theme="red"
                            children={`Remove Cadet`}
                            onClick={() => setShowRemoveConfirmation(true)}
                          />
                        </Row>
                      </Row>
                    </Column>
                  </>
                )
              }
            />
          )
        }}
      />
      <Spacer size="m" />
      {loadingMentors ? (
        <SpinnerWithLabel label="Loading mentors..." />
      ) : errorLoadingMentors ? (
        <P>Error loading mentors!</P>
      ) : (
        (mentors?.filter((mentor) => mentor.uid !== primaryMentorUid) || []).map((mentor, i) => (
          <Fragment key={mentor.id || i}>
            <Form<MentorEntity, MentorEntityNew>
              initialDataUrl={`/api/v1/mentors/${mentor.uid}`}
              initialDataRequestOptions={getAuthRequestParams(accessToken)}
              submitUrl={`/api/v1/mentors/${mentor.uid}`}
              submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'PUT' }}
              onSubmitSuccess={onMentorSaveSuccess}
              defaultValue={mentor}
              children={({
                loading,
                errorLoading,
                reloadInitialData,
                submitting,
                formData,
                updateFormValue,
                setFormData,
                validationErrors,
                unsavedChanges,
                submitForm,
              }) => {
                if (loading || errorLoading)
                  return (
                    <ScreenPanel style={{ padding: 12 }}>
                      {loading ? (
                        <SpinnerWithLabel label="Fetching mentors..." color="#ddeaff" />
                      ) : (
                        <P>An error occurred while fetching mentors, try refreshing.</P>
                      )}
                    </ScreenPanel>
                  )
                const formDataProps: Omit<FormDataSubsetProps<MentorEntityNew>, 'select'> = {
                  formData,
                  setFormData,
                  validationErrors,
                }
                const expanded = !!formData.uid && expandedMentorUids.indexOf(formData.uid) >= 0
                return (
                  <MentorCell
                    index={i + 1}
                    groupUid={groupUid}
                    mentor={formData}
                    context="cadet-overview"
                    editing={expanded}
                    onUpdate={(newData) => setFormData(newData)}
                    onReloadRequested={() => reloadInitialData()}
                    hideEdit={isCoFacilitator}
                    onEdit={() =>
                      !!formData.uid &&
                      setExpandedMentorUids(
                        expanded
                          ? expandedMentorUids.filter((v) => v !== formData.uid)
                          : [...expandedMentorUids, formData.uid]
                      )
                    }
                    children={
                      !expanded ? undefined : (
                        <>
                          <Column padding="10px 12px">
                            <MentorEditFields
                              {...{
                                formDataProps,
                                disabledFields,
                                confirmPermissionChange,
                                FEATURE_communityTipSheetsEnabled,
                              }}
                            />
                          </Column>
                          <Hr margin="xs" />
                          <Column padding="10px 12px">
                            <Row alignItems="center">
                              <div>
                                <Button
                                  type="submit"
                                  disabled={submitting || !unsavedChanges}
                                  children={submitting ? 'Saving...' : 'Save'}
                                />
                              </div>
                              <Spacer size="m" />
                              <div>
                                <div
                                  children="Cancel Edit"
                                  style={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() => {
                                    if (
                                      !unsavedChanges ||
                                      (unsavedChanges &&
                                        window.confirm(`Are you sure you want to discard changes to this mentor?`))
                                    ) {
                                      setExpandedMentorUids(expandedMentorUids.filter((v) => v !== formData.uid))
                                      setFormData(mentor)
                                    }
                                  }}
                                />
                              </div>
                              <Spacer size="m" flex="1 1 auto" />
                              <Row alignSelf="flex-end" alignItems="center">
                                {!formData.deleted && (
                                  <>
                                    <InfoTooltip
                                      placement="left"
                                      content={`Remove the mentor from this cadet. They will no longer be able to view this cadet's information.`}
                                    />
                                    <Spacer size="s" />
                                  </>
                                )}
                                <Button
                                  theme="red"
                                  size="xs"
                                  children={formData.deleted ? 'Re-enable Mentor' : 'Remove Mentor'}
                                  onClick={() => {
                                    ReactDOM.unstable_batchedUpdates(() => {
                                      const newFormData = { ...formData, deleted: !formData.deleted }
                                      if (!formData.deleted) {
                                        setExpandedMentorUids(expandedMentorUids.filter((v) => v !== formData.uid))
                                        // set all permissions to false when deleting, just easier
                                        const keys = Object.keys(mentor).filter((key) =>
                                          key.startsWith('permission_')
                                        ) as MentorPermissionKey[]
                                        for (const key of keys) newFormData[key] = false
                                      }

                                      submitForm(newFormData)
                                    })
                                  }}
                                />
                              </Row>
                            </Row>
                          </Column>
                        </>
                      )
                    }
                  />
                )
              }}
            />
            <Spacer size="m" />
          </Fragment>
        ))
      )}
      {addingNewMentor && (
        <>
          <Form<MentorEntity, MentorEntityNew>
            initialDataUrl={`/api/v1/mentors/new`}
            initialDataRequestOptions={getAuthRequestParams(accessToken)}
            submitUrl={`/api/v1/mentors/save`}
            submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'POST' }}
            onSubmitSuccess={onNewMentorSubmitSuccess}
            defaultValue={{
              primary_mentor_id: initialPrimaryMentor.current?.id,
              group_id: initialPrimaryMentor.current?.group_id,
              _auto_trigger_email: true,
            }}
            children={({
              loading,
              errorLoading,
              submitting,
              formData,
              // updateFormValue,
              setFormData,
              validationErrors,
              unsavedChanges,
            }) => {
              if (loading || errorLoading)
                return (
                  <ScreenPanel style={{ padding: 12 }}>
                    {loading ? (
                      <SpinnerWithLabel label="Initializing new mentor..." color="#ddeaff" />
                    ) : (
                      <P>An error occurred while loading data, try refreshing.</P>
                    )}
                  </ScreenPanel>
                )
              const formDataProps: Omit<FormDataSubsetProps<MentorEntityNew>, 'select'> = {
                formData,
                setFormData,
                validationErrors,
              }
              return (
                <MentorCell
                  index={(mentors || []).length + 1}
                  groupUid={groupUid}
                  mentor={formData}
                  context="cadet-overview"
                  editing
                  onEdit={() => {}}
                  children={
                    <>
                      <Column padding="10px 12px">
                        <MentorEditFields
                          {...{
                            formDataProps,
                            disabledFields,
                            confirmPermissionChange,
                            FEATURE_communityTipSheetsEnabled,
                          }}
                        />
                      </Column>
                      <Hr margin="xs" />
                      <Column padding="10px 12px">
                        <FormField<MentorEntityNew, boolean>
                          {...formDataProps}
                          select="_auto_trigger_email"
                          defaultValue={true}
                          children={({ value, onChange }) => (
                            <Checkbox size="s" checked={!!value} onChange={onChange}>
                              Send invitation email {formData.email && `to ${formData.email}`}
                            </Checkbox>
                          )}
                        />
                      </Column>
                      <Hr margin="xs" />
                      <Column padding="10px 12px">
                        <Row alignItems="center">
                          <div>
                            <Button
                              type="submit"
                              disabled={submitting || !unsavedChanges}
                              children={submitting ? 'Saving...' : 'Save'}
                            />
                          </div>
                          <Spacer size="m" />
                          <div>
                            <div
                              children="Cancel Edit"
                              style={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() =>
                                (!unsavedChanges ||
                                  (unsavedChanges &&
                                    window.confirm(`Are you sure you want to discard this unsaved mentor?`))) &&
                                setAddingNewMentor(false)
                              }
                            />
                          </div>
                        </Row>
                      </Column>
                    </>
                  }
                />
              )
            }}
          />
          <Spacer size="m" />
        </>
      )}
      {!isCoFacilitator && (
        <OutlineButton
          type="button"
          onClick={() => setAddingNewMentor(true)}
          disabled={addingNewMentor}
          children="Add New Mentor"
        />
      )}
      <MentorRemoveModal
        isOpen={showRemoveConfirmation}
        mentor={initialPrimaryMentor.current}
        onConfirmDelete={() => {
          setShowRemoveConfirmation(false)
          handleConfirmRemove()
        }}
        onRequestClose={() => setShowRemoveConfirmation(false)}
      />
      {showMoveConfirmation && (
        <MentorMoveModal
          isOpen
          mentor={initialPrimaryMentor.current}
          providerUid={currentGroup?.provider_uid}
          disableGroupUids={[groupUid]}
          onSubmit={handleMoveSubmission}
        />
      )}
    </>
  )
}
