import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'
import { FacilitatorLookupEntity, MentorEntity, MentorEntityNew } from 'shared/dashboard/types'
import { TIPSHEET_CONFIRM_READ, objShapeMatches } from 'shared/progressionEvents'
import { SET_SAVED_USER_INPUT_VALUES } from 'shared/session/actionTypes'
import { SavedInputValue } from 'shared/session/types'
import { TipsheetEntity, TipsheetType } from 'shared/tipsheets/types'

import { InputContextProvider } from 'session/InputContext'
import { TipsheetGadgetPackStateProvider } from 'session/PreviewGadgetPackStateProvider'
import { ProgressionEventsStateProvider, useProgressionEventsState } from 'session/ProgressionEventsState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'

import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'
import { Tipsheet, TipsheetWrapper } from './Tipsheet'

import { useGenericUser } from 'app/useGenericUser'
import { useMentorDashData, useMentorDashState } from 'dashboards/mentor/MentorDashboardState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'

interface UrlParams {
  moduleCode: string
  language?: string
}

const TipsheetMentorInner: React.FC<{
  mentor: MentorEntity
  primaryMentor: MentorEntity | MentorEntityNew
  type: TipsheetType
}> = ({ mentor, primaryMentor, type }) => {
  const user = useGenericUser()
  const { moduleCode } = useParams<UrlParams>()
  const { mentorUid } = useMentorDashState()
  const { dispatch: dispatchInputState } = useUserInputState()
  const { addProgressionEvent, progressionEvents } = useProgressionEventsState()

  const [tipsheet, tipsheetEndpoint] = useEndpoint<TipsheetEntity>(`/api/v1/tipsheets/${type}/${moduleCode}`)
  const [facilitator, facilitatorEndpoint] = useEndpoint<FacilitatorLookupEntity>(
    `/api/v1/mentors/${mentor.uid}/facilitator/${mentor.group_id}`
  )
  const [inputValues, inputValuesEndpoint] = useEndpoint<SavedInputValue<any>[]>(
    `/api/v1/session_input_values?session_uid=Tipsheet_${primaryMentor.uid}&participant_uid=shared`
  )

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)

  const loading = !tipsheetEndpoint.loaded || !facilitatorEndpoint.loaded || !inputValuesEndpoint.loaded

  const wasLoading = useRef<boolean>(true)

  useEffect(() => {
    if (!loading && wasLoading.current === true && inputValues) {
      wasLoading.current = false
      dispatchInputState({
        user_uid: user.uid,
        role: user.userType,
        timestamp: Date.now(),
        type: SET_SAVED_USER_INPUT_VALUES,
        inputValues,
      })
      setTimeout(() => setFinishedDelay(true), 1000)
    }
  })

  const checkTipsheetRead = () => {
    if (!tipsheet) return false
    const testEventStructure = {
      type: TIPSHEET_CONFIRM_READ,
      mentor_id: mentor.id,
      category: `tipsheet-${type}`,
      subcategory: tipsheet.module_code,
    }
    for (let testProgressionEvent of progressionEvents) {
      if (objShapeMatches(testEventStructure, testProgressionEvent)) {
        return true
      }
    }
    return false
  }

  if (loading || !finishedDelay)
    return (
      <TipsheetWrapper style={{ height: '100%' }}>
        <ResourceLoadingStatusPanel
          title="Loading Tipsheet..."
          resources={[
            { label: 'Tipsheet content', endpoint: tipsheetEndpoint },
            { label: 'Facilitator profile', endpoint: facilitatorEndpoint },
            { label: 'Previous input data', endpoint: inputValuesEndpoint },
          ]}
        />
      </TipsheetWrapper>
    )

  if (!tipsheet || !facilitator) return null

  return (
    <Tipsheet
      tipsheetModule={tipsheet}
      facilitatorEntity={facilitator}
      primaryMentor={primaryMentor || undefined}
      onConfirm={() =>
        addProgressionEvent({
          participant_uid: user.uid,
          mentor_id: mentor.id,
          type: TIPSHEET_CONFIRM_READ,
          category: `tipsheet-${type}`,
          subcategory: tipsheet.module_code,
          event_key: null,
          event_value: null,
        })
      }
      confirmRead={checkTipsheetRead()}
      printButtonProps={{
        context: 'mentor',
        tipsheetType: tipsheet.type || 'teacher',
        moduleCode: tipsheet.module_code,
        mentorUid: mentorUid || undefined,
        groupId: mentor.group_id,
      }}
    />
  )
}

export const TipsheetMentor: React.FC<NavRouteProps & { type: TipsheetType }> = (props) => {
  const [mentor] = useMentorDashData('mentor')
  const [primaryMentor] = useMentorDashData('primaryMentor')
  if (!mentor || !primaryMentor) return null
  return (
    <ProgressionEventsStateProvider>
      <TipsheetGadgetPackStateProvider>
        <UserInputStateProvider>
          <InputContextProvider
            overwriteContext={{
              session_uid: `Tipsheet_${primaryMentor.uid}`,
              participant_uid: 'shared',
              module_id: null as any,
              owner: undefined,
              owner_id: undefined,
              name: undefined,
            }}>
            <TipsheetMentorInner mentor={mentor} primaryMentor={primaryMentor} type={props.type} />
          </InputContextProvider>
        </UserInputStateProvider>
      </TipsheetGadgetPackStateProvider>
    </ProgressionEventsStateProvider>
  )
}

export const TeacherTipsheetMentor: React.FC<NavRouteProps> = (props) => {
  return <TipsheetMentor {...props} type="teacher" />
}

export const CommunityTipsheetMentor: React.FC<NavRouteProps> = (props) => {
  return <TipsheetMentor {...props} type="community" />
}
