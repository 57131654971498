import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useDrop } from 'react-dnd'

import { Button, Column, CUT_BL, CUT_BR, CUT_TL, CUT_TR, H1, H2, Row, Spacer } from 'common/ui'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { PanelTitle } from 'session/common/SectionsPanelComponents'
import { EmbeddedActivitySection, emotionRanges, ActivityEmotionometerState, EmotionRange } from 'shared/session/types'

import { Props as StickerSelectModalProps } from 'e-telligence/common/StickerSelectModal'
import { StickerViewModal } from 'e-telligence/common/StickerViewModal'
import { StickerTile } from 'e-telligence/common/StickerTile'
import { Bar, EmotionometerBar } from 'e-telligence/common/EmotionometerBar'
import { presetStickers } from 'e-telligence/constants/stickers'

import { Checkbox } from 'common/Checkbox'
import { StickerRow } from './StickerRow'
import { CustomStickerModal } from './CustomStickerModal'
import { CustomDragLayer } from './CustomDragLayer'
import { DraggableSticker, StickerDragItem, DRAG_TYPE_EMOTIONOMETER_STICKER } from './DraggableSticker'

import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'
import {
  emotionLabels,
  emotionometerDeviceLabels,
  emotionRangeDistribution,
  emotionRangeValues,
} from 'e-telligence/constants/typeValueMaps'
import {
  EmotionometerDevice,
  EmotionometerRange,
  EmotionometerSticker,
  EmotionometerStickerBase,
  Emotionometer,
} from 'shared/e-telligence/types'
import {
  AddUpdateCustomStickerAction,
  ADD_EMOTIONOMETER_STICKER,
  ADD_UPDATE_CUSTOM_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  ADD_UPDATE_EMOTIONOMETER,
} from 'shared/e-telligence/actionTypes'

import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { useSessionState } from 'session/SessionState'
import { useSectionState, useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState, useParticipantProfileUid } from 'session/hooks/useProfileState'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useUserState } from 'app/UserState'
import { TileDiv } from 'e-telligence/common/StickerTileButton'
import { AccordionBody } from 'session/common/Accordion'
import { GadgetPackLoading } from 'session/common/GadgetPackLoading'

interface Props {
  section: EmbeddedActivitySection
}

export const emotionPanelThemes = {
  //: {[key in Exclude<EmbeddedActivitySection['emotionometer_emotion'], undefined>]: SectionPanel['theme']} = {
  anger: 'red' as const,
  anxiety: 'purple' as const,
  sadness: 'blue' as const,
}

export const EmotionometerActivity: React.FC<Props> = ({ section }) => {
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionState('activity_emotionometer', section)
  const profileUid = useParticipantProfileUid()

  let activeEmotionRangeIndexes: [boolean, boolean, boolean] = [false, false, false]

  if (participantState && sectionState) {
    emotionRanges.forEach((key, i) => {
      if (sectionState[key].indexOf(participantState.profile.uid) >= 0) activeEmotionRangeIndexes[i] = true
    })
  }

  const readOnly = false // participantState && participantState.status === 'offline'

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'emotionometer',
          subcategory: section.emotionometer_emotion || null,
          event_key: section.emotionometer_device ? 'device' : null,
          event_value: section.emotionometer_device || null,
        }}
      />
      <EmotionometerActivityInner
        activityTitle={section.title}
        device={section.emotionometer_device}
        emotion={section.emotionometer_emotion}
        activeEmotionRangeIndexes={activeEmotionRangeIndexes}
        readonly={readOnly}
        viewAllDevices={readOnly}
      />
    </>
  )
}

export const EmotionometerActivityInner: React.FC<{
  activityTitle?: string
  device?: EmotionometerDevice
  emotion?: 'anger' | 'anxiety'
  activeEmotionRangeIndexes: [boolean, boolean, boolean]
  viewAllDevices?: boolean
  hideStickerTray?: boolean
  readonly?: boolean
}> = ({
  activityTitle = '',
  device = 'situations',
  emotion = 'anger',
  activeEmotionRangeIndexes,
  viewAllDevices,
  hideStickerTray,
  readonly,
}) => {
  const title = activityTitle || `${emotionLabels[emotion]} Emotionometer`

  const { getBaseAction, profileId } = useUserState()
  const { state: gadgetPackState, dispatch: gadgetPackDispatch, loadingInitialState } = useGadgetPackState()
  const [shownDevices, setShownDevices] = useState<EmotionometerDevice[]>([device])
  const [viewSticker, setViewSticker] = useState<EmotionometerStickerBase | undefined>()
  const [selectStickerProps, setSelectStickerProps] = useState<
    Pick<StickerSelectModalProps, 'emotion' | 'emotionRange' | 'device' | 'index'> | undefined
  >()
  const [dragItemSize, setDragItemSize] = useState<number>(90)
  const emptyEmotionometer: Emotionometer = {
    id: emotion,
    type: emotion,
    title: emotionLabels[emotion],
    situations: [],
    bodyClues: [],
    relaxationGadgets: [],
  }
  const stateEmotionometer = gadgetPackState.emotionometers.find(({ type }) => type === emotion)
  const emotionometer = stateEmotionometer // || emptyEmotionometer
  const createdMissing = useRef(false)
  const emotionometerRef = useRef(emotionometer)

  const [showCustomModal, setShowCustomModal] = useState<boolean>(false)

  const [allowRemove, setAllowRemove] = useState<boolean>(true)
  const [allowEdit, setAllowEdit] = useState<boolean>(true)

  useEffect(() => {
    emotionometerRef.current = emotionometer
  }, [emotionometer])

  useEffect(() => {
    createdMissing.current = false
    // emotion and device change would be for if a slide changes with an emotionometer on both slides, but one has different settings than the other
    // profileId is for if facilitator changes tab, for example
  }, [emotion, device, profileId])

  const delayedCreateMissing = useCallback((): void => {
    if (!createdMissing.current && emotionometerRef.current === undefined) {
      console.log('🧚‍♀️ Dispatching add emotionometer action')
      createdMissing.current = true
      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_UPDATE_EMOTIONOMETER,
        emotionometer: emptyEmotionometer,
      })
    } else {
      console.log(
        `🧚‍♀️ Turns out we didn't need to emit a create emotionometer action, GP state provider was just delayed`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!createdMissing.current && emotionometerRef.current === undefined && !loadingInitialState) {
      console.log(
        '🧚‍♀️ Emotionometer needs to be created but need to wait a render cycle for gadget pack state useEffects to fire'
      )
      setTimeout(delayedCreateMissing, 350)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingInitialState])

  if (!emotionometer || loadingInitialState) return <GadgetPackLoading />

  const allStickers = [
    ...gadgetPackState.customStickers.filter(
      (sticker) => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
    ...presetStickers.filter(
      (sticker) => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
  ]

  const emotionometerStickers: EmotionometerSticker<typeof device>[] = emotionometer[device]
  const selectedStickerIds: string[] = emotionometerStickers.map(({ id }) => id)
  const atCapacity = emotionometerStickers.length >= 10

  const toggleDeviceExpanded = (device: EmotionometerDevice) => {
    setShownDevices(
      shownDevices.indexOf(device) >= 0 ? shownDevices.filter((val) => val !== device) : [...shownDevices, device]
    )
  }

  const handleStickerSelect = (sticker: EmotionometerStickerBase) => {
    if (
      device === sticker.type &&
      sticker.index !== undefined &&
      activeEmotionRangeIndexes[emotionRangeDistribution[sticker.index]]
    ) {
      setAllowRemove(true)
      setAllowEdit(true)
    } else {
      setAllowEdit(false)
      setAllowRemove(false)
    }

    setViewSticker(sticker)
    setSelectStickerProps(undefined)
  }

  const handleEmptyStickerSelect = (device: EmotionometerDevice, emotionRange: EmotionometerRange, index: number) => {
    if (selectStickerProps?.index === index) {
      setSelectStickerProps(undefined)
    } else {
      setSelectStickerProps({ emotion, emotionRange, device, index })
    }
  }

  const handleTrayStickerSelect = (sticker: EmotionometerStickerBase | undefined) => {
    if (!sticker) {
      setShowCustomModal(true)
    } else if (selectStickerProps !== undefined && sticker) {
      handleAddSticker({ ...sticker, index: selectStickerProps.index })
    } else {
      setAllowRemove(false)
      setAllowEdit(true)
      setViewSticker(sticker)
    }
  }

  const handleAddSticker = (sticker: EmotionometerStickerBase) => {
    if (selectStickerProps) {
      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_EMOTIONOMETER_STICKER,
        emotionometerType: selectStickerProps.emotion,
        emotionometerDevice: selectStickerProps.device,
        emotionometerRange: selectStickerProps.emotionRange,
        sticker: { ...sticker, emotionRange: selectStickerProps.emotionRange },
      })
    } else {
      console.warn('stickerProps not defined but trying to add existing sticker BAD')
    }

    setSelectStickerProps(undefined)
  }

  const handleRemoveSticker = (sticker: EmotionometerStickerBase) => {
    if (!!sticker.emotionRange)
      gadgetPackDispatch({
        ...getBaseAction(),
        type: REMOVE_EMOTIONOMETER_STICKER,
        emotionometerType: emotion,
        emotionometerDevice: sticker.type,
        emotionometerRange: sticker.emotionRange,
        stickerId: sticker.id,
      })
    setViewSticker(undefined)
  }

  const handleAddCustomSticker = (sticker: EmotionometerStickerBase) => {
    if (selectStickerProps) {
      const addedSticker: EmotionometerSticker<EmotionometerDevice> = {
        ...sticker,
        id: sticker.id || 'sticker' + Date.now(),
        type: sticker.type,
        emotion: emotionometer.type,
        emotionRange: selectStickerProps.emotionRange,
      }

      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_UPDATE_CUSTOM_STICKER,
        emotionometerType: emotion,
        emotionometerDevice: sticker.type,
        emotionometerRange: selectStickerProps.emotionRange,
        sticker: addedSticker,
      })

      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_EMOTIONOMETER_STICKER,
        emotionometerType: emotion,
        emotionometerDevice: sticker.type,
        emotionometerRange: selectStickerProps.emotionRange,
        sticker: { ...addedSticker, index: selectStickerProps.index },
      })
    } else {
      const addedCustomSticker: EmotionometerSticker<EmotionometerDevice> = {
        ...sticker,
        id: sticker.id || 'sticker' + Date.now(),
        type: sticker.type,
        emotion: emotionometer.type,
        emotionRange: '1-3',
      }
      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_UPDATE_CUSTOM_STICKER,
        emotionometerType: emotion,
        emotionometerDevice: sticker.type,
        emotionometerRange: '1-3', //selectStickerProps.emotionRange,
        sticker: addedCustomSticker,
        disableAutoAdd: true,
      })
    }
    setShowCustomModal(false)
  }

  const handleUpdateSticker = (data: EmotionometerSticker<EmotionometerDevice>, sticker: EmotionometerStickerBase) => {
    const updatedSticker: EmotionometerSticker<EmotionometerDevice> = {
      ...data,
      id: data.id || 'sticker' + Date.now(),
      type: data.type || sticker.type,
      emotion: emotionometer.type,
      emotionRange: data.emotionRange || sticker.emotionRange,
    }
    gadgetPackDispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_STICKER,
      sticker: updatedSticker,
      emotionometerType: emotionometer.type,
      emotionometerRange: sticker.emotionRange,
    } as AddUpdateCustomStickerAction)
  }

  const handleViewClose = () => {
    setViewSticker(undefined)
    setAllowRemove(true)
  }

  const updateDragItemSize = (size: number) => setDragItemSize(size)

  const handleStickerDrop = (
    sticker: EmotionometerStickerBase,
    replaceSticker: EmotionometerStickerBase | undefined,
    index: number
  ) => {
    handleRemoveSticker(sticker)
    if (replaceSticker) handleRemoveSticker(replaceSticker)

    const emotionRange: EmotionometerRange = emotionRangeValues[emotionRangeDistribution[index]]
    gadgetPackDispatch({
      ...getBaseAction(),
      type: ADD_EMOTIONOMETER_STICKER,
      emotionometerType: emotion,
      emotionometerDevice: sticker.type,
      emotionometerRange: emotionRange,
      sticker: { ...sticker, emotionRange, index },
    })
  }

  return (
    <ActivityWrapper>
      <CustomDragLayer size={dragItemSize} />
      <SectionsPanelStyled className="emotionometer-top-panel" panelTheme={emotionPanelThemes[emotion]}>
        <PanelTitle panelTheme={emotionPanelThemes[emotion]} style={{ boxShadow: 'none' }}>
          <H1 style={{ fontStyle: 'italic' }}>{title}</H1>
        </PanelTitle>
        <div className="panel-padding" style={{ padding: '15px 20px' }}>
          <StickerRow
            activeEmotionRangeIndexes={activeEmotionRangeIndexes}
            active={!viewAllDevices && device === 'relaxationGadgets'}
            viewable={viewAllDevices || device === 'relaxationGadgets'}
            emotion={emotion}
            type="relaxationGadgets"
            expanded={shownDevices.indexOf('relaxationGadgets') >= 0}
            toggleExpanded={toggleDeviceExpanded}
            emotionometer={emotionometer}
            onSelect={handleStickerSelect}
            onAdd={handleEmptyStickerSelect}
            selectedIndex={selectStickerProps?.index}
            updateDragItemSize={updateDragItemSize}
            handleStickerDrop={handleStickerDrop}
          />
          <hr />
          <RatingWrapper>
            <EmotionometerBar
              type={emotion}
              value={[0, 1, 2]}
              distribution={emotionRangeDistribution}
              labels={['Low', 'Medium', 'High']}
            />
          </RatingWrapper>

          <hr />
          <StickerRow
            activeEmotionRangeIndexes={activeEmotionRangeIndexes}
            active={!viewAllDevices && device === 'bodyClues'}
            viewable={viewAllDevices || device === 'bodyClues' || device === 'relaxationGadgets'}
            emotion={emotion}
            type="bodyClues"
            expanded={shownDevices.indexOf('bodyClues') >= 0}
            toggleExpanded={toggleDeviceExpanded}
            emotionometer={emotionometer}
            onSelect={handleStickerSelect}
            onAdd={handleEmptyStickerSelect}
            selectedIndex={selectStickerProps?.index}
            updateDragItemSize={updateDragItemSize}
            handleStickerDrop={handleStickerDrop}
          />
          <hr />
          <StickerRow
            activeEmotionRangeIndexes={activeEmotionRangeIndexes}
            active={!viewAllDevices && device === 'situations'}
            viewable={true}
            emotion={emotion}
            type="situations"
            expanded={shownDevices.indexOf('situations') >= 0}
            toggleExpanded={toggleDeviceExpanded}
            emotionometer={emotionometer}
            onSelect={handleStickerSelect}
            onAdd={handleEmptyStickerSelect}
            selectedIndex={selectStickerProps?.index}
            updateDragItemSize={updateDragItemSize}
            handleStickerDrop={handleStickerDrop}
          />
        </div>
      </SectionsPanelStyled>

      {!hideStickerTray && (
        <SectionsPanelStyled style={{ marginTop: '1.5rem' }} panelTheme={null} flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR]}>
          <div className="panel-padding" style={{ padding: '30px 20px' }}>
            <H2 marginBottom="m">{emotionometerDeviceLabels[device]} Stickers</H2>
            <ReturnDropZone onDrop={(sticker) => handleRemoveSticker(sticker)}>
              <Row flex flexWrap marginRight={-10}>
                {[...allStickers, undefined].map((sticker, i) => {
                  const used = (sticker || false) && selectedStickerIds.indexOf(sticker.id) >= 0
                  const disabled = atCapacity || used
                  const selected = !!viewSticker && !!sticker && viewSticker.id === sticker.id
                  const node = (
                    <StickerTile<typeof device>
                      selected={selected}
                      disabled={disabled}
                      sticker={sticker}
                      placeholder={`Create custom ${emotionometerDeviceLabels[device].toLowerCase()} sticker + `}
                      onEdit={() => handleTrayStickerSelect(sticker)}
                    />
                  )
                  return (
                    <Column key={i} paddingRight="s" paddingBottom="s">
                      {sticker ? (
                        <DraggableSticker
                          sticker={sticker}
                          origin="tray"
                          disabled={disabled}
                          onMove={() => {}}
                          updateDragItemSize={updateDragItemSize}>
                          {node}
                        </DraggableSticker>
                      ) : (
                        node
                      )}
                    </Column>
                  )
                })}
              </Row>
            </ReturnDropZone>
          </div>
        </SectionsPanelStyled>
      )}

      {!!viewSticker && (
        <StickerViewModal
          sticker={viewSticker}
          editMode={allowEdit}
          emotionometerType={emotionometer.type}
          onClose={handleViewClose}
          onRemove={allowRemove ? () => handleRemoveSticker(viewSticker) : undefined}
          onUpdateSticker={(data) => handleUpdateSticker(data, viewSticker)}
          readonly={readonly}
        />
      )}

      <CustomStickerModal
        isOpen={showCustomModal}
        onAdd={handleAddCustomSticker}
        emotion={emotion}
        type={device}
        onRequestClose={() => {
          setShowCustomModal(false)
        }}
      />
    </ActivityWrapper>
  )
}

const ReturnDropZone: React.FC<{ onDrop: (item: EmotionometerStickerBase) => void }> = ({ onDrop, children }) => {
  const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
    accept: DRAG_TYPE_EMOTIONOMETER_STICKER,
    drop: (item, monitor) => {
      onDrop((item as StickerDragItem).sticker)
    },
    canDrop: (item, monitor) => {
      return (item as StickerDragItem).from !== 'tray'
    },
    collect: (monitor) => ({
      itemIsOver: monitor.isOver(),
      itemBeingDragged: monitor.canDrop(),
    }),
  })
  return <ReturnDropZoneWrapper ref={drop} children={children} {...{ itemIsOver, itemBeingDragged }} />
}

interface ReturnDropZoneWrapperProps {
  itemIsOver?: boolean
  itemBeingDragged?: boolean
}

export const ReturnDropZoneWrapper = styled.div<ReturnDropZoneWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 15px 5px 15px;
  border-radius: 5px;
  ${(p) =>
    p.itemBeingDragged && p.itemIsOver
      ? css`
          background-color: white;
        `
      : ''}
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-radius: 5px;
    ${(p) =>
      p.itemBeingDragged
        ? css`
            ${p.itemIsOver
              ? css`
                  box-shadow:
                    inset 0 0 0 2px #ffffff,
                    inset 0 0 12px 0 rgba(0, 0, 0, 0.75);
                `
              : css`
                  box-shadow:
                    inset 0 0 0 2px #ffffff,
                    inset 0 0 8px 0 rgba(0, 0, 0, 0.5);
                `}
          `
        : css`
            box-shadow: inset 0 0 0 1px #c9cfe8;
          `}
  }
`

const getInitialState = (): ActivityEmotionometerState => {
  return {
    high: [],
    medium: [],
    low: [],
  }
}

export const EmotionometerActivityFacilitator: React.FC<Props> = ({ section }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('activity_emotionometer', section, getInitialState)

  const updateSectionState = (state: ActivityEmotionometerState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_emotionometer',
      section_id: section.id,
      state: state,
    })
  }

  const selectAll = (key: EmotionRange) => {
    updateSectionState({
      ...sectionState,
      [key]: state.participants.map(({ profile }) => profile.uid),
    })
  }

  return (
    <FacilitatorControls title="Turn Controls">
      <FacilitatorControlsTable>
        <thead>
          <tr>
            <th colSpan={2} />
            {state.participants.map((participant, i) => (
              <th key={i}>{participant.profile.displayName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {emotionRanges.map((key, x) => (
            <tr key={x}>
              <td style={{ textTransform: 'uppercase' }}>{key}</td>
              <td style={{ width: 90 }}>
                <Button theme="purple" size="xxs" children="select all ->" onClick={() => selectAll(key)} />
              </td>
              {state.participants.map((participant, i) => {
                const selected = sectionState[key].indexOf(participant.profile.uid) >= 0
                return (
                  <td key={i} style={{ padding: '10px' }}>
                    <Checkbox
                      size="s"
                      checked={selected}
                      onChange={(value) => {
                        updateSectionState({
                          ...sectionState,
                          [key]: selected
                            ? sectionState[key].filter((uid) => uid !== participant.profile.uid)
                            : [...sectionState[key], participant.profile.uid],
                        })
                      }}
                    />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </FacilitatorControlsTable>
    </FacilitatorControls>
  )
}

const RatingWrapper = styled.div`
  ${Bar} {
    border-radius: 30px;
    color: #011a46;
    border: 1px solid rgba(0, 0, 0, 0.4);
    height: 40px;
    box-shadow:
      0px -0.5px 2px rgba(255, 255, 255, 0.45),
      inset 0px -3.32095px 14.114px rgba(0, 0, 0, 0.3);

    @media (max-width: 500px) {
      font-size: 12px;
      height: 26px;
    }
  }

  ${Spacer} {
    width: 6px;
  }

  label {
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`

const ActivityWrapper = styled.div`
  ${AccordionBody} ${Row} {
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }

    ${Column} {
      @media (max-width: 500px) {
        flex: 1 1 18%;
      }
    }
  }

  ${TileDiv} span {
    @media (max-width: 500px) {
      font-size: 0.5rem;
    }
  }
`
