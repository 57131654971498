import { QuestionnaireResponse } from 'shared/questionnaires/types'

export const observationalCodeRanges: [number, number][] = [
  [1, 4],
  [5, 12],
  [13, 19],
  [20, 26],
  [27, 30],
]

const getUserResponse = (index: number, responses: QuestionnaireResponse[]) =>
  responses.find((res) => res.unique_key === `panel_0_observational_0_option_${index}`)

export const getResponsesSubscale = (responses: QuestionnaireResponse[]) =>
  getResponsesTotal(responses) / responses.length || 0

export const getResponsesTotal = (responses: QuestionnaireResponse[]) =>
  responses.reduce(
    (total, response) => total + (response.score !== null && response.score !== undefined ? response.score + 1 : 0),
    0
  )

export const getUserResponsesFromRange = (start: number, end: number, responses: QuestionnaireResponse[]) =>
  [...Array(end - (start - 1))]
    .map((_, i) => getUserResponse(start + i, responses))
    .filter((_) => _) as QuestionnaireResponse[]

export const getSubscaleScores = (responses: QuestionnaireResponse[]) =>
  observationalCodeRanges.map((range) => {
    const rangeResponses = getUserResponsesFromRange(range[0], range[1], responses)
    return getResponsesSubscale(rangeResponses)
  })

export const getSubscaleTotal = (subscaleScores: number[]) =>
  subscaleScores.reduce((total, subscale) => total + subscale, 0)

export const getSubscaleOverall = (responses: QuestionnaireResponse[]) => {
  const subscaleScores = getSubscaleScores(responses)
  const subscaleTotal = getSubscaleTotal(subscaleScores)
  const applicableSubscaleScores = subscaleScores.filter((num) => num > 0)
  return subscaleTotal / applicableSubscaleScores.length || 0
}
