import { H1, P, Spacer } from 'common/ui'
import { fontBold, fontRegular } from 'fonts'
import React from 'react'
import { TipsheetType } from 'shared/tipsheets/types'
import styled from 'styled-components'

interface Props {
  tipsheetType: TipsheetType
  moduleTitle: string
  name: string
}

export const TipsheetHeader: React.FC<Props> = ({ tipsheetType, moduleTitle, name }) => {
  return (
    <HeaderContainer>
      <Banner>
        <H1>
          {tipsheetType === 'teacher' && 'Teacher Tip Sheet'}
          {tipsheetType === 'community' && 'Community Activity Tip Sheet'}
          <br />
          {moduleTitle}
        </H1>
        <img src={require('../assets/tipsheet-header.png')} alt="" />
      </Banner>
      {name && (
        <>
          <Spacer size="m" />
          <Wrapper>
            <P>Child's Name:</P>
            <P>{name}</P>
          </Wrapper>
        </>
      )}
    </HeaderContainer>
  )
}

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.tipsheetPastelColor};
  padding: 15px;
  border-radius: 10px;

  ${H1} {
    color: ${(p) => p.theme.buttonBorderTopColor};
  }
`

const Banner = styled(Wrapper)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  img {
    @media (max-width: 1066px) {
      width: 300px;
    }
  }

  ${H1} {
    padding: 0px 50px;
    min-width: 120px;

    @media (max-width: 786px) {
      padding: 0px 20px;
    }
  }
  /* background-image: url(${require('../assets/tipsheet-header.png')}); */
  /* background-repeat: no-repeat;
  background-position: right; */
`

const HeaderContainer = styled.div`
  ${P} {
    ${fontBold};
    margin: 0;
    font-size: 0.8rem;

    &:last-child {
      ${fontRegular};
      font-size: 1.3rem;
    }
  }
`
