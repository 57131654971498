import React, { Fragment } from 'react'
import styled from 'styled-components'

import { SectionProperty, SectionPropsBase, TipsheetStepEntity } from '../shared/tipsheets/types'

import { Theme } from 'themes'
import { getSections } from './reducers'

import { Container, H1 } from 'common/ui'

import { InputContext, useInputContext } from 'session/InputContext'
import { RichTextContainer } from 'session/common/RichText'
import { BlockBody, BlockMedia, GenericBlockSection } from 'session/sections/Block'
import { GenericInputSection } from 'session/sections/Input'
import { GenericDividerSection } from '../session/sections/Divider'
import { GenericDownloadResourceSection } from '../session/sections/DownloadResourceSection'
import { GenericListSection, ListContainer } from '../session/sections/List'
import { GenericGridSection } from '../session/sections/MediaGrid'
import { GenericTextSection } from '../session/sections/Text'
import { HeadingDividerSection } from './sections/HeadingDivider'
import { BorderedSection } from './sections/TipsheetBorderedSection'
import { TipsheetEmbeddedActivitySection } from './sections/TipsheetEmbeddedActivitySection'

export const sectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key }>
} = {
  divider_sections: GenericDividerSection,
  list_sections: GenericListSection,
  media_grid_sections: GenericGridSection,
  text_sections: GenericTextSection,
  heading_divider_sections: HeadingDividerSection,
  block_sections: GenericBlockSection,
  input_sections: GenericInputSection,
  bordered_sections: BorderedSection,
  tipsheet_embedded_activity_sections: TipsheetEmbeddedActivitySection,
  download_resource_sections: GenericDownloadResourceSection,
}

export const TipsheetStep: React.FC<{ step: TipsheetStepEntity; viewOnly?: boolean; printMode?: boolean }> = ({
  step,
  viewOnly = false,
  printMode = false,
}) => {
  const sections = getSections(step)
  const inputContext = useInputContext()

  return (
    <TipsheetPanel style={{ position: 'relative' }}>
      {step.title && !step.hide_header && (
        <Heading>
          <H1>{step.title}</H1>
        </Heading>
      )}

      {sections
        .map((sectionObj, i) => {
          const Component = sectionComponents[sectionObj.property]
          // @ts-ignore
          return <Component key={i} {...sectionObj} printMode={printMode} />
        })
        .map((node, i) => (
          <InputContext.Provider
            key={i}
            children={node}
            value={{
              ...inputContext,
              owner: sections[i].property,
              owner_id: sections[i].section.id,
            }}
          />
        ))
        .map((component, idx) => {
          return (
            <Fragment key={idx}>
              {viewOnly && (
                <Disabler>
                  <Container marginBottom={30} children={component} />
                </Disabler>
              )}
              {!viewOnly && <Container marginBottom={30} children={component} />}
            </Fragment>
          )
        })}
    </TipsheetPanel>
  )
}

export const getListArrow = (themeName: Theme['name']) => {
  const img = require(`session/assets/list-bullet-${themeName}.svg`)
  return `url(${img}) no-repeat right top`
}

const Disabler = styled.div`
  pointer-events: none;
`

export const TipsheetPanel = styled.div`
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  ${ListContainer}, ${RichTextContainer} {
    li {
      &::before {
        top: 4px;
        height: 1.15em;
        background: ${(p) => getListArrow(p.theme.name)};
      }
    }
  }

  ${Container}:last-of-type {
    margin-bottom: 0;
  }

  ${H1} {
    color: ${(p) => p.theme.buttonBorderTopColor};
  }

  ${BlockBody} {
    background: none;
    border: none;

    h2 strong {
      line-height: 1.2;
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  ${BlockMedia} {
    border-color: #bdc3e0;
    border-radius: 10px;
  }
`

const Heading = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
`
