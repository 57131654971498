import React, { useEffect } from 'react'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

import { NavRouteProps } from 'dashboards/types'

import { SpinnerWithLabel } from 'common/Spinner'
import { Cells } from 'dashboards/common/Cell'

import { Page } from 'dashboards/common/Page'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'

import { getCommunityTipsheets } from 'api'
import { useUserState } from 'app/UserState'
import { CommunityTipsheetCell } from 'dashboards/common/TipsheetCommunityCell'
import { useApiEndpoint } from 'dashboards/utils/endpointHooks'
import { useMentorDashData, useMentorDashState } from './MentorDashboardState'
import { mentorDashboardBaseUrl } from './constants'
import { moduleCodes } from 'shared/dashboard/moduleCodes'
import { P } from 'common/ui'
import { sortByKey } from 'utils/sortUtils'

export const CommunityTipsheets: React.FC<NavRouteProps> = ({ route }) => {
  const { accessToken } = useUserState()
  const {
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
      group: { value: group, fetch: fetchGroup, loading: loadingGroup, loaded: loadedGroup },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions', [])

  const [tipsheets, { loading: loadingTipsheets }] = useApiEndpoint(
    getCommunityTipsheets,
    !loadingGroup && !!group,
    accessToken,
    group ? [group.provider_uid] : []
  )

  useEffect(() => {
    if (!loadedGroup) fetchGroup()
  }, [loadedGroup, fetchGroup])

  if (loadingMentor) return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />
  if (mentor && !mentor.permission_community_tip_sheets) return <NotAuthorizedPage />
  if (loadingSessions || !sessions || loadingTipsheets || loadingGroup)
    return <Page route={route} children={<SpinnerWithLabel label="Loading tipsheets..." />} />
  if (!tipsheets) return <Page route={route} children={<P>No community tip sheets available at the moment</P>} />

  const nonModuleTipsheets = tipsheets.filter((tipsheet) => !moduleCodes.cadet.includes(tipsheet.module_code))

  const validSessions = sortBy(
    uniqBy(
      sessions.filter(({ type, concluded }) => concluded),
      'module_code'
    ),
    'module_code'
  )

  const validSessionTipsheets = tipsheets.filter(({ module_code }) =>
    validSessions.some((session) => session.module_code === module_code)
  )

  const allTipsheets = [...nonModuleTipsheets, ...validSessionTipsheets].sort(sortByKey('order'))

  return (
    <Page route={route}>
      <Cells>
        {allTipsheets.map((tipsheet) => (
          <CommunityTipsheetCell key={tipsheet.uid} baseUrl={mentorDashboardBaseUrl} tipsheet={tipsheet} />
        ))}
      </Cells>
    </Page>
  )
}
