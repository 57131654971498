import { NavRoute } from '../types'

import { CommunityTipsheetMentor, TeacherTipsheetMentor } from 'tipsheets/TipsheetMentor'
import { ClubJournal } from './ClubJournal'
import { Contact } from './Contact'
import { DigiHQ } from './DigiHQ'
import { Help } from './Help'
import { Home } from './Home'
import { MeetingNotes } from './MeetingNotes'
import { Meetings } from './Meetings'
import { MentorGadgetsQuickView } from './MentorGadgetsQuickView'
import { Progress } from './Progress'
import { QuestionnaireDashboardView } from './QuestionnaireView'
import { Questionnaires } from './Questionnaires'
import { CommunityTipsheets } from './TipsheetsCommunity'
import { TeacherTipsheets } from './TipsheetsTeacher'
import { FEATURE_communityTipSheets_providerUuids } from 'dashboards/constant/FEATURE_consts'

type InaccessibleFunction = Exclude<NavRoute['inaccessible'], undefined | boolean>

const licenceHasExpired: InaccessibleFunction = (user, { mentor, cadetMentor }) => {
  return (
    !mentor ||
    (mentor.primary_licence_holder
      ? mentor.product_key_expired === true
      : !cadetMentor || cadetMentor.product_key_expired === true)
  )
}

const isNotPrimaryMentor: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.primary_licence_holder !== true
}

const lacksGadgetPackAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_gadget_pack !== true
}

const lacksScheduledMeetingAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || (mentor.permission_live_parent_sessions !== true && mentor.permission_reporting !== true)
}

const lacksPastCadetSessionAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_past_cadet_sessions !== true
}

const lacksPastParentSessionAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_past_parent_sessions !== true
}

const lacksReportingAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_reporting !== true
}

const lacksTipSheetAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_tip_sheets !== true
}

const lacksCommunityTipSheetAccess: InaccessibleFunction = (user, { mentor }) => {
  return !mentor || mentor.permission_community_tip_sheets !== true
}

const lacksAnyQuestionnaireAccess: InaccessibleFunction = (user, { mentor }) => {
  return (
    !mentor || (mentor.permission_parent_questionnaires !== true && mentor.permission_teacher_questionnaires !== true)
  )
}

const FEATURE_communityTipSheets_lacksAllowedProviderUuid: InaccessibleFunction = (user, additionalData) => {
  const providers = additionalData.providers || []
  const providerUuids = providers.map((provider) => provider.id)
  return !providerUuids.some((uuid) => FEATURE_communityTipSheets_providerUuids.includes(uuid))
}

export const routes: NavRoute[] = [
  {
    title: 'Home',
    path: '',
    exact: true,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 22,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16251 8.44812L11.5 2.11063L17.8375 8.44812H17.8361V19.0083H5.16393V8.44812H5.16251ZM3.0519 10.5587L1.49236 12.1183L0 10.6259L10.0078 0.618075C10.8319 -0.206025 12.1681 -0.206025 12.9922 0.618075L23 10.6259L21.5076 12.1183L19.9481 10.5588V19.0083C19.9481 20.1747 19.0025 21.1203 17.8361 21.1203H5.16393C3.99749 21.1203 3.0519 20.1747 3.0519 19.0083V10.5587Z"
        />
      ),
    },
    Page: Home,
  },
  {
    title: 'SAS Digital HQ',
    path: '/digi-hq',
    exact: true,
    inaccessible: isNotPrimaryMentor,
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 24,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
          fill="#011A46"
        />
      ),
    },
    Page: DigiHQ,
  },
  {
    title: 'Scheduled Meetings',
    path: '/meetings',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksScheduledMeetingAccess(...args),
    icon: {
      viewboxWidth: 22,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.89998 4.4H7.09998V3.3H15.9V4.4H18.1V3.3H20.3V6.59999H2.69999V3.3H4.89998V4.4ZM2.69999 8.79999V19.8H20.3V8.79999H2.69999ZM7.09998 1.09999H15.9V0H18.1V1.09999H20.3C21.515 1.09999 22.5 2.08497 22.5 3.29999V19.8C22.5 21.015 21.515 22 20.3 22H2.7C1.48497 22 0.5 21.015 0.5 19.8V3.29999C0.5 2.08497 1.48497 1.09999 2.7 1.09999H4.89998V0H7.09998V1.09999Z"
        />
      ),
    },
    Page: Meetings,
  },
  {
    title: 'Progress',
    path: '/progress',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksReportingAccess(...args),
    icon: {
      viewboxWidth: 22,
      viewboxHeight: 18,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H20C21.1046 0 22 0.89543 22 2V16C22 17.1046 21.1046 18 20 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM2 2V16H20V2H2ZM6 14H8V8H6V14ZM12 14H10V4H12V14ZM14 14H16V7H14V14Z"
          fill="#011A46"
        />
      ),
    },
    Page: Progress,
  },

  {
    title: 'Parent Meeting Notes',
    path: '/meeting-notes',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksPastParentSessionAccess(...args),
    icon: {
      viewboxWidth: 21,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7 23H3.45C1.54462 23 0 21.4554 0 19.55V3.45C0 1.54462 1.54462 0 3.45 0H20.7V18.4C20.0649 18.4 19.55 18.9149 19.55 19.55C19.55 20.1851 20.0649 20.7 20.7 20.7V23ZM17.25 19.55C17.25 19.1468 17.3192 18.7597 17.4463 18.4H3.45C2.81487 18.4 2.3 18.9149 2.3 19.55C2.3 20.1851 2.81487 20.7 3.45 20.7H17.4463C17.3192 20.3403 17.25 19.9532 17.25 19.55ZM3.45 2.3H18.4V16.1H3.45C3.04677 16.1 2.6597 16.1692 2.3 16.2963V3.45C2.3 2.81487 2.81487 2.3 3.45 2.3Z"
          fill="#011A46"
        />
      ),
    },
    Page: MeetingNotes,
  },

  {
    title: 'Club Journal',
    path: '/club-journal',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksPastCadetSessionAccess(...args),
    icon: {
      viewboxWidth: 21,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7 23H3.45C1.54462 23 0 21.4554 0 19.55V3.45C0 1.54462 1.54462 0 3.45 0H20.7V18.4C20.0649 18.4 19.55 18.9149 19.55 19.55C19.55 20.1851 20.0649 20.7 20.7 20.7V23ZM17.25 19.55C17.25 19.1468 17.3192 18.7597 17.4463 18.4H3.45C2.81487 18.4 2.3 18.9149 2.3 19.55C2.3 20.1851 2.81487 20.7 3.45 20.7H17.4463C17.3192 20.3403 17.25 19.9532 17.25 19.55ZM3.45 2.3H18.4V16.1H3.45C3.04677 16.1 2.6597 16.1692 2.3 16.2963V3.45C2.3 2.81487 2.81487 2.3 3.45 2.3Z"
          fill="#011A46"
        />
      ),
    },
    Page: ClubJournal,
  },

  {
    title: 'Gadgets Quick View',
    path: '/gadget-pack',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksGadgetPackAccess(...args),
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 22,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.20001 0.649994H13.8C15.0703 0.649994 16.1 1.67974 16.1 2.94999V4.09998H20.7C21.9703 4.09998 23 5.12973 23 6.39998V19.05C23 20.3202 21.9703 21.35 20.7 21.35H2.3C1.02974 21.35 0 20.3202 0 19.05V6.39998C0 5.12973 1.02974 4.09998 2.3 4.09998H6.90001V2.94999C6.90001 1.67974 7.92975 0.649994 9.20001 0.649994ZM6.90001 6.39999V6.39998H2.30001V12.15H10.35H12.65H20.7V6.39998L16.1 6.39999H6.90001ZM2.30001 19.05V14.45H10.35V15.6H12.65V14.45H20.7V19.05H2.30001ZM13.8 2.95001V4.10001H9.19999V2.95001H13.8Z"
          fill="#011A46"
        />
      ),
    },
    Page: MentorGadgetsQuickView,
  },
  {
    title: 'Teacher Tip Sheets',
    path: '/teacher-tip-sheets',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksTipSheetAccess(...args),
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 24,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
          fill="#011A46"
        />
      ),
    },
    Page: TeacherTipsheets,
    subpages: [
      {
        title: 'Teacher Tip Sheet',
        path: '/:moduleCode',
        exact: true,
        hideFromNav: true,
        Page: TeacherTipsheetMentor,
      },
    ],
  },
  {
    sidebarTitle: 'Community Activity Tip Sheets',
    title: 'Community Activity Tip Sheets',
    path: '/community-tip-sheets',
    exact: true,
    inaccessible: (...args) =>
      licenceHasExpired(...args) ||
      lacksCommunityTipSheetAccess(...args) ||
      FEATURE_communityTipSheets_lacksAllowedProviderUuid(...args),
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 24,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
          fill="#011A46"
        />
      ),
    },
    Page: CommunityTipsheets,
    subpages: [
      {
        title: 'Community Activity Tip Sheet',
        path: '/:moduleCode/:language?',
        exact: true,
        hideFromNav: true,
        Page: CommunityTipsheetMentor, // TODO!
      },
    ],
  },
  {
    title: 'Questionnaires',
    path: '/questionnaires',
    exact: true,
    inaccessible: (...args) => licenceHasExpired(...args) || lacksAnyQuestionnaireAccess(...args),
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 24,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
          fill="#011A46"
        />
      ),
    },
    Page: Questionnaires,
    subpages: [
      {
        title: 'Questionnaire',
        path: '/:booklet/:userType/:questionnaireType',
        exact: true,
        hideFromNav: true,
        Page: QuestionnaireDashboardView,
      },
    ],
  },
  /*
  {
    title: 'Shop',
    path: '/shop',
    exact: true,
    icon: {
      viewboxWidth: 24,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68193 2.30292C4.47769 2.30248 4.28081 2.3256 4.09372 2.36955C3.83725 1.80427 3.49848 1.29713 3.11317 0.911827C2.4592 0.257854 1.42778 0 0 0V2.3C0.872215 2.3 1.37413 2.42548 1.48683 2.53817C1.92149 2.97284 2.3 3.85602 2.3 4.6L2.31156 4.76263L3.43841 12.6506C1.55291 12.7484 0.0922273 14.2126 0.00141287 16.043L0 17.25C0.111344 19.1405 1.56717 20.5948 3.38472 20.6981L3.6458 20.6985C4.119 22.0393 5.3973 23 6.9 23C8.40215 23 9.68008 22.04 10.1537 20.7H12.8463C13.3199 22.04 14.5978 23 16.1 23C18.0054 23 19.55 21.4554 19.55 19.55C19.55 17.6446 18.0054 16.1 16.1 16.1C14.5978 16.1 13.3199 17.06 12.8463 18.4H10.1537C9.68008 17.06 8.40215 16.1 6.9 16.1C5.39785 16.1 4.11992 17.06 3.64631 18.4H3.45C2.86351 18.3648 2.33742 17.8393 2.29794 17.1811L2.3 16.1C2.33101 15.5035 2.85351 14.981 3.50699 14.9486L5.77725 14.9492L5.7937 14.95H17.3844L17.5541 14.8961C18.4261 14.6195 19.1241 13.9629 19.4546 13.1129L19.5757 12.8731L19.9694 12.0933C20.3766 11.286 20.7838 10.4774 21.1807 9.68737C22.1527 7.75223 22.764 6.52241 22.8994 6.22041C23.5946 4.66952 22.1863 3.47583 20.7468 3.45041L4.68193 2.30292ZM16.9848 12.65H5.8798C5.80596 12.6295 5.74969 12.5669 5.73867 12.489L4.61179 4.60084L20.5774 5.74416C20.298 6.31387 19.7902 7.33143 19.1254 8.655L19.1087 8.68817C18.7292 9.44364 18.3225 10.2512 17.9159 11.0573L17.5227 11.8362L17.3737 12.1312L17.3176 12.2616C17.2575 12.4289 17.1382 12.5665 16.9848 12.65ZM16.1 20.7C16.7351 20.7 17.25 20.1851 17.25 19.55C17.25 18.9149 16.7351 18.4 16.1 18.4C15.4649 18.4 14.95 18.9149 14.95 19.55C14.95 20.1851 15.4649 20.7 16.1 20.7ZM8.05 19.55C8.05 20.1851 7.53513 20.7 6.9 20.7C6.26487 20.7 5.75 20.1851 5.75 19.55C5.75 18.9149 6.26487 18.4 6.9 18.4C7.53513 18.4 8.05 18.9149 8.05 19.55Z"
        />
      ),
    },
    Page: DummyRoute,
  },
  */
  {
    title: 'Help',
    path: '/help',
    exact: true,
    inaccessible: false,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5ZM20.9091 11.5C20.9091 16.6965 16.6965 20.9091 11.5 20.9091C6.3035 20.9091 2.09091 16.6965 2.09091 11.5C2.09091 6.3035 6.3035 2.09091 11.5 2.09091C16.6965 2.09091 20.9091 6.3035 20.9091 11.5ZM11.5003 16.7255C12.0779 16.7255 12.5461 16.2575 12.5461 15.6801C12.5461 15.1027 12.0779 14.6346 11.5003 14.6346C10.9228 14.6346 10.4545 15.1027 10.4545 15.6801C10.4545 16.2575 10.9228 16.7255 11.5003 16.7255ZM10.4545 13.5909H12.5455C12.5455 12.7563 12.6766 12.6033 13.5357 12.1737C15.0288 11.4272 15.6818 10.6654 15.6818 8.88636C15.6818 6.60793 13.8882 5.22727 11.5 5.22727C9.19045 5.22727 7.31818 7.09954 7.31818 9.40909H9.40909C9.40909 8.25431 10.3452 7.31818 11.5 7.31818C12.8352 7.31818 13.5909 7.89988 13.5909 8.88636C13.5909 9.721 13.4598 9.87398 12.6006 10.3036C11.1075 11.0501 10.4545 11.8119 10.4545 13.5909Z"
        />
      ),
    },
    Page: Help,
  },
  {
    title: 'Contact',
    path: '/contact',
    exact: true,
    inaccessible: false,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 17,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.09091 2.09091V11.5H0V14.6364C0 15.7911 0.936132 16.7273 2.09091 16.7273H15.6818H20.9091C22.0639 16.7273 23 15.7911 23 14.6364V5.22727C23 4.0725 22.0639 3.13636 20.9091 3.13636V2.09091C20.9091 0.936132 19.973 0 18.8182 0H4.18182C3.02704 0 2.09091 0.936132 2.09091 2.09091ZM18.8182 3.13636V2.09091H4.18182V11.5H13.5909V5.22727C13.5909 4.0725 14.527 3.13636 15.6818 3.13636L18.8182 3.13636ZM2.09091 13.5909H13.5909V14.6364H2.09091V13.5909ZM15.6818 14.6364V5.22728H20.9091V14.6364H15.6818Z"
        />
      ),
    },
    Page: Contact,
  },
]
