import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import {
  Panel,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  H1,
  H2,
  H3,
  CUT_BL_DARK,
  CUT_BR_DARK,
  CUT_TL_DARK,
  CUT_TR_DARK,
  TAB_B_L,
  TAB_B_DARK,
  TAB_T_DARK,
  TAB_T_L,
  Label,
  P,
} from 'common/ui'
import { SectionPanel, SectionProperty, SectionPropsBase } from 'shared/session/types'
import { getSections } from 'session/reducers'

import { BlockBody, BlocksSection, BlocksSectionFacilitator } from 'session/sections/Block'
import { CardDeckSection, CardDeckSectionFacilitator } from 'session/sections/CardDeck'
import { DividerSection, DividerSectionFacilitator } from 'session/sections/Divider'
import { DrawingSection, DrawingSectionFacilitator } from 'session/sections/drawing/Drawing'
import { EmbeddedActivitySection, EmbeddedActivitySectionFacilitator } from 'session/sections/EmbeddedActivity'
import { EmotionometerSection, EmotionometerSectionFacilitator } from 'session/sections/Emotionometer'
import { GroupedSection, GroupedSectionFacilitator, ShowText, ToggleAllText } from 'session/sections/Grouped'
import { FormField, InputSection, InputSectionFacilitator } from 'session/sections/Input'
import { ListContainer, ListSection, ListSectionFacilitator } from 'session/sections/List'
import { MediaGridSection, MediaGridSectionFacilitator } from 'session/sections/MediaGrid'
import { NotificationSection, NotificationSectionFacilitator } from 'session/sections/Notification'
import { TextSection, TextSectionFacilitator } from 'session/sections/Text'
import { TokenTargetSection, TokenTargetSectionFacilitator } from 'session/sections/TokenTarget'
import { TurnTakingSection, TurnTakingSectionFacilitator } from 'session/sections/TurnTaking'

import { HighlightableArea } from './HighlightableArea'
// import { LockableArea } from './LockableArea'
import { InputContext, useInputContext } from 'session/InputContext'
import { RichTextContainer } from './RichText'

import { useSessionState } from 'session/SessionState'
import { useFacilitatorState } from 'session/hooks/useProfileState'
import { Notification } from 'session/sections//Notification'
import { AccordionTitle } from './Accordion'
import { CircleButton } from './CircleButton'
import { CodeCardSection, CodeCardSectionFacilitator } from 'session/sections/CodeCard'
import { DownloadResourceSection, DownloadResourceSectionFacilitator } from 'session/sections/DownloadResourceSection'
import { QuestionnaireSection, QuestionnaireSectionFacilitator } from 'session/sections/QuestionnaireSection'
import { TodoListSection, TodoListSectionFacilitator } from 'session/sections/TodoList'
import { CorkTitle, PanelTitle, CorkSection } from './SectionsPanelComponents'

interface Props {
  panel: SectionPanel
  fullHeight?: boolean
}

export const sectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key; panel: SectionPanel }>
} = {
  block_sections: BlocksSection,
  card_deck_sections: CardDeckSection,
  code_card_sections: CodeCardSection,
  divider_sections: DividerSection,
  download_resource_sections: DownloadResourceSection,
  drawing_sections: DrawingSection,
  embedded_activity_sections: EmbeddedActivitySection,
  emotionometer_sections: EmotionometerSection,
  grouped_sections: GroupedSection,
  input_sections: InputSection,
  list_sections: ListSection,
  media_grid_sections: MediaGridSection,
  notification_sections: NotificationSection,
  questionnaire_sections: QuestionnaireSection,
  text_sections: TextSection,
  todo_list_sections: TodoListSection,
  token_target_sections: TokenTargetSection,
  turn_taking_sections: TurnTakingSection,
}

export const facilitatorSectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key; panel: SectionPanel }>
} = {
  block_sections: BlocksSectionFacilitator,
  card_deck_sections: CardDeckSectionFacilitator,
  code_card_sections: CodeCardSectionFacilitator,
  divider_sections: DividerSectionFacilitator,
  drawing_sections: DrawingSectionFacilitator,
  embedded_activity_sections: EmbeddedActivitySectionFacilitator,
  emotionometer_sections: EmotionometerSectionFacilitator,
  grouped_sections: GroupedSectionFacilitator,
  input_sections: InputSectionFacilitator,
  list_sections: ListSectionFacilitator,
  media_grid_sections: MediaGridSectionFacilitator,
  notification_sections: NotificationSectionFacilitator,
  text_sections: TextSectionFacilitator,
  todo_list_sections: TodoListSectionFacilitator,
  token_target_sections: TokenTargetSectionFacilitator,
  turn_taking_sections: TurnTakingSectionFacilitator,
  download_resource_sections: DownloadResourceSectionFacilitator,
  questionnaire_sections: QuestionnaireSectionFacilitator,
}

const disableSectionInteractivity: { [key in SectionProperty]: boolean } = {
  block_sections: false,
  card_deck_sections: true,
  code_card_sections: true,
  divider_sections: false,
  download_resource_sections: false,
  drawing_sections: true,
  embedded_activity_sections: true,
  emotionometer_sections: true,
  grouped_sections: false,
  input_sections: true,
  list_sections: false,
  media_grid_sections: false,
  notification_sections: false,
  questionnaire_sections: true,
  text_sections: false,
  todo_list_sections: false,
  token_target_sections: false,
  turn_taking_sections: true,
}

interface ThemeStyleOption {
  header_background: string
  header_text_color: string
  body_padding: string
}

export const themeStyleOptions: { [key in Exclude<SectionPanel['theme'], null> | 'default']: ThemeStyleOption } = {
  default: {
    header_background: 'transparent', // N/A
    header_text_color: '#011A46',
    body_padding: '30px 20px',
  },
  none: {
    header_background: 'transparent', // N/A
    header_text_color: '#011A46',
    body_padding: '0',
  },
  cork: {
    header_background: 'transparent', // N/A
    header_text_color: '#ffffff',
    body_padding: '80px 70px',
  },
  relaxation: {
    header_background: '#2d90dc',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  parentGreen: {
    header_background: '#4EBE40',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  // Here down are code card styles - i.e. black background
  blue: {
    header_background: '#2d90dc',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  red: {
    header_background: '#ff2928',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  green: {
    header_background: '#4EBE40',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  maroon: {
    header_background: '#C6317C',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  yellow: {
    header_background: '#FFF051',
    header_text_color: '#011A46',
    body_padding: '30px 20px',
  },
  aqua: {
    header_background: '#009981',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
  purple: {
    header_background: '#7D41DF',
    header_text_color: '#ffffff',
    body_padding: '30px 20px',
  },
}

const codeCardThemeKeys: SectionPanel['theme'][] = ['blue', 'red', 'green', 'maroon', 'yellow', 'aqua', 'purple']

export const SectionsPanel: React.FC<Props> = ({ panel, fullHeight, children }) => {
  const facilitatorState = useFacilitatorState()
  const inputContext = useInputContext()
  const { state, pastMode } = useSessionState()

  const sections = getSections(panel)
  const participantUids = state.participants.map(({ profile }) => profile.uid)
  const showFacilitatorVersion =
    facilitatorState &&
    (!facilitatorState.focusedParticipantUid ||
      (facilitatorState.focusedParticipantUid && participantUids.indexOf(facilitatorState.focusedParticipantUid) < 0))

  const wrapSectionNodes = (nodes: ReactNode[]): ReactNode[] => {
    if (pastMode) {
      return nodes.map((node, i) => {
        if (disableSectionInteractivity[sections[i].property]) {
          return <div key={i} style={{ pointerEvents: 'none', cursor: 'unset' }} children={node} />
        } else {
          return node
        }
      })
    } else {
      return nodes.map((node, i) => {
        if (sections[i].property === 'grouped_sections') return node
        const key = `step${panel.step_id}_${sections[i].property}_${sections[i].index}`
        return <HighlightableArea key={key} id={key} children={node} />
      })
    }
  }

  return (
    <SectionsPanelStyled
      theme={panel.theme || undefined}
      panelTheme={panel.theme}
      flex={fullHeight ? '1 0 auto' : undefined}
      flair={
        panel.theme === 'none'
          ? []
          : codeCardThemeKeys.indexOf(panel.theme) >= 0
            ? [CUT_TL_DARK, CUT_TR_DARK, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, TAB_T_DARK]
            : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_L, TAB_T_L]
      }>
      {panel.theme && panel.title && (
        <>
          {panel.theme === 'cork' ? (
            <div>
              <CorkTitle>
                <H1>{panel.title}</H1>
              </CorkTitle>
            </div>
          ) : (
            <PanelTitle panelTheme={panel.theme}>
              <H1>{panel.title}</H1>
            </PanelTitle>
          )}
        </>
      )}
      <div className="panel-padding" style={{ padding: themeStyleOptions[panel.theme || 'default'].body_padding }}>
        {wrapSectionNodes(
          sections
            .map((sectionObj, i) => {
              const Component = showFacilitatorVersion
                ? facilitatorSectionComponents[sectionObj.property]
                : sectionComponents[sectionObj.property]
              // @ts-ignore
              return <Component key={i} {...sectionObj} panel={panel} />
            })
            .map((node, i) => {
              if (panel.theme === 'cork' && sections[i].property !== 'media_grid_sections')
                return <CorkSection key={i} children={node} />
              return node
            })
            .map((node, i) => (
              <InputContext.Provider
                key={i}
                children={node}
                value={{
                  ...inputContext,
                  owner: sections[i].property,
                  owner_id: sections[i].section.id,
                }}
              />
            ))
        )}
        {children}
      </div>
    </SectionsPanelStyled>
  )
}

export const SectionsPanelStyled = styled(Panel)<{ panelTheme: SectionPanel['theme'] }>`
  z-index: 0;
  font-size: 24px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

  ${(p) =>
    codeCardThemeKeys.indexOf(p.panelTheme) >= 0
      ? css`
          background-color: #2c2c2c;
          color: #fff;
          border-bottom-color: #121212;

          & hr {
            border: none;
            border-top: 1px solid #000000;
            border-bottom: 1px solid #3e3e3e;
          }

          ${H1},
          ${H2},
          ${H3} {
            color: ${p.panelTheme ? themeStyleOptions[p.panelTheme].header_background : '#fff'};
          }

          ${ListContainer}, ${RichTextContainer} {
            li {
              color: #fff;

              &:before {
                background: url(${require('../assets/list-bullet-white.svg')?.default}) no-repeat right top;
              }
            }
          }
          
          ${RichTextContainer} {
            color: #fff;

            h1,
            h2,
            h3, 
            h4 {
              color: ${p.panelTheme ? themeStyleOptions[p.panelTheme].header_background : '#fff'};
            }
          }

          ${Notification}[type='info'] .notification-inner{
            color: #fff;
          }

          ${FormField} ${Label} {
            color: #fff;
          }
          
          ${ShowText}, ${ToggleAllText}, ${P} {
              color: #fff;
          }

          ${AccordionTitle} {
            color: ${p.panelTheme ? themeStyleOptions[p.panelTheme].header_background : '#fff'};
          }

          ${CircleButton} {
            background: ${p.panelTheme ? themeStyleOptions[p.panelTheme].header_background : ''};
            border-color: ${p.panelTheme ? themeStyleOptions[p.panelTheme].header_background : ''};
          }

          ${BlockBody} {
            background-image:
              url('${require('common/assets/image/cut-br-dark.svg')?.default}'), 
              url('${require('common/assets/image/cut-tr-dark.svg')?.default}'), 
              url('${require('common/assets/image/cut-bl-dark.svg')?.default}'),
              url('${require('common/assets/image/cut-tl-dark.svg')?.default}');
            background-color: #2c2c2c;
            color: #fff;
            border-color: #0f0f0f;
            box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.2);

            ${RichTextContainer} {
              color: #fff;
            }
              `
      : ''}
  ${(p) =>
    p.panelTheme === 'relaxation'
      ? css`
          background: none;
          background-image: linear-gradient(180deg, #2eadf0, #fff 30%);
        `
      : ''}
  ${(p) =>
    p.panelTheme === 'cork'
      ? css`
          border-image-slice: 40% 40% 40% 40%;
          border-image-width: 90px 90px 90px 90px;
          border-image-outset: 0px 0px 0px 0px;
          border-image-repeat: stretch stretch;
          border-image-source: url(${require('../assets/cork-borders.png')});
          background-image: url(${require('../assets/cork-background.jpg')});
          background-repeat: repeat;
          background-size: 300px 300px;
          box-shadow: inset 0px 0px 30px 45px rgba(0, 0, 0, 0.35);
          border-radius: 15px;
          overflow: hidden;
        `
      : ''}
  ${(p) =>
    p.panelTheme === 'parentGreen'
      ? css`
          background-color: #fff;
          box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.44);
          background-image: none;

          & ${ListContainer}, & ${RichTextContainer} {
            ul > li::before {
              background: url(${require('../assets/list-bullet-parentGreen.svg')?.default}) no-repeat right top;
            }
          }
        `
      : ''}
  ${(p) =>
    p.panelTheme === 'none'
      ? css`
          padding: 0;
          border-radius: 0;
          border-bottom: none;
          box-shadow: none;
          background-color: transparent;
        `
      : ''}
`
