import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'
import { FacilitatorLookupEntity } from 'shared/dashboard/types'
import { GroupEntity, GroupEntityNew } from 'shared/dashboard/types'

import {
  H3,
  P,
  Button,
  Spacer,
  Row,
  Column,
  CUT_BL,
  CUT_BR,
  CUT_TL,
  CUT_TR,
  TAB_B_L,
  TAB_T_L,
  Padding,
  Input,
} from 'common/ui'
import { TextInputField } from 'common/FieldInputs'

import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells, Cell, CellInner } from 'dashboards/common/Cell'
import { Form, Props as FormProps } from 'dashboards/common/Form'
import { FormField, FormFieldDataProps } from 'dashboards/common/FormField'
import { Select } from 'common/Select'
import { InfoTooltip } from 'common/Tooltip'
import { SpinnerWithLabel } from 'common/Spinner'

import { useFacDashData } from './FacilitatorDashboardState'
import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useGenericUser } from 'app/useGenericUser'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { postJson } from 'utils/apiUtils'
import { ConfirmModal } from 'common/ConfirmModal'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { LinkButton } from 'common/LinkButton'
import ReactDOM from 'react-dom'
import { red } from 'themes'
import { facilitatorDashboardBaseUrl } from './constants'

export const GroupAdd: React.FC<NavRouteProps> = ({ route }) => <GroupEditInner route={route} />

export const GroupEditInner: React.FC<
  NavRouteProps & {
    editMode?: boolean
    formProps?: Partial<FormProps<GroupEntity, GroupEntityNew>>
  }
> = ({ route, editMode, formProps = {} }) => {
  const { groupUid } = useParams<{ groupUid: string }>()
  const history = useHistory()
  const user = useGenericUser()
  const { accessToken } = useUserState()
  const [providers, loadingProviders] = useFacDashData('providers', [])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showInputModal, setShowInputModal] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [deleting, setDeleting] = useState<boolean>(false)

  const isSeniorFacilitator = user.roles.indexOf('sas-sg senior facilitator') >= 0

  const [selectedProviderUid, setSelectedProviderUid] = useState<string | null>(null)

  const [assistants] = useEndpoint<FacilitatorLookupEntity[]>(
    isSeniorFacilitator && selectedProviderUid
      ? `/api/v1/facilitators/provider/${selectedProviderUid}/assistants`
      : null,
    []
  )

  const [facilitators] = useEndpoint<FacilitatorLookupEntity[]>(
    isSeniorFacilitator && selectedProviderUid
      ? `/api/v1/facilitators/provider/${selectedProviderUid}/facilitators`
      : null,
    []
  )

  const onSubmitSuccess = (savedGroup: GroupEntity) => {
    history.replace(`${facilitatorDashboardBaseUrl}/groups/${savedGroup.uid}/cadets${editMode ? '' : '/new'}`)
  }

  const orphanGroup = (confirmed: boolean) => {
    setShowModal(false)
    if (confirmed)
      postJson(`/api/v1/groups/orphan/${groupUid}/${selectedProviderUid}`, {}, getAuthRequestParams(accessToken), {
        retries: 0,
      })
        .then(() => {
          history.replace(`${facilitatorDashboardBaseUrl}/groups`)
        })
        .catch((e) => {
          alert('Unable to remove group')
          console.warn(e)
        })
  }

  const handleConfirmDelete = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowDeleteModal(false)
      setDeleting(true)
      fetch(`/api/v1/groups/${groupUid}`, { method: 'DELETE', ...getAuthRequestParams(accessToken) })
        .then(() => {
          history.push(`${facilitatorDashboardBaseUrl}/groups`)
        })
        .catch(() => {
          setDeleting(false)
        })
    })
  }

  return (
    <Page route={route}>
      {/* <Spacer /> */}
      <P style={{ fontSize: 17, fontWeight: 'bold' }}>Tips for creating a group:</P>
      <ul>
        <li>
          <P style={{ margin: 8 }}>SAS Facilitators can create group of up to 4 Cadets in any one software group</P>
        </li>
        <li>
          <P style={{ margin: 8 }}>Groups can be empty.</P>
        </li>
        <li>
          <P style={{ margin: 8 }}>
            If you and another SAS Facilitator are running two software groups to create a combined group of 4-6 Cadets
            with two facilitators, the second group is best created in the other SAS Facilitator's Dashboard.
          </P>
        </li>
        <li>
          <P style={{ margin: 8 }}>
            The Lead Facilitator for any group can be changed from with the one SAS Provider network through the
            “Unassign” button and then “Unassigned Groups” menu tab.
          </P>
        </li>
        <li>
          <P style={{ margin: 8 }}>
            If you have a co-facilitator or SAS Assistant joining you for any individual group meeting, they are added
            later when you schedule your group meetings and can be changed at any time before the meeting starts.
          </P>
        </li>
        <li>
          <P style={{ margin: 8 }}>
            <strong>Senior SAS Facilitators</strong> - When Senior SAS Facilitators are creating a group there is an
            option to add a co-facilitator or SAS Assistant at the overall group level to unlock a maximum of 6 Cadets
            in one software group
          </P>
        </li>
      </ul>
      <Spacer size="l" />

      <Form<GroupEntity, GroupEntityNew>
        initialDataUrl={`/api/v1/groups/new`}
        initialDataRequestOptions={getAuthRequestParams(accessToken)}
        submitUrl={`/api/v1/groups/save`}
        submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'POST' }}
        onSubmitSuccess={onSubmitSuccess}
        {...formProps}
        children={({ loading, errorLoading, submitting, formData, updateFormValue, setFormData, validationErrors }) => {
          const { provider_uid } = formData
          if (loading) return <SpinnerWithLabel color="#925BED" label="One moment please..." />
          if (errorLoading) return <P>An error occurred while loading data, try refreshing.</P>

          if (provider_uid && provider_uid !== selectedProviderUid) setSelectedProviderUid(provider_uid)

          // auto-select provider if there is only 1 option
          if (!loadingProviders && providers.length === 1 && !provider_uid) {
            updateFormValue('provider_uid', providers[0].id)
          }

          const formFieldDataProps: FormFieldDataProps<GroupEntityNew> = { formData, validationErrors, setFormData }
          return (
            <>
              <HeaderHr
                children={editMode ? 'Provider' : 'Select SAS Provider'}
                sideRoute={
                  editMode && formData.uid
                    ? `${facilitatorDashboardBaseUrl}/groups/${formData.uid}`
                    : `${facilitatorDashboardBaseUrl}/groups`
                }
                sideLabel={editMode && formData.uid ? '< Back to Group' : '< Back to My Groups'}
              />
              {loadingProviders ? (
                <SpinnerWithLabel label="Loading providers..." />
              ) : (
                <FormField<GroupEntityNew, string>
                  {...formFieldDataProps}
                  select="provider_uid"
                  defaultValue={''}
                  children={({ value, onChange }) =>
                    providers.length > 0 ? (
                      <Cells>
                        {providers
                          .filter(({ id }) => !value || !editMode || id === value)
                          .map((provider) => (
                            <Cell
                              key={provider.id}
                              style={{ minHeight: 50 }}
                              selected={value === provider.id}
                              onClick={() => {
                                onChange(provider.id)
                                setSelectedProviderUid(provider.id)
                              }}>
                              <CellInner>
                                <H3 children={provider.name} />
                              </CellInner>
                            </Cell>
                          ))}
                      </Cells>
                    ) : (
                      <P style={{ margin: 0 }}>You are not linked to any providers yet!</P>
                    )
                  }
                />
              )}
              <Spacer size="l" />

              <Row>
                <Column flex="auto">
                  <HeaderHr
                    children="Group Reference Code"
                    tooltip={`This code will only be visible to yourself, co-facilitators and assistants. This code can be an identifying name or number for internal use only.`}
                  />
                  <FormField<GroupEntityNew, string>
                    {...formFieldDataProps}
                    select="name"
                    defaultValue={''}
                    children={({ value, onChange }) => (
                      <TextInputField
                        placeholder={`Enter internal group reference code`}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Column>
                <Spacer size="m" />
                <Column flex="auto">
                  <HeaderHr
                    children="Group Name"
                    tooltip={`This name will be visible to anyone who can participate in sessions or view them, including your cadets, parents, and teachers.`}
                  />
                  <FormField<GroupEntityNew, string>
                    {...formFieldDataProps}
                    select="public_name"
                    defaultValue={''}
                    children={({ value, onChange }) => (
                      <TextInputField
                        placeholder={`Enter group name visible to others`}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Column>
              </Row>
              <Spacer size="l" />

              {isSeniorFacilitator && (
                <>
                  <HeaderHr
                    children="Co-Facilitator / SAS Assistant"
                    tooltip={`(Senior SAS Facilitators only) By adding a co-facilitator or SAS Assistant to deliver the program together, you can create a group of up to 6 cadets.`}
                  />
                  {!!formData.provider_uid ? (
                    <>
                      <P style={{ margin: '0 0 5px 0' }}>
                        Select a co-facilitator below:
                        <InfoTooltip
                          iconSize={16}
                          content={`The options below are based on the provider you selected for this group.`}
                        />
                      </P>
                      <FormField<GroupEntityNew, number | null>
                        {...formFieldDataProps}
                        select="cofacilitator_id"
                        defaultValue={null}
                        children={({ value, onChange }) => (
                          <Row alignItems="center">
                            <Column flex="1 1 auto">
                              <Select
                                options={(facilitators || []).map((user) => ({
                                  value: String(user.id),
                                  label: `${user.name} (${user.email})`,
                                }))}
                                value={value ? String(value) : ''}
                                onChange={(value) => onChange(value ? parseInt(value) : null)}
                                empty={
                                  !facilitators || !facilitators.length
                                    ? `There are no available facilitators to add.`
                                    : undefined
                                }
                                disabled={!facilitators || !facilitators.length}
                              />
                            </Column>
                            {!!value && (
                              <>
                                <Spacer size="s" />
                                <div>
                                  <Button theme="red" size="xs" children="Remove" onClick={() => onChange(null)} />
                                </div>
                              </>
                            )}
                          </Row>
                        )}
                      />
                      <Spacer />
                      <P style={{ margin: '0 0 5px 0' }}>
                        Select an assistant below:
                        <InfoTooltip
                          iconSize={16}
                          content={`The options below are based on the provider you selected for this group.`}
                        />
                      </P>
                      <FormField<GroupEntityNew, number | null>
                        {...formFieldDataProps}
                        select="assistant_id"
                        defaultValue={null}
                        children={({ value, onChange }) => (
                          <Row alignItems="center">
                            <Column flex="1 1 auto">
                              <Select
                                options={(assistants || []).map((user) => ({
                                  value: String(user.id),
                                  label: `${user.name} (${user.email})`,
                                }))}
                                value={value ? String(value) : ''}
                                onChange={(value) => onChange(value ? parseInt(value) : null)}
                                empty={
                                  !assistants || !assistants.length
                                    ? `There are no available assistants to add.`
                                    : undefined
                                }
                                disabled={!assistants || !assistants.length}
                              />
                            </Column>
                            {!!value && (
                              <>
                                <Spacer size="s" />
                                <div>
                                  <Button theme="red" size="xs" children="Remove" onClick={() => onChange(null)} />
                                </div>
                              </>
                            )}
                          </Row>
                        )}
                      />
                    </>
                  ) : (
                    <P>Select a provider first</P>
                  )}
                  <Spacer size="l" />
                </>
              )}

              {Object.values(validationErrors).length > 0 && (
                <P style={{ color: 'red' }}>There are validation errors, please check the fields above.</P>
              )}
              <Row>
                <Button type="submit" size="m" children={submitting ? 'Submitting...' : 'Save & Continue'} />
                {editMode && (
                  <>
                    <Spacer flex="1 1 auto" />
                    <Row>
                      <div>
                        <Row alignItems="center">
                          <InfoTooltip
                            placement="left"
                            iconSize={16}
                            content={`When you have finished your SAS Small Group Program delivery, “COMPLETE” your group. This will remove the group details and past meetings from your dashboard. Please download/print any group information needed for your professionals records prior to completion. You will not be able to restore this group to your dashboard once completed.`}
                          />
                          <Spacer size="s" />
                          <Button
                            size="xs"
                            onClick={(e) => {
                              e.preventDefault()
                              setShowDeleteModal(true)
                            }}
                            theme="blue"
                            disabled={deleting}
                            children={deleting ? 'Completing...' : 'Complete'}
                          />
                        </Row>
                      </div>
                      <Spacer />
                      <div>
                        <Button
                          size="xs"
                          theme="red"
                          children="Unassign"
                          onClick={(e) => {
                            e.preventDefault()
                            setShowModal(true)
                          }}
                        />
                      </div>
                    </Row>
                  </>
                )}
              </Row>
            </>
          )
        }}
      />
      <MobileModalPanel
        isOpen={showDeleteModal}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l">
          <P>Are you sure you are ready to COMPLETE this group?</P>
          <P>
            <strong>This will remove the group details and past meetings from your Facilitator Dashboard.</strong>
          </P>
          <P>
            Prior to completing ensure you have downloaded/printed any group details needed for your professional files,
            for example:
          </P>
          <ul style={{ paddingLeft: 15, margin: 0 }}>
            <li>
              <P>Cadet Overview - Progress Screen, Assessment, Mentor Details</P>
            </li>
            <li>
              <P>Group Meetings - Past Meetings List</P>
            </li>
          </ul>
          <Spacer />
          <Row justifyContent="center">
            <LinkButton
              style={{ width: '25%', boxSizing: 'border-box' }}
              children="Go to cadet overview"
              to={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/overview`}
              size="s"
            />
            <Spacer />
            <Button
              style={{ width: '25%', boxSizing: 'border-box' }}
              children="Complete"
              onClick={() => {
                ReactDOM.unstable_batchedUpdates(() => {
                  setShowInputModal(true)
                  setShowDeleteModal(false)
                })
              }}
              size="s"
              theme={red}
            />
            <Spacer />
            <Button
              style={{ width: '25%', boxSizing: 'border-box' }}
              children="Cancel"
              onClick={() => setShowDeleteModal(false)}
              size="s"
            />
          </Row>
          <Spacer />
        </Padding>
      </MobileModalPanel>

      <MobileModalPanel
        isOpen={showInputModal}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l" style={{ maxWidth: 600 }}>
          <P>Are you sure you are ready to COMPLETE this group?</P>
          <P>
            Type "permanently remove" and click "Complete" to permanently remove all group information from you
            dashboard.
          </P>
          <P>Click "Take me back" if you clicked this button in error.</P>
          <Spacer />
          <Input
            placeholder="permanently remove"
            inputSize="m"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
          />
          <Spacer size="l" />
          <Row justifyContent="center">
            <Button
              disabled={inputValue !== 'permanently remove'}
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Complete"
              onClick={handleConfirmDelete}
              size="s"
              theme={red}
            />
            <Spacer />
            <Button
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Take me back"
              onClick={() => {
                ReactDOM.unstable_batchedUpdates(() => {
                  setShowInputModal(false)
                  setShowDeleteModal(true)
                  setInputValue('')
                })
              }}
              size="s"
            />
          </Row>
          <Spacer />
        </Padding>
      </MobileModalPanel>

      <ConfirmModal
        isOpen={showModal}
        onSubmit={(confirmed) => {
          orphanGroup(confirmed)
        }}
        label="Are you sure you want to stop being this group's primary/managing SAS Facilitator? The group can be assigned to another team member from their own Facilitator Dashboard"
      />
    </Page>
  )
}
