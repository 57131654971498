import React from 'react'
import { IntroTemplate } from './IntroTemplate'
import { P, Hr, Row, Column, Spacer, H2 } from 'common/ui'
import { LinkButton, AnchorButton } from 'common/LinkButton'
import { getDomainFromEnv } from 'app/RequireDomain'
import { facilitatorDashboardBaseUrl } from 'dashboards/facilitator/constants'
import { mentorDashboardBaseUrl } from 'dashboards/mentor/constants'

export const HomeMentor: React.FC = () => {
  return (
    <IntroTemplate
      logoOverride={require('./assets/image/logo_tagline_all_white.png')}
      title="SAS Small Group"
      afterPanel={
        <Column style={{ textAlign: 'center' }}>
          <Spacer size="s" />
          <Row>
            <AnchorButton
              withChevron
              ghostMode
              href={`${process.env.REACT_APP_SST_BASE_URL}/SAS-SG`}
              target="_blank"
              flex="1 1 0px"
              size="s"
              style={{ textAlign: 'left', fontSize: 15 }}>
              Learn more about SAS small group
            </AnchorButton>
          </Row>
          <Spacer size="xl" />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}
            style={{ textTransform: 'uppercase', color: 'white', fontSize: 12 }}>
            Technical Support
          </a>
        </Column>
      }>
      <P align="center" style={{ marginTop: 0 }}>
        Welcome to the SAS Small Group Program Mentor and Facilitator access page.
      </P>
      <Hr margin="s" />
      <Row>
        <Column flex="1 1 auto" justifyContent="center">
          <H2 margin={0}>Mentors</H2>
          <P style={{ margin: 0, fontSize: 14 }}>Empower your Cadet</P>
        </Column>
        <Column>
          <LinkButton
            size="s"
            theme="parentGreen"
            to={mentorDashboardBaseUrl}
            children="Login"
            style={{ minWidth: 70 }}
          />
        </Column>
      </Row>
      <Hr margin="s" />
      <Row>
        <Column flex="1 1 auto" justifyContent="center">
          <H2 margin={0}>Cadets</H2>
          <P style={{ margin: 0, fontSize: 14 }}>Go to SAS Digital HQ</P>
        </Column>
        <Column>
          <AnchorButton size="s" theme="blue" href={getDomainFromEnv('cadet')} children="Go" style={{ minWidth: 70 }} />
        </Column>
      </Row>
      <Hr margin="s" />
      <Row>
        <Column flex="1 1 auto" justifyContent="center">
          <H2 margin={0}>Facilitators / Trainees</H2>
          <P style={{ margin: 0, fontSize: 14 }}>Lead SAS Small Groups &amp; complete your training</P>
        </Column>
        <Column>
          <LinkButton
            size="s"
            theme="purple"
            to={facilitatorDashboardBaseUrl}
            children="Login"
            style={{ minWidth: 70 }}
          />
        </Column>
      </Row>
      <Hr margin="s" style={{ marginBottom: -15 }} />
    </IntroTemplate>
  )
}
