import React, { useRef, useReducer, useState, useContext, useEffect } from 'react'
import { getUserUid } from 'app/useGenericUser'
import { useUserState } from 'app/UserState'
import { useSessionState } from 'session/SessionState'
import {
  ActionTypes,
  UPDATE_STATE,
  UPDATE_OVERVIEW,
  UPDATE_TEXT_VALUE,
  UPDATE_OPTION_VALUE,
} from 'shared/questionnaires/actionTypes'
import {
  Questionnaire,
  QuestionnaireSummary,
  PreviewState,
  QuestionnaireStateHookObject,
  QuestionnaireUserDataStatus,
  QuestionnaireResponse,
} from 'shared/questionnaires/types'
import {
  QuestionnaireStateUpdatedAction,
  QUESTIONNAIRE_STATE_UPDATED,
  QUESTIONNAIRE_STATE_REQUESTED,
  QuestionnaireStateRequestedAction,
} from 'shared/session/actionTypes'
import { getSubscaleOverall } from 'dashboards/utils/observationalCodeUtils'

function reducer(state: QuestionnaireSummary, action: ActionTypes): QuestionnaireSummary {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state

    case UPDATE_OVERVIEW:
      return { ...state, [action.field]: action.value }

    case UPDATE_TEXT_VALUE:
      const existing = !!state.responses.find((response) => response.unique_key === action.unique_key)
      return {
        ...state,
        responses: existing
          ? state.responses.map((response) => {
              if (response.unique_key === action.unique_key) return { ...response, value: action.value }
              return response
            })
          : [...state.responses, { unique_key: action.unique_key, value: action.value }],
      }

    case UPDATE_OPTION_VALUE: {
      const { data } = action
      const existingOption = !!state.responses.find(({ unique_key }) => unique_key === data.unique_key)
      const responses: QuestionnaireResponse[] = existingOption
        ? state.responses.map((response) =>
            response.unique_key === action.data.unique_key ? { ...response, ...data } : response
          )
        : [...state.responses, action.data]
      return {
        ...state,
        observational_code_score: action.updateObservationalCodeScore
          ? getSubscaleOverall(responses)
          : state.observational_code_score,
        responses,
      }
    }
  }
}

const initialState: QuestionnaireSummary = {
  mentor_id: -1,
  cadet_mentor_id: -1,
  name: '',
  questionnaire_type: 'dylan-anger',
  facilitator_created: false,
  score: -1,
  responses: [],
}

const getInitialState = (questionnaireData: Questionnaire | false) => {
  const newState: QuestionnaireSummary = { ...initialState, ...(questionnaireData || {}) }
  return newState
}

function useProviderQuestionnaireState() {
  const [questionnaireData, setQuestionnaireData] = useState<Questionnaire | false>(false)
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [previewState, setPreviewState] = useState<PreviewState>({ currentIndex: 0 })
  const [userDataStatus, setUserDataStatus] = useState<QuestionnaireUserDataStatus>(false)
  const [state, dispatch] = useReducer(reducer, getInitialState(questionnaireData))
  const [sessionSectionId, setSessionSectionId] = useState<number>(0)
  const { profileId, getBaseAction: getUserBaseAction } = useUserState()

  // NOTE: this will not return anything outside of session context
  const { socket, dispatch: sessionDispatch, getBaseAction: getSessionUserBaseAction } = useSessionState()
  const getBaseAction = getSessionUserBaseAction || getUserBaseAction

  const persistentState = useRef(state)

  const preDispatch = (action: ActionTypes) => {
    console.log('DISPATCHING ACTION', action)
    persistentState.current = reducer(persistentState.current, action)

    // preemptively emit questionnaire state change for speed (gets dispatched again on successfully save)
    if (socket && socket.connected && sessionSectionId > 0) {
      const userUid = getUserUid({ authProvider: 'sas', profileId })
      const jitState: QuestionnaireSummary = reducer(state, action)
      sessionDispatch({
        ...getBaseAction(),
        user_uid: userUid,
        type: QUESTIONNAIRE_STATE_UPDATED,
        state: jitState,
        userDataStatus: action.type === UPDATE_STATE ? action.userDataStatus : undefined,
      })
    }

    dispatch(action)
  }

  useEffect(() => {
    if (!socket) return
    const focusedUserUid = getUserUid({ authProvider: 'sas', profileId })
    const actualUserType = getUserBaseAction().role // even if facilitator is posing as cadet this will still return 'facilitator'

    const handleStateUpdateReceived = (action: QuestionnaireStateUpdatedAction) => {
      // action user_uid will always be the cadet's so check that first
      if (action.user_uid === focusedUserUid) {
        if (actualUserType === 'agent' && action.role === 'agent') {
          console.log(`❓ Received questionnaire state update from yourself, discarding`)
        } else {
          console.log(`❓ Received new questionnaire state from socket from a ${action.role}`)
          console.log(`❓ userDataStatus: ${action.userDataStatus}`)
          console.log(action)
          persistentState.current = action.state
          dispatch({ ...getBaseAction(), type: UPDATE_STATE, state: action.state })
          if (action.userDataStatus) {
            if (userDataStatus === 'submitted') {
              console.warn('❓ Choosing to ignore userDataStatus update  since questionnaire is already submitted')
            } else {
              setUserDataStatus(action.userDataStatus)
            }
          }
        }
      } else {
        console.warn(`❓ Received new questionnaire state from socket for ${action.user_uid} -- not you`)
      }
    }
    const handleStateRequested = (action: QuestionnaireStateRequestedAction) => {
      console.log(`❓ A facilitator requested your state! Quick go dispatch it (userDataStatus: ${userDataStatus})`)
      sessionDispatch({
        ...getBaseAction(),
        user_uid: focusedUserUid,
        type: QUESTIONNAIRE_STATE_UPDATED,
        state,
        userDataStatus,
      })
    }
    // Rest assured this only gets dispatched to sockets that are registered as facilitators
    // The fact it's able to match user_uid
    socket.on(QUESTIONNAIRE_STATE_UPDATED, handleStateUpdateReceived)
    socket.on(QUESTIONNAIRE_STATE_REQUESTED, handleStateRequested)
    return () => {
      if (socket) socket.off(QUESTIONNAIRE_STATE_UPDATED, handleStateUpdateReceived)
      if (socket) socket.off(QUESTIONNAIRE_STATE_REQUESTED, handleStateRequested)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, profileId, state, userDataStatus])

  return {
    state,
    userDataStatus,
    setUserDataStatus,
    questionnaireData,
    setQuestionnaireData,
    dispatch: preDispatch,
    previewing,
    setPreviewing,
    previewState,
    setPreviewState,
    sessionSectionId,
    setSessionSectionId,
  }
}

function noop(): any {}

export const QuestionnaireStateContext = React.createContext<QuestionnaireStateHookObject>({
  state: initialState,
  userDataStatus: false,
  setUserDataStatus: noop,
  questionnaireData: false,
  setQuestionnaireData: noop,
  dispatch: noop,
  previewing: false,
  setPreviewing: noop,
  previewState: { currentIndex: 0 },
  setPreviewState: noop,
  sessionSectionId: 0,
  setSessionSectionId: noop,
})

export const QuestionnaireStateProvider: React.FC = ({ children }) => {
  const state = useProviderQuestionnaireState()
  return <QuestionnaireStateContext.Provider value={state}>{children}</QuestionnaireStateContext.Provider>
}

export function useQuestionnaireState() {
  return useContext(QuestionnaireStateContext)
}
