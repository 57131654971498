import { NavRoute } from '../types'

import { Home } from './Home'

import { Training } from './Training'
import { TrainingFacilitator } from './TrainingFacilitator'

import { OrphanGroups } from './OrphanGroups'
import { ProviderRequest } from './ProviderRequest'
import { Providers } from './Providers'

import { CadetAdd } from './CadetAdd'
import { CadetEdit } from './CadetEdit'
import { Cadets } from './Cadets'
import { GroupAdd } from './GroupAdd'
import { GroupCadetOverview } from './GroupCadetOverview'
import { GroupEdit } from './GroupEdit'
import { GroupOverview } from './GroupOverview'
import { Groups } from './Groups'

import { MeetingAdd } from './MeetingAdd'
import { MeetingEdit } from './MeetingEdit'
import { Meetings } from './Meetings'

import { FacManModuleView } from 'fac-man/FacManModule'
import { Manual } from './Manual'

import { AssessmentMeasures } from './AssessmentMaterials'
import { OrphanedQuestionnaires } from './OrphanedQuestionnaires'
import { PrintOrphanedQuestionnaire, PrintQuestionnaire } from './PrintQuestionnaire'

import { CommunityTipsheetFacilitator, TeacherTipsheetFacilitator } from 'tipsheets/TipsheetFacilitator'
import { Resources } from './Resources'
import { Handouts } from './resources/Handouts'
import { IntakeSupport } from './resources/IntakeSupport'
import { PracticeSession } from './resources/PracticeSession'
import { PresentationSlides } from './resources/PresentationSlides'
import { SessionChecklists } from './resources/SessionChecklists'
import { TeacherTipsheets } from './resources/TipsheetsTeacher'
import { CommunityTipsheets } from './resources/TipsheetsCommunity'

import { Help } from './Help'
import { FAQs } from './help/FAQs'
import { VideoTutorials } from './help/VideoTutorials'

import { ProviderEntity } from 'shared/dashboard/types'
import { Contact } from './Contact'
import { DigiHQ } from './DigiHQ'
import { FEATURE_communityTipSheets_providerUuids } from 'dashboards/constant/FEATURE_consts'

type InaccessibleFunction = Exclude<NavRoute['inaccessible'], undefined | boolean>

// seniors have same permissions as facilitators
const lacksFacilitatorRole: InaccessibleFunction = (user) => {
  return (
    user.roles.indexOf('sas-sg facilitator beta') < 0 &&
    user.roles.indexOf('sas-sg facilitator') < 0 &&
    user.roles.indexOf('sas-sg senior facilitator') < 0
  )
}

const lacksProvider = (providers: ProviderEntity[]) => {
  return providers.length < 1
}

const lacksFacmanAccess: InaccessibleFunction = (user, additionalData) => {
  return lacksFacilitatorRole(user, additionalData) && user.roles.indexOf('sas-sg facilitator trainee') < 0
}

const lacksProviderAccess: InaccessibleFunction = (user, additionalData) => {
  return lacksFacilitatorRole(user, additionalData) && user.roles.indexOf('sas-sg assistant') < 0
}

const lacksGroupMeetingAccess: InaccessibleFunction = (user, additionalData) => {
  if (lacksFacilitatorRole(user, additionalData) && user.roles.indexOf('sas-sg assistant') < 0) return true
  return lacksProvider(additionalData.providers || [])
}

export const lacksDigiAccess: InaccessibleFunction = (user, additionalData) => {
  if (lacksFacilitatorRole(user, additionalData)) return true
  return lacksProvider(additionalData.providers || [])
}

const lacksGroupsAccess: InaccessibleFunction = (user, additionalData) => {
  if (lacksFacilitatorRole(user, additionalData)) return true
  return lacksProvider(additionalData.providers || [])
}

const lacksResourcesAccess: InaccessibleFunction = (user, additionalData) => {
  if (lacksFacilitatorRole(user, additionalData)) return true
  return lacksProvider(additionalData.providers || [])
}

const lacksOrphanedQuestionnaireAccess: InaccessibleFunction = (user, additionalData) => {
  return lacksFacilitatorRole(user, additionalData) || !additionalData.orphanedQuestionnairesCount
}

const lacksOrphanedGroupAccess: InaccessibleFunction = (user, additionalData) => {
  return lacksFacilitatorRole(user, additionalData) || !additionalData.orphanedGroupsCount
}

const FEATURE_communityTipSheets_lacksAllowedProviderUuid: InaccessibleFunction = (user, additionalData) => {
  const providers = additionalData.providers || []
  const providerUuids = providers.map((provider) => provider.id)
  return (
    lacksProvider(providers) || !providerUuids.some((uuid) => FEATURE_communityTipSheets_providerUuids.includes(uuid))
  )
}

export const routes: NavRoute[] = [
  {
    title: 'Home',
    path: '',
    exact: true,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 22,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16251 8.44812L11.5 2.11063L17.8375 8.44812H17.8361V19.0083H5.16393V8.44812H5.16251ZM3.0519 10.5587L1.49236 12.1183L0 10.6259L10.0078 0.618075C10.8319 -0.206025 12.1681 -0.206025 12.9922 0.618075L23 10.6259L21.5076 12.1183L19.9481 10.5588V19.0083C19.9481 20.1747 19.0025 21.1203 17.8361 21.1203H5.16393C3.99749 21.1203 3.0519 20.1747 3.0519 19.0083V10.5587Z"
        />
      ),
    },
    Page: Home,
  },
  {
    title: 'My Training',
    path: '/training',
    exact: true,
    // hide: lacksAdministratorRole, // TODO: customize based on if user is a trainee
    icon: {
      viewboxWidth: 20,
      viewboxHeight: 21,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4374 0C15.0921 0 15.7197 0.26142 16.1781 0.723423L19.279 3.82432C19.7407 4.286 20.0001 4.91217 20.0001 5.56508C20.0001 6.21799 19.7407 6.84416 19.279 7.30584L7.95751 18.6238C7.25902 19.4295 6.2689 19.9245 5.1346 20.0023H0V19.0023L0.00324765 14.7873C0.0884382 13.7328 0.578667 12.7523 1.3265 12.0934L12.6954 0.724628C13.1564 0.26083 13.7834 0 14.4374 0ZM5.06398 18.0048C5.59821 17.967 6.09549 17.7184 6.49479 17.2616L14.0567 9.69972L10.3023 5.94519L2.6961 13.5496C2.29095 13.9079 2.04031 14.4092 2 14.8678V18.0029L5.06398 18.0048ZM11.7167 4.53115L15.4709 8.2855L17.8648 5.89162C17.9514 5.80502 18.0001 5.68756 18.0001 5.56508C18.0001 5.4426 17.9514 5.32514 17.8648 5.23854L14.7611 2.13486C14.6755 2.04855 14.5589 2 14.4374 2C14.3158 2 14.1992 2.04855 14.1136 2.13486L11.7167 4.53115Z"
        />
      ),
    },
    Page: Training,
    subpages: [
      {
        title: '',
        path: '/:type',
        exact: true,
        hideFromNav: true,
        Page: TrainingFacilitator,
      },
    ],
  },
  {
    title: 'My SAS Provider',
    path: '/providers',
    exact: true,
    inaccessible: lacksProviderAccess,
    icon: {
      viewboxWidth: 18,
      viewboxHeight: 22,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99592 1C6.7482 0.362975 7.83914 0 9 0C10.1609 0 11.2518 0.362975 12.0041 1H13C14.1046 1 15 1.89543 15 3H16C17.1046 3 18 3.89543 18 5V20C18 21.1046 17.1046 22 16 22H2C0.89543 22 0 21.1046 0 20V5C0 3.89543 0.89543 3 2 3H3C3 1.89543 3.89543 1 5 1H5.99592ZM13 6C13.7403 6 14.3866 5.5978 14.7324 5H16V20H2V5H3.26756C3.61337 5.5978 4.25972 6 5 6H13ZM5 15V13H11V15H5ZM5 9V11H13V9H5ZM6.85762 3L7.15665 2.65538C7.48519 2.27674 8.18941 2 9 2C9.81059 2 10.5148 2.27674 10.8434 2.65538L11.1424 3H13V4H5V3H6.40135H6.85762Z"
        />
      ),
    },
    Page: Providers,
    subpages: [
      {
        title: 'Request to be linked to an SAS Provider',
        path: '/request',
        exact: true,
        hideFromNav: true,
        Page: ProviderRequest,
      },
    ],
  },
  {
    title: 'SAS Digital HQ',
    path: '/digi-hq',
    exact: true,
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 25,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
        />
      ),
    },
    Page: DigiHQ,
  },
  {
    title: 'My Groups',
    path: '/groups',
    exact: true,
    inaccessible: lacksGroupsAccess,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5C23 17.8513 17.8513 23 11.5 23ZM19.2358 16.8576C20.2908 15.3371 20.9091 13.4908 20.9091 11.5C20.9091 6.3035 16.6965 2.09091 11.5 2.09091C6.3035 2.09091 2.09091 6.3035 2.09091 11.5C2.09091 13.4908 2.70917 15.3372 3.76417 16.8576C4.94077 15.3034 7.70564 14.6364 11.5 14.6364C15.2944 14.6364 18.0592 15.3034 19.2358 16.8576ZM17.7358 18.5461C17.4298 17.4459 15.2316 16.7273 11.5 16.7273C7.76838 16.7273 5.57019 17.4459 5.26421 18.5461C6.92437 20.0165 9.10794 20.9091 11.5 20.9091C13.8921 20.9091 16.0756 20.0165 17.7358 18.5461ZM11.5 5.22727C8.96906 5.22727 7.31818 7.06284 7.31818 9.40909C7.31818 12.9923 9.15909 14.6364 11.5 14.6364C13.8189 14.6364 15.6818 13.0469 15.6818 9.61818C15.6818 7.23619 14.0239 5.22727 11.5 5.22727ZM9.40909 9.40909C9.40909 11.7815 10.2645 12.5455 11.5 12.5455C12.7312 12.5455 13.5909 11.812 13.5909 9.61818C13.5909 8.3118 12.7709 7.31818 11.5 7.31818C10.1762 7.31818 9.40909 8.17109 9.40909 9.40909Z"
        />
      ),
    },
    Page: Groups,
    subpages: [
      {
        title: 'Create a group',
        path: '/new',
        exact: true,
        hideFromNav: true,
        Page: GroupAdd,
      },
      {
        title: 'Group Overview',
        path: '/:groupUid',
        exact: true,
        hideFromNav: true,
        Page: GroupOverview,
      },
      {
        title: 'Edit Group',
        path: '/:groupUid/edit',
        exact: true,
        hideFromNav: true,
        Page: GroupEdit,
      },
      {
        title: 'Add a cadet to group',
        path: '/:groupUid/cadets',
        exact: true,
        hideFromNav: true,
        Page: Cadets,
      },
      {
        title: 'Add a cadet to group',
        path: '/:groupUid/cadets/new',
        exact: true,
        hideFromNav: true,
        Page: CadetAdd,
      },
      {
        title: 'Add a cadet to group',
        path: '/:groupUid/cadets/:mentorUid',
        exact: true,
        hideFromNav: true,
        Page: CadetEdit,
      },
      {
        title: 'Cadet Progress',
        path: '/:groupUid/overview',
        exact: false,
        hideFromNav: true,
        Page: GroupCadetOverview,
      },
    ],
  },
  {
    title: 'Unassigned Groups',
    path: '/orphans',
    exact: true,
    inaccessible: lacksOrphanedGroupAccess,
    icon: {
      viewboxWidth: 22,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.89998 4.4H7.09998V3.3H15.9V4.4H18.1V3.3H20.3V6.59999H2.69999V3.3H4.89998V4.4ZM2.69999 8.79999V19.8H20.3V8.79999H2.69999ZM7.09998 1.09999H15.9V0H18.1V1.09999H20.3C21.515 1.09999 22.5 2.08497 22.5 3.29999V19.8C22.5 21.015 21.515 22 20.3 22H2.7C1.48497 22 0.5 21.015 0.5 19.8V3.29999C0.5 2.08497 1.48497 1.09999 2.7 1.09999H4.89998V0H7.09998V1.09999Z"
        />
      ),
    },
    Page: OrphanGroups,
  },
  {
    title: 'Group Meetings',
    path: '/meetings',
    exact: true,
    inaccessible: lacksGroupMeetingAccess,
    icon: {
      viewboxWidth: 22,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.89998 4.4H7.09998V3.3H15.9V4.4H18.1V3.3H20.3V6.59999H2.69999V3.3H4.89998V4.4ZM2.69999 8.79999V19.8H20.3V8.79999H2.69999ZM7.09998 1.09999H15.9V0H18.1V1.09999H20.3C21.515 1.09999 22.5 2.08497 22.5 3.29999V19.8C22.5 21.015 21.515 22 20.3 22H2.7C1.48497 22 0.5 21.015 0.5 19.8V3.29999C0.5 2.08497 1.48497 1.09999 2.7 1.09999H4.89998V0H7.09998V1.09999Z"
        />
      ),
    },
    Page: Meetings,
    subpages: [
      {
        title: 'Schedule a Meeting',
        path: '/new',
        exact: true,
        hideFromNav: true,
        Page: MeetingAdd,
      },
      {
        title: 'Schedule a Meeting',
        path: '/new/:groupUid',
        exact: true,
        hideFromNav: true,
        Page: MeetingAdd,
      },
      {
        title: 'Edit Meeting',
        path: '/:sessionUid/edit',
        exact: true,
        hideFromNav: true,
        Page: MeetingEdit,
      },
    ],
  },
  {
    title: 'Facilitator Manual',
    path: '/manual',
    exact: true,
    inaccessible: lacksFacmanAccess,
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 25,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
        />
      ),
    },
    Page: Manual,
    subpages: [
      {
        title: 'Facilitator Manual',
        path: '/:moduleType/:moduleCode',
        exact: true,
        hideFromNav: true,
        Page: FacManModuleView,
      },
    ],
  },
  {
    sidebarTitle: 'Assessment',
    title: 'Assessment Materials',
    path: '/assessment',
    exact: true,
    inaccessible: lacksResourcesAccess,
    Page: AssessmentMeasures,
    icon: {
      viewboxWidth: 17,
      viewboxHeight: 14,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.52317 0H15.2317C16.073 0 16.7549 0.681948 16.7549 1.52317V12.1854C16.7549 13.0266 16.073 13.7086 15.2317 13.7086H1.52317C0.681948 13.7086 0 13.0266 0 12.1854V1.52317C0 0.681948 0.681948 0 1.52317 0ZM1.52338 1.52337V12.1856H15.2319V1.52337H1.52338ZM4.56982 10.6618H6.093V7.625H4.56982V10.6618ZM9.13938 10.6623H7.61621V5.4375H9.13938V10.6623ZM10.6624 10.6622H12.1855V3.25H10.6624V10.6622Z"
        />
      ),
    },
    subpages: [
      {
        title: 'Unlinked Submissions',
        path: '/unlinked-submissions',
        exact: true,
        Page: OrphanedQuestionnaires,
        inaccessible: lacksOrphanedQuestionnaireAccess,
      },
    ],
  },
  {
    title: 'Print Questionnaire',
    path: '/print-questionnaire/:cadetMentorId(\\d+)/:mentorId(cadet|\\d+)/:questionnaireBooklet(1|2|3|4)/:questionnaireKey',
    exact: true,
    hideFromNav: true,
    Page: PrintQuestionnaire,
  },
  {
    title: 'Print Questionnaire',
    path: '/print-questionnaire/unlinked/:orphanId/:mentorId(cadet|\\d+)/:questionnaireBooklet(1|2|3|4)/:questionnaireKey',
    exact: true,
    hideFromNav: true,
    Page: PrintOrphanedQuestionnaire,
  },
  {
    title: 'Resources',
    path: '/resources',
    exact: true,
    // hide: lacksAdministratorRole,
    icon: {
      viewboxWidth: 19,
      viewboxHeight: 25,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 0H13.1039L19 5.89611V21.1111C19 22.277 18.0548 23.2222 16.8889 23.2222H2.11111C0.945177 23.2222 0 22.277 0 21.1111V2.11111C0 0.945176 0.945177 0 2.11111 0ZM10.5556 2.11111H2.11111V21.1111H16.8889V8.44444H12.6667C11.5007 8.44444 10.5556 7.49927 10.5556 6.33333V2.11111ZM12.6667 2.54834V6.33333H16.4517L12.6667 2.54834Z"
        />
      ),
    },
    Page: Resources,
    inaccessible: lacksResourcesAccess,
    subpages: [
      {
        title: 'Practice Sessions',
        path: '/practice-sessions',
        exact: true,
        // hide: lacksAdministratorRole,
        Page: PracticeSession,
      },
      {
        title: 'Intake Support',
        path: '/intake-support',
        exact: true,
        Page: IntakeSupport,
      },
      {
        title: 'Session Checklists',
        path: '/session-checklists',
        exact: true,
        // hide: lacksAdministratorRole,
        Page: SessionChecklists,
      },
      {
        title: 'Presentation Slides',
        path: '/presentation-slides',
        exact: true,
        Page: PresentationSlides,
      },
      {
        title: 'Teacher Tip Sheets',
        path: '/teacher-tip-sheets',
        exact: true,
        // hide: lacksAdministratorRole,
        Page: TeacherTipsheets,
        subpages: [
          {
            title: 'Teacher Tip Sheet',
            path: '/:moduleCode',
            exact: true,
            hideFromNav: true,
            Page: TeacherTipsheetFacilitator,
          },
        ],
      },
      {
        sidebarTitle: 'Community Activity Tip Sheets',
        title: 'Community Activity Tip Sheets',
        path: '/community-tip-sheets',
        exact: true,
        inaccessible: FEATURE_communityTipSheets_lacksAllowedProviderUuid,
        Page: CommunityTipsheets,
        subpages: [
          {
            title: 'Community Activity Tip Sheet',
            path: '/:moduleCode/:language?',
            exact: true,
            hideFromNav: true,
            Page: CommunityTipsheetFacilitator,
          },
        ],
      },
      {
        title: 'Templates / Handouts',
        path: '/handouts',
        exact: true,
        // hide: lacksAdministratorRole,
        Page: Handouts,
      },
    ],
  },
  /*
  {
    title: 'Shop',
    path: '/shop',
    exact: true,
    icon: {
      viewboxWidth: 24,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68193 2.30292C4.47769 2.30248 4.28081 2.3256 4.09372 2.36955C3.83725 1.80427 3.49848 1.29713 3.11317 0.911827C2.4592 0.257854 1.42778 0 0 0V2.3C0.872215 2.3 1.37413 2.42548 1.48683 2.53817C1.92149 2.97284 2.3 3.85602 2.3 4.6L2.31156 4.76263L3.43841 12.6506C1.55291 12.7484 0.0922273 14.2126 0.00141287 16.043L0 17.25C0.111344 19.1405 1.56717 20.5948 3.38472 20.6981L3.6458 20.6985C4.119 22.0393 5.3973 23 6.9 23C8.40215 23 9.68008 22.04 10.1537 20.7H12.8463C13.3199 22.04 14.5978 23 16.1 23C18.0054 23 19.55 21.4554 19.55 19.55C19.55 17.6446 18.0054 16.1 16.1 16.1C14.5978 16.1 13.3199 17.06 12.8463 18.4H10.1537C9.68008 17.06 8.40215 16.1 6.9 16.1C5.39785 16.1 4.11992 17.06 3.64631 18.4H3.45C2.86351 18.3648 2.33742 17.8393 2.29794 17.1811L2.3 16.1C2.33101 15.5035 2.85351 14.981 3.50699 14.9486L5.77725 14.9492L5.7937 14.95H17.3844L17.5541 14.8961C18.4261 14.6195 19.1241 13.9629 19.4546 13.1129L19.5757 12.8731L19.9694 12.0933C20.3766 11.286 20.7838 10.4774 21.1807 9.68737C22.1527 7.75223 22.764 6.52241 22.8994 6.22041C23.5946 4.66952 22.1863 3.47583 20.7468 3.45041L4.68193 2.30292ZM16.9848 12.65H5.8798C5.80596 12.6295 5.74969 12.5669 5.73867 12.489L4.61179 4.60084L20.5774 5.74416C20.298 6.31387 19.7902 7.33143 19.1254 8.655L19.1087 8.68817C18.7292 9.44364 18.3225 10.2512 17.9159 11.0573L17.5227 11.8362L17.3737 12.1312L17.3176 12.2616C17.2575 12.4289 17.1382 12.5665 16.9848 12.65ZM16.1 20.7C16.7351 20.7 17.25 20.1851 17.25 19.55C17.25 18.9149 16.7351 18.4 16.1 18.4C15.4649 18.4 14.95 18.9149 14.95 19.55C14.95 20.1851 15.4649 20.7 16.1 20.7ZM8.05 19.55C8.05 20.1851 7.53513 20.7 6.9 20.7C6.26487 20.7 5.75 20.1851 5.75 19.55C5.75 18.9149 6.26487 18.4 6.9 18.4C7.53513 18.4 8.05 18.9149 8.05 19.55Z"
        />
      ),
    },
    Page: DummyRoute,
  },
  */
  {
    title: 'Help',
    path: '/help',
    exact: true,
    inaccessible: false, // lacksAdministratorRole,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 23,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5ZM20.9091 11.5C20.9091 16.6965 16.6965 20.9091 11.5 20.9091C6.3035 20.9091 2.09091 16.6965 2.09091 11.5C2.09091 6.3035 6.3035 2.09091 11.5 2.09091C16.6965 2.09091 20.9091 6.3035 20.9091 11.5ZM11.5003 16.7255C12.0779 16.7255 12.5461 16.2575 12.5461 15.6801C12.5461 15.1027 12.0779 14.6346 11.5003 14.6346C10.9228 14.6346 10.4545 15.1027 10.4545 15.6801C10.4545 16.2575 10.9228 16.7255 11.5003 16.7255ZM10.4545 13.5909H12.5455C12.5455 12.7563 12.6766 12.6033 13.5357 12.1737C15.0288 11.4272 15.6818 10.6654 15.6818 8.88636C15.6818 6.60793 13.8882 5.22727 11.5 5.22727C9.19045 5.22727 7.31818 7.09954 7.31818 9.40909H9.40909C9.40909 8.25431 10.3452 7.31818 11.5 7.31818C12.8352 7.31818 13.5909 7.89988 13.5909 8.88636C13.5909 9.721 13.4598 9.87398 12.6006 10.3036C11.1075 11.0501 10.4545 11.8119 10.4545 13.5909Z"
        />
      ),
    },
    Page: Help,
    subpages: [
      {
        title: 'Video Tutorials',
        path: '/videos',
        exact: true,
        Page: VideoTutorials,
        inaccessible: false,
      },
      {
        title: 'FAQs',
        path: '/faqs',
        exact: true,
        Page: FAQs,
        inaccessible: false,
      },
    ],
  },
  {
    title: 'Contact',
    path: '/contact',
    exact: true,
    icon: {
      viewboxWidth: 23,
      viewboxHeight: 17,
      size: 20,
      children: (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.09091 2.09091V11.5H0V14.6364C0 15.7911 0.936132 16.7273 2.09091 16.7273H15.6818H20.9091C22.0639 16.7273 23 15.7911 23 14.6364V5.22727C23 4.0725 22.0639 3.13636 20.9091 3.13636V2.09091C20.9091 0.936132 19.973 0 18.8182 0H4.18182C3.02704 0 2.09091 0.936132 2.09091 2.09091ZM18.8182 3.13636V2.09091H4.18182V11.5H13.5909V5.22727C13.5909 4.0725 14.527 3.13636 15.6818 3.13636L18.8182 3.13636ZM2.09091 13.5909H13.5909V14.6364H2.09091V13.5909ZM15.6818 14.6364V5.22728H20.9091V14.6364H15.6818Z"
        />
      ),
    },
    Page: Contact,
  },
]
