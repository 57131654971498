import React, { useEffect } from 'react'

import { NavRouteProps } from 'dashboards/types'

import { Cells } from 'dashboards/common/Cell'
import { Page } from 'dashboards/common/Page'
import { CommunityTipsheetCell } from 'dashboards/common/TipsheetCommunityCell'

import { getCommunityTipsheets } from 'api'
import { useUserState } from 'app/UserState'
import { useApiEndpoint } from 'dashboards/utils/endpointHooks'
import { SpinnerWithLabel } from 'common/Spinner'
import { facilitatorDashboardBaseUrl } from '../constants'
import { useFacDashState } from '../FacilitatorDashboardState'
import { P } from 'common/ui'

export const CommunityTipsheets: React.FC<NavRouteProps> = ({ route }) => {
  const { accessToken } = useUserState()
  const {
    groups: { value: groups, fetch: fetchGroups, loaded: loadedGroups, loading: loadingGroups },
  } = useFacDashState()

  const [tipsheets, { loading: loadingTipsheets }] = useApiEndpoint(
    getCommunityTipsheets,
    !loadingGroups && !!groups,
    accessToken,
    groups ? groups.map((g) => g.provider_uid) : []
  )

  useEffect(() => {
    if (!loadedGroups) fetchGroups()
  }, [loadedGroups, fetchGroups])

  if (loadingTipsheets || loadingGroups)
    return <Page route={route} children={<SpinnerWithLabel label="Loading tipsheets..." />} />

  return (
    <Page route={route}>
      <Cells>
        {(tipsheets || []).length === 0 && <P>No community tip sheets available at the moment</P>}
        {(tipsheets || []).map((tipsheet) => (
          <CommunityTipsheetCell
            key={tipsheet.uid}
            baseUrl={`${facilitatorDashboardBaseUrl}/resources`}
            tipsheet={tipsheet}
          />
        ))}
      </Cells>
    </Page>
  )
}
