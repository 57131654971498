import { LinkButton } from 'common/LinkButton'
import { Column, H3, Row, Spacer } from 'common/ui'
import React from 'react'
import { TipsheetEntity } from 'shared/tipsheets/types'
import { Cell, CellInner } from './Cell'
// import { TipsheetPrintButton } from 'tipsheets/TipsheetPrint'

interface Props {
  baseUrl: string
  tipsheet: Omit<TipsheetEntity, 'tipsheet_steps'>
}

export const CommunityTipsheetCell: React.FC<Props> = ({ baseUrl, tipsheet }) => {
  const themeName = tipsheet.theme || 'orange'
  return (
    <Cell accent={themeName}>
      <CellInner>
        <Column flex="1 1 auto">
          <H3>Community Activity Tip Sheet - {tipsheet.public_title || tipsheet.title}</H3>
        </Column>
        <Row flex="none">
          {/* <TipsheetPrintButton
            size="s"
            theme={themeName}
            context={context}
            mentorUid={mentorUid}
            groupId={groupId}
            moduleCode={moduleCode}
          /> */}
          <Spacer size="xs" />
          <LinkButton
            size="s"
            children="View"
            theme={themeName}
            to={`${baseUrl}/community-tip-sheets/${tipsheet.module_code}/${tipsheet.language || 'english'}`}
          />
        </Row>
      </CellInner>
    </Cell>
  )
}
