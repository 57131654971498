import {
  ContentSection,
  PanelSection,
  Questionnaire,
  QuestionnaireQuestionOption,
  QuestionnaireType,
  QuestionnaireUser,
} from 'shared/questionnaires/types'

const generatePanel = (title: string, sections: ContentSection[]): PanelSection => {
  const generatedSections = sections.reduce(
    (obj, section, order) => ({
      ...obj,
      [section._owner]: [
        ...obj[section._owner as 'text_sections' | 'input_sections' | 'question_sections' | 'notification_sections'],
        { ...section, order },
      ],
    }),
    {
      text_sections: [],
      input_sections: [],
      question_sections: [],
      notification_sections: [],
    } as Pick<PanelSection, 'text_sections' | 'input_sections' | 'question_sections' | 'notification_sections'>
  )
  return {
    id: -1,
    _owner: '',
    _owner_id: -1,
    order: 0,
    title,
    ...generatedSections,
  }
}

const generateTextSection = (text: string): PanelSection['text_sections'][number] => ({
  id: -1,
  _owner: 'text_sections',
  _owner_id: -1,
  order: 0,
  text,
})

const generateInputSection = (
  partial: Pick<PanelSection['input_sections'][number], 'label' | 'type' | 'lines' | 'required' | 'maxLength'>,
  negativeId: number = -1
): PanelSection['input_sections'][number] => ({
  id: negativeId,
  _owner: 'input_sections',
  _owner_id: -1,
  order: 0,
  usage: 'individual', // N/A
  size: null,
  multiple_symbols: false,
  ...partial,
})

// const key = `panel_${section._owner_id}_${section.type}_${section.id}`
// const uniqueKey = `${key}_option_${option.id}`
const generateQuestionSection = (
  partial: Pick<
    PanelSection['question_sections'][number],
    'type' | 'score_range' | 'required' | 'title' | 'text' | 'terminology' | 'start_from' | 'question_options'
  >
): PanelSection['question_sections'][number] => ({
  id: 0,
  uid: '',
  _owner: 'question_sections',
  _owner_id: 0,
  order: 0,
  ...partial,
  question_options: partial.question_options.map((option, order) => ({
    ...option,
    order,
    id: (partial.start_from || 0) + order,
  })),
})

const generateQuestionOption = (
  optionLabels: [string, string, string],
  title: string,
  reverse_scored: boolean = false
): QuestionnaireQuestionOption => ({
  id: -1,
  order: 0,
  reverse_scored,
  title,
  label: '',
  placeholder: '',
  text: '',
  optionLabels,
})

export const observationalCodeHeadings = [
  'Non-verbal Communication',
  'Conversation skills',
  'Cooperation skills',
  'Conflict resolution',
  'Emotion regulation',
] as const

const developmentalOptionsMinMax: [string, string, string] = [
  'Developmentally appropriate level',
  'Slightly too little / too much',
  'Significantly too little / too much',
]
const developmentalOptionsMax: [string, string, string] = [
  'Developmentally appropriate level',
  'Slightly too much',
  'Significantly too much',
]
const truthyOptions: [string, string, string] = ['Not true', 'Somewhat true', 'Mostly true']

export const observationalCodeQuestionnaire: Questionnaire = {
  id: -1,
  uid: '',
  title: 'The Secret Agent Society Observer Coding Form',
  type: QuestionnaireType.observational_code,
  user_type: QuestionnaireUser.facilitator,
  panels: [
    generatePanel('The Secret Agent Society Observer Coding Form', [
      generateTextSection(
        `This observational tool cannot be changed after partial completion. It must be completed in one attempt. If this is not possible, please use the print version of this tool.`
      ),
      generateTextSection(`Instructions: \nPlease fill out this form after observing a child for 15-20 minutes. Rate how often the child displayed each target behaviour relative to an average child of the same age.
          If you do not have the opportunity to see a target behaviour, or were unable to judge the child’s competency from the behaviour observed, please circle N/A.`),
      generateInputSection(
        {
          label: 'Description of Activities Observed',
          type: 'textarea',
          lines: 3,
          required: false,
          maxLength: 5000,
        },
        -1
      ),
    ]),
    generatePanel(observationalCodeHeadings[0], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 1,
        terminology: '',
        required: false,
        question_options: [
          // 1 - 4 Non-verbal Communication
          generateQuestionOption(developmentalOptionsMinMax,`The child makes eye contact when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child smiles and laughs when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child varies the pitch and volume of his/her voice when talking to others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The distance the child places between him/herself and others when interacting with them is`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(observationalCodeHeadings[1], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 5,
        terminology: '',
        required: false,
        question_options: [
          // 5 - 12 Conversation skills
          generateQuestionOption(developmentalOptionsMinMax,`The child takes appropriate steps to start conversations with other children`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax,`The child takes appropriate steps to start conversations with adults`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child is polite when interacting with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child interrupts others when they are speaking`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child asks other people questions about themselves`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child talks about him/herself or his/her interests when interacting with others`), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child politely ends conversations with other children`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child politely ends conversations with adults`, true), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(observationalCodeHeadings[2], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 13,
        terminology: '',
        required: false,
        question_options: [
          // 13 - 19 Cooperation skills
          generateQuestionOption(developmentalOptionsMinMax,`The child asks other children to play`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child asks other people for help`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child offers to help others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child shares with others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child takes over when doing activities`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child follows the rules when playing with other children`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child compliments and congratulates other children`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(observationalCodeHeadings[3], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 20,
        terminology: '',
        required: false,
        question_options: [
          // 20 - 26 Conflict resolution
          generateQuestionOption(truthyOptions, `The child calmly resolves disagreements with others`, true), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child apologises when he/she upsets someone else`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child ignores bullying/teasing by others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child verbally stands up for him/herself when being bullied`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child physically retaliates when frustrated by others`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMinMax, `The child reports bullying events to adults`), // prettier-ignore
          generateQuestionOption(developmentalOptionsMax, `The child makes fun of other children`), // prettier-ignore
        ],
      }),
    ]),
    generatePanel(observationalCodeHeadings[4], [
      generateQuestionSection({
        title: '',
        text: '',
        type: 'observational',
        score_range: 3,
        start_from: 27,
        terminology: '',
        required: false,
        question_options: [
          // 27 - 30 Emotion Regulation
          generateQuestionOption(truthyOptions, `The child uses appropriate strategies to calm down when feeling anxious/worried`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child uses appropriate strategies to calm down when feeling angry/frustrated`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child stays calm when he/she makes a mistake`, true), // prettier-ignore
          generateQuestionOption(truthyOptions, `The child persists with activities that he/she finds difficult`, true), // prettier-ignore
        ],
      }),
    ]),
    generatePanel('', [
      generateInputSection(
        {
          label: 'Additional Comments',
          type: 'textarea',
          lines: 7,
          required: false,
          maxLength: 5000,
        },
        -2
      ),
    ]),
  ].map((panel, order) => ({ ...panel, order })),
}
