export const FEATURE_communityTipSheets_providerUuids = [
  'b312fc1f-ef7f-4bf3-8240-dd9d287d3862', // ILC SST Lifestart Project
  '5bdeab7a-c527-452c-a42f-de96c6ff3d1f', // Charles Darwin University
  '928536c6-f392-4573-9ff0-94b56363c6c8', // Red Robin (007)
  '301b0274-55a1-4d1e-8af4-c1ce9e63eeea', // Kathleen’s Demo Provider (007)
  //
  '5ef601c0-f4d2-453f-8547-27befc76ada8', // Jape Test Provider
  //
  'e551b8e9-64fc-46ef-840b-8d029a0b2075',
  'f30649ca-63b6-4a78-8c45-c5f86c1d1374',
]
