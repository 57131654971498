import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { purple } from 'themes'

import { LinkButton } from 'common/LinkButton'
import { Logo } from 'common/Logo'
import { Column, Fixed, H2, Row, Spacer } from 'common/ui'
import { HeaderProfile } from 'dashboards/common/HeaderProfile'
import { Body, Header, HeaderLeft, Main, Sidebar, SidebarBody, SidebarHead } from 'dashboards/common/LayoutUI'
import { SidebarNavItem } from 'dashboards/common/SidebarNavItem'
import { NavRoute } from 'dashboards/types'
import { lacksDigiAccess, routes } from './routes'

import { useGenericUser } from 'app/useGenericUser'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { GroupEntity } from 'shared/dashboard/types'
import { FacDashStateProvider, useFacDashData } from './FacilitatorDashboardState'
import { GroupEdit } from './GroupEdit'
import { GroupOverview } from './GroupOverview'

import { usePortraitWidthTrigger } from 'app/OrientationPrompt'
import { useUserState } from 'app/UserState'
import { mentorDashboardBaseUrl } from 'dashboards/mentor/constants'
import { clearEndpointCache } from 'dashboards/utils/endpointHooks'
import { flattenRoutes } from 'dashboards/utils/routeUtils'
import { ProgressionEventsStateProvider, useProgressionEventExists } from 'session/ProgressionEventsState'
import { UNLOCK_DASHBOARD_NAV_ITEM } from 'shared/progressionEvents'
import { sortByKey } from 'utils/sortUtils'
import { facilitatorDashboardBaseUrl } from './constants'

const injectGroupSidebarItems = (routes: NavRoute[], groups: GroupEntity[]) =>
  routes.map((route) =>
    route.path === '/groups'
      ? {
          ...route,
          subpages: [
            ...groups.sort(sortByKey<GroupEntity>('created', 'descending')).map(
              (group): NavRoute => ({
                title: group.name,
                path: `/${group.uid}`,
                Page: GroupOverview,
                exact: true,
                subpages: [
                  {
                    title: `Edit ${group.name}`,
                    path: `/edit`,
                    Page: GroupEdit,
                    exact: true,
                    inaccessible: true,
                  },
                ],
              })
            ),
            ...(route.subpages || []),
          ],
        }
      : route
  )

const hideNavItems = (routes: NavRoute[]): NavRoute[] => {
  return routes
    .filter(({ hideFromNav }) => !hideFromNav)
    .map((item) => (item.subpages ? { ...item, subpages: hideNavItems(item.subpages) } : item))
}

export const FacilitatorDashboard: React.FC = () => {
  usePortraitWidthTrigger(720)
  return (
    <ThemeProvider theme={purple}>
      <ProgressionEventsStateProvider>
        <FacDashStateProvider>
          <FacilitatorDashboardInner />
        </FacDashStateProvider>
      </ProgressionEventsStateProvider>
    </ThemeProvider>
  )
}

export const FacilitatorDashboardInner: React.FC = () => {
  const user = useGenericUser()
  const { drupalProfile } = useUserState()
  const [groups] = useFacDashData('groups', [])
  const [providers] = useFacDashData('providers', [])
  const [orphanedGroupsCount] = useFacDashData('orphanedGroupsCount', 0)
  const [orphanedQuestionnairesCount] = useFacDashData('orphanedQuestionnairesCount', 0)
  const myGroups = drupalProfile
    ? groups.filter(({ facilitator_id }) => drupalProfile.user_id === String(facilitator_id))
    : []

  const hasFacman = useProgressionEventExists({
    type: UNLOCK_DASHBOARD_NAV_ITEM,
    category: 'training',
    subcategory: 'facman',
    event_key: null,
    event_value: null,
  })

  const hasDigiHQ = useProgressionEventExists({
    type: UNLOCK_DASHBOARD_NAV_ITEM,
    category: 'training',
    subcategory: 'digiHq',
    event_key: null,
    event_value: null,
  })

  const removeProgressionLockedNavItems = (routes: NavRoute[]) => {
    const facilitatorAccess =
      user.roles.indexOf('sas-sg facilitator') >= 0 || user.roles.indexOf('sas-sg senior facilitator') >= 0
    const isFacilitatorTrainee = user.roles.indexOf('sas-sg facilitator trainee') >= 0

    return routes.map((route) => {
      if (route.path === '/digi-hq') {
        if (hasDigiHQ && isFacilitatorTrainee) return route
        if (lacksDigiAccess(user, { providers })) return { ...route, inaccessible: true }
        return route
      }
      if (route.path === '/manual') {
        if ((hasFacman && isFacilitatorTrainee) || facilitatorAccess) return route
        return { ...route, inaccessible: true }
      }
      return route
    })
  }

  const availableRoutes = removeProgressionLockedNavItems(routes).filter(
    ({ inaccessible }) =>
      !inaccessible ||
      (typeof inaccessible === 'function' &&
        !inaccessible(user, { providers, orphanedGroupsCount, orphanedQuestionnairesCount }))
  )

  return (
    <Fixed cover>
      <Row style={{ width: '100%', maxWidth: '100vw' }}>
        <Sidebar>
          <SidebarHead>
            <Logo fill="#7D41DF" />
          </SidebarHead>
          <SidebarBody>
            {injectGroupSidebarItems(hideNavItems(availableRoutes), myGroups).map((route, i) => (
              <SidebarNavItem
                key={route.path || i}
                route={route}
                baseUrl={facilitatorDashboardBaseUrl}
                shouldHide={(subpageHideFunction) =>
                  subpageHideFunction(user, { providers, orphanedGroupsCount, orphanedQuestionnairesCount })
                }
              />
            ))}
          </SidebarBody>
          <Spacer flex="1 1 auto" />
          {(user.roles.indexOf('mentor') >= 0 || user.roles.indexOf('administrator') >= 0) && (
            <Column padding={10}>
              <LinkButton
                onClick={clearEndpointCache}
                to={mentorDashboardBaseUrl}
                size="s"
                children="My Mentor Portal"
                theme="parentGreen"
              />
            </Column>
          )}
        </Sidebar>
        <Main>
          <Header>
            <HeaderLeft>
              <H2>Facilitator Dashboard</H2>
            </HeaderLeft>
            <HeaderProfile />
          </Header>
          <Body>
            <Switch
              children={[
                ...flattenRoutes(availableRoutes, facilitatorDashboardBaseUrl).map((route, i) => [
                  <Route path={route.path} exact={route.exact}>
                    <route.Page route={route} />
                  </Route>,
                ]),
                <Route>
                  <RouteNotFound />
                </Route>,
              ]}
            />
          </Body>
        </Main>
      </Row>
    </Fixed>
  )
}
