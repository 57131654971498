import React, { useState } from 'react'

import { NavRouteProps } from 'dashboards/types'

import { Spacer, P, CUT_BL, CUT_BR, CUT_TL, CUT_TR, Padding, Row, TAB_B_L, TAB_T_L } from 'common/ui'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells } from 'dashboards/common/Cell'
import { Page } from 'dashboards/common/Page'
import { GroupCell } from 'dashboards/common/GroupCell'
import { SessionCell } from 'dashboards/common/SessionCell'
import { SpinnerWithLabel } from 'common/Spinner'
import { HeroImage } from 'dashboards/common/HeroImage'

import { useFacDashData, useFacDashState } from './FacilitatorDashboardState'
import { sortByKey } from 'utils/sortUtils'
import { sessionsFilterCatchUp, sessionsFilterFuture } from 'dashboards/utils/reducers'
import { useGenericUser } from 'app/useGenericUser'
import { arrayContainsAny } from 'utils/arrayUtils'

import { useUserState } from 'app/UserState'
import { MentorPanel } from 'dashboards/common/MentorPanel'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { LinkButton } from 'common/LinkButton'
import { facilitatorDashboardBaseUrl } from './constants'

export const Home: React.FC<NavRouteProps> = ({ route }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const user = useGenericUser()
  const { drupalProfile } = useUserState()
  const {
    sessions: { fetch: refetchSessions },
  } = useFacDashState()
  const [groups, loadingGroups, fetchGroups] = useFacDashData('groups', [])
  const [sessions, loadingSessions, fetchSessions] = useFacDashData('sessions', [])
  const futureSessions = sessionsFilterFuture(sessions)
  const catchUpSessions = sessionsFilterCatchUp(sessions)
  const myGroups = drupalProfile
    ? groups.filter(({ facilitator_id }) => drupalProfile.user_id === String(facilitator_id))
    : []
  const otherGroups = drupalProfile
    ? groups.filter(({ cofacilitator_id }) => drupalProfile.user_id === String(cofacilitator_id))
    : []

  const refetchDetails = () => {
    fetchGroups()
    fetchSessions()
  }

  return (
    <>
      <Page route={route}>
        <HeroImage />
        <Spacer />
        {arrayContainsAny(user.roles, ['administrator', 'sas-sg facilitator', 'sas-sg senior facilitator']) && (
          <>
            <HeaderHr children="My Groups" sideRoute={`${facilitatorDashboardBaseUrl}/groups`} />
            {myGroups.length > 0 ? (
              <Cells>
                {myGroups.sort(sortByKey('created', 'descending')).map((group) => (
                  <GroupCell key={group.id} group={group} callback={refetchDetails} />
                ))}
              </Cells>
            ) : loadingGroups ? (
              <SpinnerWithLabel color="#925BED" label="One moment please..." />
            ) : (
              <P>No groups created yet.</P>
            )}
            <Spacer size="l" />
            {otherGroups.length > 0 && (
              <>
                <HeaderHr children="Other Groups" />
                <Cells>
                  {otherGroups.sort(sortByKey('created', 'descending')).map((group) => (
                    <GroupCell key={group.id} group={group} callback={refetchDetails} readOnly />
                  ))}
                </Cells>
                <Spacer size="l" />
              </>
            )}
          </>
        )}
        {arrayContainsAny(user.roles, [
          'administrator',
          'sas-sg facilitator',
          'sas-sg senior facilitator',
          'sas-sg assistant',
        ]) && (
          <>
            <HeaderHr children="Upcoming Sessions" sideRoute={`${facilitatorDashboardBaseUrl}/meetings`} />
            <Cells>
              {futureSessions.length > 0 ? (
                <Cells>
                  {futureSessions.map((session, i) => {
                    const group = groups.find(({ id }) => id === session.group_id)
                    return (
                      <SessionCell
                        key={session.id || i}
                        userType="facilitator"
                        context="future"
                        session={session}
                        group={group}
                        onUpdate={() => refetchSessions()}
                        preventStart={
                          catchUpSessions.length > 0 && group
                            ? !!catchUpSessions.find(({ group_id }) => group_id === group.id)
                            : false
                        }
                        preventStartCallback={() => setModalOpen(true)}
                      />
                    )
                  })}
                </Cells>
              ) : loadingSessions ? (
                <SpinnerWithLabel color="#925BED" label="One moment please..." />
              ) : (
                <P>No meetings created yet.</P>
              )}
            </Cells>
            <Spacer size="l" />
          </>
        )}
        <MentorPanel style={{ maxWidth: '100%', padding: 0, margin: 0 }} alignItems="flex-start">
          <img
            style={{ width: '100%' }}
            alt="Program Overview"
            src={require('dashboards/facilitator/resources/assets/ProgramOverview.png')}
          />
        </MentorPanel>
      </Page>
      <MobileModalPanel
        zIndex={1200}
        isOpen={modalOpen}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l">
          <P style={{ maxWidth: 500 }}>
            Please stop all Catch-up Cadet Club Meetings before starting this new Club Meeting.
            <br />
            <br />
            If cadet’s have already started to log into the open Catch-up meeting, they may need to exit and log in
            again to join this new meeting with you.
          </P>
          <Row justifyContent="center" style={{ paddingBottom: 20 }}>
            <LinkButton size="s" children="Group Meetings" to={`${facilitatorDashboardBaseUrl}/meetings`} />
          </Row>
        </Padding>
      </MobileModalPanel>
    </>
  )
}
