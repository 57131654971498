import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { Column, CUT_BL_S, CUT_TR_S, H1, H3, P, Padding, Panel, Row, Spacer } from 'common/ui'
import { Props as ModalProps } from 'app/FlexModal'
import { TransitionModal } from 'app/MobileMessageModal'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { PanelTitle } from 'session/common/SectionsPanelComponents'

import { Gadget } from 'shared/e-telligence/types'
import { EmotionometerBar } from 'e-telligence/common/EmotionometerBar'
import { emotionRangeGroup, getSituationSentenceForGadget } from 'e-telligence/RelaxationGadgetView'
import { emotionRangeDistribution } from 'e-telligence/constants/typeValueMaps'

const GadgetViewPanel = styled(Row)`
  min-height: 260px;
  max-height: calc(100vh - 380px);
  min-width: 630px;
  max-width: 800px;
  width: calc(100vw - 100px);
`

const GadgetViewImageColumn = styled(Column)`
  flex: 1 1 50%;
  max-width: 280px;
  background-color: #ffffff;
  border: 1px solid #cdd2e4;
  border-right: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`
const GadgetViewImageContainer = styled(Column)`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  max-height: 300px;
`

const GadgetViewContentColumn = styled(Panel)`
  flex: 1 1 50%;
  background-color: #2d90dc;
  color: #ffffff;
  box-shadow: none;
  border-bottom: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 16px;
  & ${H3}, & ${P} {
    color: #ffffff;
  }
  & > ${Padding} {
    display: block;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const GadgetViewModal: React.FC<Omit<ModalProps, 'children'> & ComponentProps<typeof GadgetViewModalInner>> = ({
  children,
  gadget,
  ...props
}) => {
  return <TransitionModal {...props}>{() => <GadgetViewModalInner gadget={gadget} />}</TransitionModal>
}

export const GadgetViewModalInner: React.FC<{ gadget: Gadget | null; compact?: boolean }> = ({ gadget, compact }) => (
  <>
    {gadget && (
      <SectionsPanelStyled
        panelTheme="blue"
        style={{
          maxHeight: 'calc(100vh - 20px)',
          maxWidth: compact ? '100%' : 'calc(100vw - 20px)',
          overflow: 'auto',
          borderRadius: 7,
          pointerEvents: 'auto',
        }}>
        <PanelTitle panelTheme="blue" style={compact ? { padding: '15px 10px' } : {}}>
          <H1 style={{ fontSize: 20, fontStyle: 'italic' }}>{gadget.title}</H1>
        </PanelTitle>
        <Padding size="m">
          <GadgetViewPanel style={compact ? { width: '100%', minHeight: 150 } : {}}>
            <GadgetViewImageColumn style={compact ? { maxWidth: 150 } : {}}>
              <GadgetViewImageContainer>
                {gadget.isPreset ? (
                  <img
                    src={require('e-telligence/assets/gadgets/' + gadget.id + '.png')}
                    alt={gadget.title}
                    style={{ width: '100%', maxWidth: 180 }}
                  />
                ) : (
                  gadget.symbol && <span style={{ fontSize: 84 }}>{gadget.symbol}</span>
                )}
              </GadgetViewImageContainer>
            </GadgetViewImageColumn>
            <GadgetViewContentColumn flair={[CUT_TR_S, CUT_BL_S]} padding={[15, 10]}>
              <Padding size="s">
                <H3 style={{ color: 'white' }}>Best suited for:</H3>
                <P style={{ marginTop: 5 }}>{getSituationSentenceForGadget(gadget)}</P>
                <EmotionometerBar
                  flex="1 0 auto"
                  type={gadget.emotion[gadget.emotion.length - 1]}
                  value={gadget.emotionRange.map((str) => emotionRangeGroup[str])}
                  distribution={emotionRangeDistribution}
                />
                <Spacer size="s" />
                {gadget.description && (
                  <>
                    <H3>How to use it:</H3>
                    {typeof gadget.description === 'string' ? (
                      <P style={{ whiteSpace: 'pre-wrap' }}>{gadget.description}</P>
                    ) : (
                      gadget.description
                    )}
                  </>
                )}
              </Padding>
            </GadgetViewContentColumn>
          </GadgetViewPanel>
        </Padding>
      </SectionsPanelStyled>
    )}
  </>
)
