import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { GenericUser } from 'shared/types'
import { TipsheetEntity, TipsheetType } from 'shared/tipsheets/types'

import { FacilitatorLookupEntity } from 'shared/dashboard/types'
import { CadetModuleCode } from 'shared/dashboard/moduleCodes'
import { PdfStatus, PdfModal } from 'common/PdfModal'
import { Button, ButtonProps } from 'common/ui'

import { createPdf } from 'api'
import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useGenericUser } from 'app/useGenericUser'

export interface TipsheetPrintPayload {
  context: 'mentor' | 'facilitator'
  tipsheet: TipsheetEntity
  facilitatorEntity?: FacilitatorLookupEntity
  facilitator?: GenericUser
}

export interface TipsheetPrintButtonProps {
  context: 'mentor' | 'facilitator'
  tipsheetType: TipsheetType
  moduleCode: CadetModuleCode | 'tipsheet-intro'
  mentorUid?: string
  groupId?: number
}

export const TipsheetPrintButton: React.FC<ButtonProps & TipsheetPrintButtonProps> = ({
  context,
  tipsheetType,
  moduleCode,
  mentorUid,
  groupId,
  ...buttonProps
}) => {
  const user = useGenericUser()
  const { accessToken } = useUserState()
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')
  const [loadingResources, setLoadingResources] = useState<boolean>(false)
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false)
  const [tipsheet] = useEndpoint<TipsheetEntity>(
    !loadingResources ? null : `/api/v1/tipsheets/${tipsheetType}/${moduleCode}`
  )
  const [facilitator] = useEndpoint<FacilitatorLookupEntity>(
    !loadingResources || context !== 'mentor' ? null : `/api/v1/mentors/${mentorUid}/facilitator/${groupId}`
  )
  const handleStartDownload = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setLoadingResources(true)
      setResourcesLoaded(false)
    })
  }
  const handlePdfGeneration = () => {
    if (!tipsheet || !(context === 'mentor' ? !!facilitator : true)) return
    const templateUrl = `${window.location.origin}/print/tipsheet`
    const payload: TipsheetPrintPayload = {
      context,
      tipsheet,
      facilitatorEntity: facilitator || undefined,
      facilitator: context === 'facilitator' ? user : undefined,
    }
    setPdfStatus('busy')
    createPdf<TipsheetPrintPayload>(templateUrl, payload, accessToken, { hideHeaderFooter: true })
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(pdf.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }
  useEffect(() => {
    if (tipsheet && (context === 'mentor' ? facilitator : true) && loadingResources && !resourcesLoaded) {
      console.log('LOADED')
      ReactDOM.unstable_batchedUpdates(() => {
        setLoadingResources(false)
        setResourcesLoaded(true)
        handlePdfGeneration()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipsheet, facilitator, loadingResources, resourcesLoaded])
  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => handleStartDownload()}
        children={
          loadingResources
            ? 'Gathering resources...'
            : pdfStatus === 'busy'
              ? 'Generating PDF...'
              : pdfStatus === 'error'
                ? 'Retry Download'
                : buttonProps.children || 'Download'
        }
        disabled={pdfStatus === 'busy' || loadingResources}
      />
      <PdfModal
        status={loadingResources ? 'busy' : pdfStatus}
        url={pdfUrl}
        onClose={() => setPdfStatus('ready')}
        onCancel={() =>
          ReactDOM.unstable_batchedUpdates(() => {
            setLoadingResources(false)
            setResourcesLoaded(false)
            setPdfStatus('ready')
          })
        }
        text1={'Generating'}
        text2={'Tipsheet'}
      />
    </>
  )
}
