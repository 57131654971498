import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'

import { UserInputStateProvider } from 'session/UserInputState'
import { TipsheetEntity, TipsheetType } from 'shared/tipsheets/types'

import { SpinnerWithLabel } from 'common/Spinner'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { NavRouteProps } from 'dashboards/types'
import { Tipsheet, TipsheetWrapper } from './Tipsheet'

import { getTipsheet } from 'api'
import { useUserState } from 'app/UserState'
import { useGenericUser } from 'app/useGenericUser'
import { MentorDashStateProvider } from 'dashboards/mentor/MentorDashboardState'

interface UrlParams {
  moduleCode: string
}

const TipsheetFacilitatorInner: React.FC<NavRouteProps & { type: TipsheetType }> = ({ type }) => {
  const { accessToken } = useUserState()
  const facilitator = useGenericUser()
  const { moduleCode } = useParams<UrlParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [tipsheetModule, setTipsheetModule] = useState<TipsheetEntity | null>(null)

  useEffect(() => {
    getTipsheet(type, moduleCode, accessToken)
      .then((res) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setTipsheetModule(res)
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {tipsheetModule ? (
        <MentorDashStateProvider>
          <Tipsheet
            viewOnly
            tipsheetModule={tipsheetModule}
            facilitator={facilitator}
            onConfirm={() => {}}
            confirmRead={false}
            printButtonProps={{
              context: 'facilitator',
              tipsheetType: tipsheetModule.type || 'teacher',
              moduleCode: tipsheetModule.module_code,
            }}
          />
        </MentorDashStateProvider>
      ) : (
        <TipsheetWrapper style={{ height: '100%' }}>
          {loading ? (
            <SpinnerWithLabel color="#925BED" label="One moment please..." labelStyle={{ color: 'white' }} />
          ) : (
            <RouteNotFound />
          )}
        </TipsheetWrapper>
      )}
    </>
  )
}

export const TipsheetFacilitator: React.FC<NavRouteProps & { type: TipsheetType }> = (props) => {
  return (
    <UserInputStateProvider>
      <TipsheetFacilitatorInner {...props} />
    </UserInputStateProvider>
  )
}

export const TeacherTipsheetFacilitator: React.FC<NavRouteProps> = (props) => (
  <TipsheetFacilitator {...props} type="teacher" />
)

export const CommunityTipsheetFacilitator: React.FC<NavRouteProps> = (props) => (
  <TipsheetFacilitator {...props} type="community" />
)
