/* eslint-disable @typescript-eslint/no-unused-vars */
import { uniqBy } from 'lodash'
import qs from 'qs'
import React, { ComponentProps, CSSProperties, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import zipcelx, { ZipCelXCell, ZipCelXRow } from 'zipcelx'

import { useUserState } from 'app/UserState'
import { observationalCodeQuestionnaire } from 'dashboards/constant/observationalCode'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { GroupEntity, MentorEntity } from 'shared/dashboard/types'
import {
  getQuestionnaireKey,
  Questionnaire,
  QuestionnaireBooklet,
  questionnaireBookletLabels,
  QuestionnaireKey,
  questionnaireKeyLabels,
  questionnaireKeyLookup,
  QuestionnaireSummarySaved,
  QuestionnaireType,
  QuestionnaireUser,
} from 'shared/questionnaires/types'

import { Spinner, SpinnerWithLabel } from 'common/Spinner'
import { InfoTooltip } from 'common/Tooltip'
import { Button, P, Row, Spacer, TextInput } from 'common/ui'
import { Table, TableProps } from 'reporting/common/ui'
import { AssessmentChart } from './AssessmentChart'
import { isQuestionnaireApplicable, parseScore, QuestionnaireCustomRows, QuestionnaireRows } from './QuestionnaireRows'

import { fontLight } from 'fonts'
import { CircleButton } from 'session/common/CircleButton'

const baseStyles: CSSProperties = {
  color: '#fff',
  textTransform: 'uppercase',
}

const greenRow: CSSProperties = { ...baseStyles, background: 'linear-gradient(180deg, hsl(113, 55%, 57%) 0%, hsl(130, 83%, 33%) 100%)' } // prettier-ignore
const orangeRow: CSSProperties = { ...baseStyles, background: 'linear-gradient(180deg, hsl(35, 95%, 62%) 0%, hsl(32, 100%, 40%) 100%)' } // prettier-ignore
const blueRow: CSSProperties = { ...baseStyles, background: 'linear-gradient(180deg, hsl(199, 96%, 64%) 0%, hsl(204, 97%, 38%) 100%)' } // prettier-ignore
const darkBlueRow: CSSProperties = { ...baseStyles, background: 'linear-gradient(180deg, #4D648B 0%, #011A46 100%)' }
const greyRow: CSSProperties = { ...baseStyles, background: 'linear-gradient(180deg, #CACACA 0%, #818181 100%)' }

type ExcludeBgKeys = 'training-pre' | 'training-post'
type BgStyleKeys = Exclude<QuestionnaireKey, ExcludeBgKeys> | 'feedback'
const backgroundStyles: { [key in BgStyleKeys]: CSSProperties } = {
  'planning-teacher': orangeRow,
  'spence-ssq-parent': greenRow,
  'erssq-parent': greenRow,
  'erssq2-parent': greenRow,
  'spence-ssq-teacher': orangeRow,
  'erssq-teacher': orangeRow,
  'erssq2-teacher': orangeRow,
  'james-anxiety': blueRow,
  'dylan-anger': blueRow,
  'observational-code': darkBlueRow,
  'feedback-cadet': blueRow,
  'feedback-parent': greenRow,
  'feedback-teacher': orangeRow,
  custom: greyRow,
  feedback: greyRow,
}

interface RenderRowProps {
  title: string
  questionnaireKey: QuestionnaireKey
  theme?: BgStyleKeys
  mentors?: MentorEntity[]
  cadetPrimaryMentor?: MentorEntity
}

const emptyCell: ZipCelXCell = { value: '', type: 'string' }

const preferredTypeOrder: QuestionnaireType[] = [
  QuestionnaireType.planning,
  QuestionnaireType.spence_ssq,
  QuestionnaireType.erssq,
  QuestionnaireType.erssq2,
  QuestionnaireType.james_anxiety,
  QuestionnaireType.dylan_anger,
  QuestionnaireType.observational_code,
  QuestionnaireType.feedback,
]

export const AssessmentDashboard: React.FC = () => {
  const { primaryMentorUid } = useParams<{ groupUid: string; primaryMentorUid: string }>()
  const [primaryMentor, { loading: loadingPrimaryMentor }] = useEndpoint<MentorEntity>(
    primaryMentorUid ? `/api/v1/mentors/${primaryMentorUid}` : null
  )
  if (loadingPrimaryMentor) {
    return (
      <Row justifyContent="center">
        <SpinnerWithLabel label="One moment please..." />
      </Row>
    )
  }
  if (!primaryMentor || !primaryMentor.activated) {
    return <P>The assessment table will be available once the cadet place has been activated.</P>
  }
  return <Assessment />
}

export const Assessment: React.FC = () => {
  const { groupUid, primaryMentorUid } = useParams<{ groupUid: string; primaryMentorUid: string }>()
  const { accessToken, drupalProfile } = useUserState()
  const [refetch, setRefetchCount] = useState<number>(0)
  const [primaryMentor, { loading: loadingPrimaryMentor }] = useEndpoint<MentorEntity>(
    primaryMentorUid ? `/api/v1/mentors/${primaryMentorUid}` : null
  )
  const [mentors, { loading: loadingMentors, errorLoading: errorLoadingMentors }] = useEndpoint<MentorEntity[]>(
    `/api/v1/mentors/cadet/${primaryMentorUid}`
  )
  const [group, { loading: loadingGroup, errorLoading: errorLoadingGroup }] = useEndpoint<GroupEntity>(
    `/api/v1/groups/${groupUid}`
  )

  const [questionnaires, { loading: loadingQuestionnaires }] = useEndpoint<Questionnaire[]>(
    group ? `/api/v1/questionnaires/list?group_id=${group.id}` : null,
    [],
    { refetchDespiteCachedValue: true }
  )
  const [questionnaireSummaries, { loading: loadingQuestionnaireSummaries }] = useEndpoint<QuestionnaireSummarySaved[]>(
    primaryMentor ? `/api/v1/questionnaires?${qs.stringify({ cadet_mentor_id: primaryMentor.id, refetch })}` : null
  )

  const loading = loadingMentors || loadingGroup || loadingQuestionnaireSummaries || loadingQuestionnaires

  const cadetPrimaryMentor = primaryMentor || undefined
  const parents: MentorEntity[] = mentors ? mentors.filter((mentor) => mentor.permission_parent_questionnaires) : []
  const teachers: MentorEntity[] = mentors ? mentors.filter((mentor) => mentor.permission_teacher_questionnaires) : []

  const questionnaireRowProps: Pick<
    ComponentProps<typeof QuestionnaireRows>,
    'summaryPartial' | 'questionnaires' | 'questionnaireSummaries' | 'onQuestionnaireSave'
  > = {
    questionnaires: [...(questionnaires || []), observationalCodeQuestionnaire],
    questionnaireSummaries: questionnaireSummaries || [],
    onQuestionnaireSave: () => setRefetchCount((i) => i + 1),
    summaryPartial: {
      drupal_user_id: drupalProfile ? parseInt(drupalProfile.user_id) : undefined,
      cadet_mentor_id: primaryMentor ? primaryMentor.id : -1,
      provider_uid: group ? group.provider_uid : undefined,
      facilitator_names: drupalProfile ? `${drupalProfile.first_name} ${drupalProfile.last_name}` : undefined,
      cadet_name: primaryMentor ? `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}` : undefined,
    },
  }

  const getRenderRowProps = () => {
    return (
      uniqBy(
        [
          ...(questionnaires || []),
          {
            id: -1,
            uid: 'observational-code',
            panels: [],
            title: '',
            type: QuestionnaireType.observational_code,
            user_type: QuestionnaireUser.facilitator,
          } satisfies Questionnaire,
        ],
        // we only need one row per questionnaire key, regardless of if booklets use different questionnaires
        ({ type, user_type }) => getQuestionnaireKey(type, user_type)
      )
        // .sort(sortByKey('user_type'))
        // .sort((a, b) => preferredTypeOrder.indexOf(a.type) - preferredTypeOrder.indexOf(b.type))
        .map(({ short_title, type, user_type }) => {
          const questionnaireKey = getQuestionnaireKey(type, user_type)
          const renderRowProps: RenderRowProps = {
            title: short_title ? short_title : questionnaireKeyLabels[questionnaireKey],
            questionnaireKey,
            theme: questionnaireKey === 'observational-code' ? 'observational-code' : undefined, // TODO: others?
            mentors: user_type === 'parent' ? parents : user_type === 'teacher' ? teachers : undefined,
            cadetPrimaryMentor:
              questionnaireKey === 'observational-code' || user_type === 'cadet' ? cadetPrimaryMentor : undefined,
          }
          return renderRowProps
        })
    )
  }

  const renderRowGroup = ({ title, questionnaireKey, theme, mentors, cadetPrimaryMentor }: RenderRowProps) => {
    const bgStyleKey = (theme || (questionnaireKey in backgroundStyles ? questionnaireKey : 'custom')) as BgStyleKeys
    return (
      <Fragment key={questionnaireKey}>
        <tr>
          <th style={backgroundStyles[bgStyleKey]}>
            <Row>
              <strong>{title}</strong>
              {(questionnaireKey === 'james-anxiety' || questionnaireKey === 'dylan-anger') && (
                <>
                  <div style={{ flex: '1 1 auto' }} />
                  <InfoTooltip
                    iconColor="#705ef0"
                    iconBackground="#fff"
                    placement="top"
                    content={
                      <>
                        Scores for this child questionnaire are entered into the assessment table in one of three ways:
                        <br />
                        <ol style={{ paddingLeft: '1em', marginBottom: 0 }}>
                          <li>Automatically via the live group meeting score, or</li>
                          <li>
                            Through clicking the “✓” to view the completed questionnaire and then manually entering the
                            score into this table, or
                          </li>
                          <li>Through manually entering the score from a “Print & Write” form.</li>
                        </ol>
                      </>
                    }
                  />
                </>
              )}
            </Row>
          </th>
          {[...Array(4)].map((_, i) => (
            <th key={i} style={backgroundStyles[bgStyleKey]} />
          ))}
        </tr>
        <QuestionnaireRows
          {...questionnaireRowProps}
          questionnaireKey={questionnaireKey}
          mentors={mentors}
          cadetPrimaryMentor={cadetPrimaryMentor}
        />
      </Fragment>
    )
  }

  /** ------------------------- */
  /** Here down be XLSX dragons */
  /** ------------------------- */

  const getCellValue = ({
    booklet,
    questionnaireKey,
    questionnaireSummary,
  }: {
    booklet: QuestionnaireBooklet
    questionnaireKey: QuestionnaireKey
    questionnaireSummary?: QuestionnaireSummarySaved
  }) => {
    if (!questionnaireSummary) return ''

    const isApplicable = isQuestionnaireApplicable({ questionnaires: questionnaires || [], questionnaireKey, booklet })
    if (!isApplicable && questionnaireKey !== 'custom') return 'N/A'

    let returnStr = ''

    const { score, facilitator_created } = questionnaireSummary
    const manual_score = parseScore(questionnaireSummary.manual_score)
    const observational_code_score = parseScore(questionnaireSummary.observational_code_score)

    if (questionnaireKey === 'custom' || facilitator_created) {
      return typeof manual_score === 'number' ? String(manual_score) : ''
    } else if (questionnaireKey === 'observational-code') {
      if (typeof observational_code_score === 'number') returnStr += observational_code_score.toFixed(2)
      else returnStr += '--'
    } else if (typeof score === 'number') {
      returnStr += score === 0 ? '✓' : String(score)
    } else {
      returnStr += '--'
    }

    if (typeof manual_score === 'number') {
      // returnStr += ` (${manual_score})`
      returnStr = String(manual_score)
    }

    return returnStr
  }

  const getExcelRows = ({ questionnaireKey, mentors, cadetPrimaryMentor }: RenderRowProps): ZipCelXRow[] => {
    if (!questionnaireSummaries) return []
    if (!(questionnaireKey in questionnaireKeyLookup)) {
      alert(`Unknown questionnaireKey in getExcelRows: ${questionnaireKey}\nPlease report this error to support!`)
      throw new Error(
        `Unknown questionnaireKey in getExcelRows: ${questionnaireKey}\nPlease report this error to support!`
      )
    }
    const { type: questionnaireType, userType } = questionnaireKeyLookup[questionnaireKey]

    let label = questionnaireKeyLabels[questionnaireKey]
    if (questionnaires) {
      const questionnaire = questionnaires.find((qn) => qn.type === questionnaireType && qn.user_type === userType)
      if (questionnaire) {
        if (questionnaire.short_title) label = questionnaire.short_title
        else if (questionnaire.public_title) label = questionnaire.public_title
      }
    }

    const items = questionnaireSummaries.filter(
      (questionnaireSummary) =>
        // this check for matching questionnaireKey or questionnaire.type I'm a bit iffy on
        // I thought I'd kept it so that the overview.questionnaire_type value would match questionnaireKey
        // but somewhere along the line I must have updated the data that gets saved to a questionnaire overview
        // to just be the questionnaire type and not the overall key
        // I'm not sure if this will return false positives or not for cross-user-type lookups...
        questionnaireSummary.questionnaire_type === questionnaireKey ||
        questionnaireSummary.questionnaire_type === questionnaireType
    )
    const rows = mentors
      ? mentors.reduce(
          (obj, mentor, i) => ({ ...obj, [mentor.id]: items.filter(({ mentor_id }) => mentor_id === mentor.id) }),
          {} as { [key: number]: QuestionnaireSummarySaved[] }
        )
      : cadetPrimaryMentor
        ? { [cadetPrimaryMentor.id]: items.filter(({ cadet_mentor_id }) => cadet_mentor_id === cadetPrimaryMentor.id) }
        : items.reduce(
            (obj, questionnaire, i) => ({
              ...obj,
              [questionnaire.mentor_id || -1]: [...(obj[questionnaire.mentor_id || -1] || []), questionnaire],
            }),
            {} as { [key: number]: QuestionnaireSummarySaved[] }
          )
    const excelRows: ZipCelXRow[] = []
    Object.entries(rows).forEach(([mentorId, questionnaireGroup]) => {
      const mentor = mentors ? mentors.find((m) => m.id === parseInt(mentorId)) : undefined

      // skip deleted mentors with no questionnaires for current type
      if (mentor && mentor.deleted && !questionnaireGroup.length) return

      const name = mentor
        ? `${mentor.deleted ? '[Deleted] ' : ''}${mentor.first_name} ${mentor.last_name}`
        : cadetPrimaryMentor
          ? `${cadetPrimaryMentor.cadet_first_name} ${cadetPrimaryMentor.cadet_last_name}`
          : questionnaireGroup[0]?.name

      excelRows.push([
        { value: name, type: 'string' },
        ...[...Array(4)].map((_, i) => {
          const booklet = (i + 1) as QuestionnaireBooklet
          const questionnaireSummary = questionnaireGroup.find((q) => q.questionnaire_booklet === booklet)
          const cell: ZipCelXCell = {
            value: getCellValue({
              booklet,
              questionnaireKey,
              questionnaireSummary,
            }),
            type: 'string',
          }
          return cell
        }),
      ])
    })
    return [[{ value: label.toUpperCase(), type: 'string' }, emptyCell, emptyCell, emptyCell, emptyCell], ...excelRows]
  }

  const getCustomExcelRows = (): ZipCelXRow[] => {
    if (!questionnaireSummaries) return []
    const customQuestionnaires = (questionnaireSummaries || []).filter(
      ({ questionnaire_type }) => questionnaire_type === 'custom'
    )
    const rows = customQuestionnaires.reduce(
      (obj, questionnaire) => ({ ...obj, [questionnaire.name]: [...(obj[questionnaire.name] || []), questionnaire] }),
      {} as { [key: string]: QuestionnaireSummarySaved[] }
    )
    return Object.keys(rows).map((name) => {
      const questionnaireGroup = rows[name]
      const row: ZipCelXRow = [
        { value: name, type: 'string' },
        ...[...Array(4)].map((_, i) => {
          const questionnaireSummary = questionnaireGroup.find((q) => q.questionnaire_booklet === i + 1)
          const cell: ZipCelXCell = {
            value: getCellValue({ booklet: 1, questionnaireKey: 'custom', questionnaireSummary }),
            type: 'string',
          }
          return cell
        }),
      ]
      return row
    })
  }

  const handleSpreadsheetDownload = () => {
    zipcelx({
      filename: `${group ? group.name : 'Group'} - ${Date.now()}`,
      sheet: {
        data: [
          [
            { value: 'Measure', type: 'string' },
            ...([...Array(4)].map((_, i) => ({
              value: questionnaireBookletLabels[(i + 1) as QuestionnaireBooklet],
              type: 'string',
            })) as ZipCelXCell[]),
          ],
          ...getRenderRowProps().flatMap(getExcelRows),
          // ...getExcelRows({ questionnaireKey: 'spence-ssq-parent', mentors: parents }),
          // ...getExcelRows({ questionnaireKey: 'erssq-parent', mentors: parents }),
          // ...getExcelRows({ questionnaireKey: 'spence-ssq-teacher', mentors: teachers }),
          // ...getExcelRows({ questionnaireKey: 'erssq-teacher', mentors: teachers }),
          // ...getExcelRows({ questionnaireKey: 'james-anxiety', cadetPrimaryMentor }),
          // ...getExcelRows({ questionnaireKey: 'dylan-anger', cadetPrimaryMentor }),
          // ...getExcelRows({ questionnaireKey: 'observational-code', cadetPrimaryMentor }),
          // ...getExcelRows({ questionnaireKey: 'feedback-cadet', cadetPrimaryMentor }),
          // ...getExcelRows({ questionnaireKey: 'feedback-parent', mentors: parents }),
          // ...getExcelRows({ questionnaireKey: 'feedback-teacher', mentors: teachers }),
          [{ value: 'CUSTOM', type: 'string' }, emptyCell, emptyCell, emptyCell, emptyCell],
          ...getCustomExcelRows(),
        ],
      },
    })
  }

  /** ----------------------- */
  /** Here up be XLSX dragons */
  /** ----------------------- */

  return (
    <>
      <Row justifyContent="center">
        <Legend>
          <legend style={{ padding: '0 8px' }}>Legend</legend>
          <Row flexWrap>
            <Row alignItems="center">
              <div style={{ width: 30 }}>
                <CircleButton size="xs" children="+" onClick={() => {}} style={{ fontSize: 16 }} />
              </div>
              <span>= Link a previously completed online questionnaire</span>
            </Row>
            <Spacer size="l" />
            <Row alignItems="center">
              <div style={{ width: 55 }}>
                <TextInput inputSize="s" value="" style={{ width: 52, padding: '5px 12px' }} readOnly />
              </div>
              <Spacer size="xs" />
              <span>= Manually enter a questionnaire score</span>
            </Row>
          </Row>
        </Legend>
      </Row>
      <StyledTable striped loading={loading ? 1 : 0}>
        <thead>
          <tr>
            <th style={{ position: 'relative', zIndex: 2 }}>
              <Row>
                <div>Measure</div>
                <div style={{ flex: '1 1 auto' }} />
                <InfoTooltip
                  placement={'bottom'}
                  tooltipStyle={{ zIndex: 9999 }}
                  containerStyle={{ zIndex: 9999 }}
                  content={
                    <span>
                      Each built-in questionnaire type used in SAS are listed below. Custom assessment measures can be
                      added to the last line of the table.
                      <br />
                      <br />
                      Mentors are listed when they are added as a Cadet's Mentor with the Questionnaire permission.
                      <br />
                      <br />
                      Link a Questionnaire that has previously been completed online, or manually enter a score from an
                      offline administration methods. <br />
                      Watch the graph underneath as scores are added overtime.
                    </span>
                  }
                />
              </Row>
            </th>
            {[...Array(4)].map((_, i) => (
              <th key={i}>
                {questionnaireBookletLabels[(i + 1) as QuestionnaireBooklet]}
                {i === 0 && (
                  <InfoTooltip
                    placement={'bottom'}
                    content={
                      <span>
                        Pre-program Questionnaire Booklet 1 is typically completed during intake before a group is
                        established in the software. These questionnaires will be waiting in the Unlinked Submissions
                        list.
                        <br />
                        <br />
                        To link pre-program questionnaires for this Cadet, use the “+” button and select the
                        corresponding questionnaire to link.
                      </span>
                    }
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getRenderRowProps().map(renderRowGroup)}
          {/* 
          {renderRowGroup({ questionnaireKey: 'spence-ssq-parent', mentors: parents })}
          {renderRowGroup({ questionnaireKey: 'erssq-parent', mentors: parents })}
          {renderRowGroup({ questionnaireKey: 'spence-ssq-teacher', mentors: teachers })}
          {renderRowGroup({ questionnaireKey: 'erssq-teacher', mentors: teachers })}
          {renderRowGroup({ questionnaireKey: 'james-anxiety', cadetPrimaryMentor })}
          {renderRowGroup({ questionnaireKey: 'dylan-anger', cadetPrimaryMentor })}
          {renderRowGroup({ questionnaireKey: 'observational-code', theme: 'observational-code', cadetPrimaryMentor })}
          {renderRowGroup({ questionnaireKey: 'feedback-cadet', cadetPrimaryMentor })}
          {renderRowGroup({ questionnaireKey: 'feedback-parent', mentors: parents })}
          {renderRowGroup({ questionnaireKey: 'feedback-teacher', mentors: teachers })} 
          */}
          <tr>
            <th style={backgroundStyles['custom']}>
              <strong>Custom</strong>
            </th>
            {[...Array(4)].map((_, i) => (
              <th key={i} style={backgroundStyles['custom']} />
            ))}
          </tr>
          <QuestionnaireCustomRows {...questionnaireRowProps} />
        </tbody>
      </StyledTable>
      <Spacer size="m" />
      <Row>
        <div>
          <Button children="Download Spreadsheet" size="s" onClick={() => handleSpreadsheetDownload()} />
        </div>
        <Spacer flex="1 1 auto" />
        <Row alignItems="center">
          <InfoTooltip
            placement="left"
            content={
              <span>
                The above table doesn't live-update. <br />
                If you're expecting to see changes appear in real-time, you can use this button instead of reloading the
                whole page.
              </span>
            }
          />
          <Spacer size="s" />
          <Button
            size="s"
            theme="grey"
            children={loadingQuestionnaireSummaries ? 'Loading...' : 'Reload table'}
            disabled={loadingQuestionnaireSummaries}
            onClick={() => setRefetchCount((i) => i + 1)}
          />
        </Row>
      </Row>
      <Spacer size="l" />
      <AssessmentChart questionnaires={questionnaires || []} questionnaireSummaries={questionnaireSummaries || []} />

      {loading && (
        <FixedSpinnerContainer>
          <Spinner size={30} color="#6737b5" />
        </FixedSpinnerContainer>
      )}
    </>
  )
}

const FixedSpinnerContainer = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 15px;
  right: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
`

const Legend = styled.fieldset`
  ${fontLight}
  display: inline-block;
  border-radius: 7px;
  font-size: 14px;
  margin-bottom: 15px;
  padding: 5px 15px 10px 15px;
  border: 1px solid #cdd2e4;
`

const StyledTable = styled(Table)<TableProps & { loading?: 1 | 0 }>`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  border-top: none;
  border-left: none;
  border-right: none;

  position: relative;
  ${(p) =>
    p.loading
      ? css`
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            z-index: 10;
          }
        `
      : ''}

  thead tr {
    th {
      color: #6737b5;
      background-color: #e9dcff;
      padding: 15px 10px;
    }
    th,
    td {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  tbody tr {
    th {
      font-size: 14px;
    }
    th,
    td {
      min-height: 58px;
      &:first-child {
        border-left: 1px solid #abb3dc;
      }
      &:last-child {
        border-right: 1px solid #abb3dc;
      }
    }
  }
`
