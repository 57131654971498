import React from 'react'
import { CUT_BL_DARK, CUT_BR_DARK, CUT_TL_DARK, CUT_TR_DARK, P, TAB_B_DARK, TAB_T_DARK } from 'common/ui'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { PanelTitle } from 'session/common/SectionsPanelComponents'
import styled, { css } from 'styled-components'
import { fontBlack } from 'fonts'
import requireImageSize from '../../../../macros/requireImageSize.macro'

const SvgImageSize = requireImageSize('../../../assets/conversation-equation.svg')

export const ConversationEquations: React.FC<{}> = () => (
  <>
    <Title panelTheme="blue">
      Conversation Equations <span>The conversation code</span>
    </Title>
    <SectionsPanelStyled
      panelTheme={'blue'}
      flex={'1 0 auto'}
      flair={[CUT_TR_DARK, CUT_TL_DARK, CUT_BL_DARK, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, TAB_T_DARK]}
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <div style={{ padding: '30px 20px' }}>
        <P style={{ width: '60%', color: '#fff' }}>
          Try to follow these 3 equations to take turns when listening and speaking to others (individuals and groups).
        </P>
        <RatioDiv width={SvgImageSize.width} height={SvgImageSize.height}>
          <Equation src={require('session/assets/conversation-equation.svg')?.default} alt="" />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/1a.png')}
            x={2}
            y={5.1}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/1b.png')}
            x={35}
            y={5.1}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/1c.png')}
            x={68}
            y={5.1}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/2a.png')}
            x={2}
            y={38}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/2b.png')}
            x={35}
            y={38}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/1d.png')}
            x={68}
            y={38}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/3a.png')}
            x={2}
            y={71}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/3b.png')}
            x={35}
            y={71}
            width={27}
            height={23.3}
          />
          <AbsoluteImage
            image={require('session/assets/conversation-equations/3c.png')}
            x={68}
            y={71}
            width={27}
            height={23.3}
          />
        </RatioDiv>
      </div>
    </SectionsPanelStyled>
  </>
)

export const Title = styled(PanelTitle)`
  ${fontBlack};
  color: #fff;
  font-style: italic;
  font-size: 1.3em;
  margin: 0 auto;

  span {
    font-size: 0.7em;
  }
`

const Equation = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`

const RatioDiv = styled.div<{ height: number; width: number }>`
  position: relative;
  height: 0;
  width: 100%;
  ${(p) => css`
    padding-top: ${(p.height / p.width) * 100}%;
  `}
`

const AbsoluteImage = styled.div<{ width: number; height: number; x: number; y: number; image: string }>`
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  background-position: center;
  background-size: cover;

  ${(p) => css`
    left: ${p.x}%;
    top: ${p.y}%;
    width: ${p.width}%;
    height: ${p.height}%;
    background-image: url(${p.image});
  `}
`
