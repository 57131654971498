import React from 'react'
import styled from 'styled-components'

import { TipsheetPrintPayload } from './TipsheetPrintButton'

import { Spacer } from 'common/ui'
import { Tipsheet } from './Tipsheet'
import { TipsheetPanel } from './TipsheetStep'
import { FooterContainer, TipsheetFooterPanel } from './sections/TipsheetFooter'

import { MentorDashStateProvider } from 'dashboards/mentor/MentorDashboardState'
import { UserInputStateProvider } from 'session/UserInputState'

import { usePdfPayload } from 'utils/usePdfPayload'

export const TipsheetPrint: React.FC = () => {
  const payload = usePdfPayload<TipsheetPrintPayload>()
  // const payload = testPayload

  if (!payload) return <h1>no payload</h1>
  return (
    <TipsheetPrintWrapper>
      <UserInputStateProvider>
        <MentorDashStateProvider>
          <Tipsheet
            viewOnly
            printMode
            tipsheetModule={payload.tipsheet}
            facilitatorEntity={payload.facilitatorEntity}
            facilitator={payload.facilitator}
            onConfirm={() => {}}
            confirmRead={false}
          />
        </MentorDashStateProvider>
      </UserInputStateProvider>
    </TipsheetPrintWrapper>
  )
}

const TipsheetPrintWrapper = styled.div`
  @media print {
    & ${TipsheetPanel} {
      /* page-break-before: avoid; */
      page-break-after: auto;
      page-break-inside: avoid;
      /* break-before: avoid; */
      break-after: auto;
      break-inside: auto;
      box-decoration-break: clone;
      /* orphans: 10; */
      /* widows: 10; */
    }
    & ${TipsheetFooterPanel} {
      page-break-inside: avoid;
      break-inside: avoid;
    }
    & .panel-wrapper {
      border: 1px solid transparent;
      & > ${TipsheetPanel} {
        margin-top: 15px;
      }
      & > ${Spacer} {
        display: none;
      }
    }
    & ${FooterContainer} {
      & ${TipsheetFooterPanel} {
        margin-top: 15px;
        & + ${Spacer} {
          display: none;
        }
      }
    }
  }
`
