import React, { Fragment, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'

import { Column, P, Row, Spacer, Button } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { SpinnerWithLabel } from 'common/Spinner'
import { GroupEntity } from 'shared/dashboard/types'
import { ConfirmModal } from 'common/ConfirmModal'

import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useFacDashData } from './FacilitatorDashboardState'
import { postJson } from 'utils/apiUtils'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { facilitatorDashboardBaseUrl } from './constants'

export const OrphanGroups: React.FC<NavRouteProps> = ({ route }) => {
  const [providers, loadingProviders] = useFacDashData('providers', [])
  const [orphanGroups, { loading }] = useEndpoint<GroupEntity[]>(`/api/v1/groups/orphan/list`, [])
  const { accessToken } = useUserState()
  const history = useHistory()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [groupUid, setGroupUid] = useState<string | null>(null)
  const [providerUid, setProviderUid] = useState<string | null>(null)

  const handleConfirm = (confirmed: boolean) => {
    setShowModal(false)
    if (confirmed && groupUid && providerUid)
      postJson(`/api/v1/groups/allocate/${groupUid}/${providerUid}`, {}, getAuthRequestParams(accessToken), {
        retries: 0,
      })
        .then(() => {
          history.replace(`${facilitatorDashboardBaseUrl}/groups`)
        })
        .catch((e) => {
          alert('Unable to remove group')
          console.warn(e)
        })
  }

  const handleClickClaim = (groupUid: string, providerUid: string) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowModal(true)
      setProviderUid(providerUid)
      setGroupUid(groupUid)
    })
  }

  if (loading || loadingProviders)
    return (
      <Page route={route}>
        <SpinnerWithLabel label="Loading groups..." color="#925BED" />
      </Page>
    )

  if ((orphanGroups && orphanGroups.length <= 0) || !orphanGroups)
    return (
      <Page route={route}>
        <P>No groups to allocate</P>
      </Page>
    )

  return (
    <Page route={route}>
      <Spacer />

      <P>
        These groups have been unassigned by a lead Facilitator linked to the same SAS Provider as you and are available
        to be reassigned to you or another Facilitator linked to this same SAS Provider.
      </P>

      <P>
        These groups have no Lead Facilitator assigned to manage them. Each group is available to be reassigned through
        using the "Assign Group" button. All SAS Facilitators within an SAS Provider network can see all unassigned
        groups under their SAS Provider only. If working in a team, please coordinate who is taking on the new Lead
        Facilitator role.
      </P>

      <P style={{ marginBottom: 0 }}>Groups appear here when:</P>
      <ul style={{ marginTop: 3 }}>
        <li>
          <P style={{ margin: 2 }}>A Lead Facilitator uses the "Unassign" button.</P>
        </li>
        <li>
          <P style={{ margin: 2 }}>A Lead Facilitator is unlinked from an SAS Provider.</P>
        </li>
      </ul>

      <Spacer />
      {providers.map((provider, i) => {
        const providerGroups = orphanGroups.filter((group) => group.provider_uid === provider.id)
        if (providerGroups.length <= 0) return null
        return (
          <Fragment key={i}>
            <HeaderHr children={provider.name} />
            <Cells>
              {providerGroups.map((group) => (
                <Cell key={group.id}>
                  <CellInner>
                    <Column flex="1 1 auto">
                      {group.name}
                      <Spacer size="xs" />
                      <small>Cadets: {group.primary_mentors.length}</small>
                    </Column>
                    <Row flex="none">
                      <Button
                        size="s"
                        children="Assign Group"
                        onClick={() => {
                          handleClickClaim(group.uid, provider.id)
                        }}
                      />
                    </Row>
                  </CellInner>
                </Cell>
              ))}
            </Cells>
            <Spacer />
          </Fragment>
        )
      })}

      <ConfirmModal
        isOpen={showModal}
        onSubmit={handleConfirm}
        label="Are you sure you want to assign this group with yourself as the primary/managing SAS Facilitator? If not, please ask a different team member to take these steps from their own Facilitator Dashboard."
      />
    </Page>
  )
}
