/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/browser'
import React, { ComponentProps, useState } from 'react'
import ReactDOM from 'react-dom'

import { MentorEntity } from 'shared/dashboard/types'
import {
  Questionnaire,
  QuestionnaireBooklet,
  QuestionnaireKey,
  QuestionnaireSummary,
  QuestionnaireSummarySaved,
  QuestionnaireType,
  questionnaireBookletLabels,
  questionnaireKeyLabels,
  questionnaireKeyLookup,
  scorelessQuestionnaireTypes,
} from 'shared/questionnaires/types'

import { createQuestionnaire, deleteQuestionnaire, renameCustomQuestionnaires, updateQuestionnaire } from 'api'
import { useUserState } from 'app/UserState'

import { ButtonLink } from 'common/LinkButton'
import { Spinner } from 'common/Spinner'
import { Tooltip } from 'common/Tooltip'
import { OutlineButton, TextInput } from 'common/ui'
import { PromptModal } from 'dashboards/common/PromptModal'
import { CircleButton } from 'session/common/CircleButton'

import { ConfirmModal } from 'common/ConfirmModal'
import { QuestionnaireOrphanModal } from './QuestionnaireOrphanModal'
import { QuestionnaireQuickViewModal } from './QuestionnaireQuickViewModal'

export const QuestionnaireRows: React.FC<{
  questionnaires: Questionnaire[]
  questionnaireKey: QuestionnaireKey
  questionnaireSummaries: QuestionnaireSummarySaved[] | null
  mentors?: MentorEntity[]
  cadetPrimaryMentor?: MentorEntity
  summaryPartial: Omit<QuestionnaireSummaryBase, 'name' | 'mentor_id' | 'questionnaire_booklet' | 'questionnaire_type'>
  onQuestionnaireSave: () => void
}> = ({
  questionnaires,
  questionnaireKey,
  mentors,
  cadetPrimaryMentor,
  questionnaireSummaries,
  summaryPartial,
  onQuestionnaireSave,
}) => {
  if (!questionnaireSummaries) return null
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(`Unknown questionnaireKey in QuestionnaireRows: ${questionnaireKey}\nPlease report this error to support!`)
    throw new Error(
      `Unknown questionnaireKey in QuestionnaireRows: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { type: questionnaireType } = questionnaireKeyLookup[questionnaireKey]
  const items = questionnaireSummaries.filter(
    // this check for matching questionnaireKey or questionnaire.type I'm a bit iffy on
    // I thought I'd kept it so that the overview.questionnaire_type value would match questionnaireKey
    // but somewhere along the line I must have updated the data that gets saved to a questionnaire overview
    // to just be the questionnaire type and not the overall key
    // I'm not sure if this will return false positives or not for cross-user-type lookups...
    (summary) => summary.questionnaire_type === questionnaireKey || summary.questionnaire_type === questionnaireType
  )
  const rows = mentors
    ? mentors.reduce(
        (obj, mentor, i) => ({ ...obj, [mentor.id]: items.filter(({ mentor_id }) => mentor_id === mentor.id) }),
        {} as { [key: number]: QuestionnaireSummarySaved[] }
      )
    : cadetPrimaryMentor
      ? { [cadetPrimaryMentor.id]: items.filter(({ cadet_mentor_id }) => cadet_mentor_id === cadetPrimaryMentor.id) }
      : items.reduce(
          (obj, questionnaire, i) => ({
            ...obj,
            [questionnaire.mentor_id || -1]: [...(obj[questionnaire.mentor_id || -1] || []), questionnaire],
          }),
          {} as { [key: number]: QuestionnaireSummarySaved[] }
        )
  return (
    <>
      {Object.keys(rows).map((_mentorId, rowIndex) => {
        const mentorId: number = typeof _mentorId === 'string' ? parseInt(_mentorId) : _mentorId
        const questionnaireGroup = rows[mentorId]
        const mentor = mentors ? mentors.find(({ id }) => id === mentorId) : undefined
        if (mentor && mentor.deleted && !questionnaireGroup.length) return null
        const name = mentor
          ? `${mentor.first_name} ${mentor.last_name}`
          : cadetPrimaryMentor
            ? `${cadetPrimaryMentor.cadet_first_name} ${cadetPrimaryMentor.cadet_last_name}`
            : questionnaireGroup[0]?.name
        const extendedSummaryPartial: Omit<
          ComponentProps<typeof QuestionnaireColumn>['summaryPartial'],
          'questionnaire_booklet'
        > = {
          ...summaryPartial,
          name,
          questionnaire_type: questionnaireKey,
          mentor_id: mentor ? mentor.id : undefined,
        }
        return (
          <tr key={mentorId || `_${rowIndex}`}>
            <td>{mentor && mentor.deleted ? <del>{name}</del> : name}</td>
            {[...Array(4)].map((_, i) => (
              <QuestionnaireColumn
                key={i}
                questionnaires={questionnaires}
                questionnaireSummary={questionnaireGroup.find((q) => q.questionnaire_booklet === i + 1)}
                summaryPartial={{ ...extendedSummaryPartial, questionnaire_booklet: (i + 1) as QuestionnaireBooklet }}
                onQuestionnaireSave={onQuestionnaireSave}
              />
            ))}
          </tr>
        )
      })}
    </>
  )
}

export const QuestionnaireCustomRows: React.FC<{
  questionnaires: Questionnaire[]
  questionnaireSummaries: QuestionnaireSummarySaved[] | null
  onQuestionnaireSave: () => void
  summaryPartial: Omit<QuestionnaireSummaryBase, 'name' | 'mentor_id' | 'questionnaire_booklet' | 'questionnaire_type'>
}> = ({ questionnaires, summaryPartial, questionnaireSummaries, onQuestionnaireSave }) => {
  const { accessToken } = useUserState()
  const [savingQuestionnaire, setSavingQuestionnaire] = useState(false)
  const [showAddCustomModal, setShowAddCustomModal] = useState<boolean>(false)
  const [showEditCustomModal, setShowEditCustomModal] = useState<boolean>(false)
  const [customName, setCustomName] = useState<string>('')
  const [initialCustomName, setInitialCustomName] = useState<string>('')
  const customQuestionnaires = (questionnaireSummaries || []).filter(
    ({ questionnaire_type }) => questionnaire_type === 'custom'
  )
  const rows = customQuestionnaires.reduce(
    (obj, questionnaire) => ({ ...obj, [questionnaire.name]: [...(obj[questionnaire.name] || []), questionnaire] }),
    {} as { [key: string]: QuestionnaireSummarySaved[] }
  )
  const handleCustomSubmit = (confirm: boolean) => {
    setShowAddCustomModal(false)
    if (confirm && !!customName) {
      if (showEditCustomModal) {
        setShowEditCustomModal(false)
        renameCustomQuestionnaires(
          customName,
          initialCustomName,
          { cadet_mentor_id: summaryPartial.cadet_mentor_id },
          accessToken
        )
          .then((data) => {
            console.log('Renamed custom data point title!', data)
            setCustomName('')
            setInitialCustomName('')
            onQuestionnaireSave()
          })
          .catch((e) => {})
      } else {
        console.log('creating dummy overview for', customName)
        setSavingQuestionnaire(true)
        createQuestionnaire(
          {
            ...summaryPartial,
            name: customName,
            questionnaire_type: 'custom',
            questionnaire_booklet: 1,
            date: new Date().toISOString().split('T')[0],
            mentor_id: undefined,
            facilitator_created: true,
            responses: [],
          },
          accessToken
        )
          .then((data) => {
            console.log('Saved!', data)
            setSavingQuestionnaire(false)
            setCustomName('')
            onQuestionnaireSave()
          })
          .catch((e) => {
            setSavingQuestionnaire(false)
          })
      }
    }
  }
  return (
    <>
      {Object.keys(rows).map((name) => {
        const questionnaireGroup = rows[name]
        const extendedSummaryPartial: Omit<
          ComponentProps<typeof QuestionnaireColumn>['summaryPartial'],
          'questionnaire_booklet'
        > = {
          ...summaryPartial,
          name,
          questionnaire_type: 'custom',
          mentor_id: undefined,
        }
        return (
          <tr key={name}>
            <td>
              <ButtonLink
                role="link"
                style={{ color: '#7D41DF' }}
                children={name}
                onClick={() =>
                  ReactDOM.unstable_batchedUpdates(() => {
                    setCustomName(name)
                    setInitialCustomName(name)
                    setShowEditCustomModal(true)
                  })
                }
              />
            </td>
            {[...Array(4)].map((_, i) => (
              <QuestionnaireColumn
                key={i}
                questionnaires={questionnaires}
                questionnaireSummary={questionnaireGroup.find((q) => q.questionnaire_booklet === i + 1)}
                summaryPartial={{ ...extendedSummaryPartial, questionnaire_booklet: (i + 1) as QuestionnaireBooklet }}
                onQuestionnaireSave={onQuestionnaireSave}
              />
            ))}
          </tr>
        )
      })}
      {savingQuestionnaire && (
        <tr>
          <td>{customName}</td>
          {[...Array(4)].map((_, i) => (
            <td key={i} />
          ))}
        </tr>
      )}
      <tr>
        <td colSpan={5}>
          <OutlineButton
            size="xs"
            onClick={() => setShowAddCustomModal(true)}
            children="Add custom tracking data label"
            style={{ width: '100%', cursor: 'pointer' }}
          />
        </td>
      </tr>
      {(showAddCustomModal || showEditCustomModal) && (
        <PromptModal
          isOpen
          label={`${showEditCustomModal ? 'Edit' : 'Add'} custom tracking data label`}
          onSubmit={handleCustomSubmit}>
          <TextInput
            value={customName}
            onChange={(e) => setCustomName(e.target.value || '')}
            placeholder={showEditCustomModal ? initialCustomName : ''}
          />
        </PromptModal>
      )}
    </>
  )
}

type QuestionnaireSummaryBase = Pick<
  QuestionnaireSummary,
  | 'drupal_user_id'
  | 'mentor_id'
  | 'cadet_mentor_id'
  | 'provider_uid'
  | 'name'
  | 'cadet_name'
  | 'facilitator_names'
  | 'questionnaire_type'
  | 'questionnaire_booklet'
>

const tooltipProps: Partial<ComponentProps<typeof Tooltip>> = {
  placement: 'bottom',
  tooltipStyle: { width: 160, padding: '7px 9px' },
}

export const parseScore = (manualScore: undefined | number | string): number | undefined => {
  if (manualScore === undefined) return undefined
  if (typeof manualScore === 'string') return parseFloat(manualScore)
  return manualScore
}

export const isQuestionnaireApplicable = ({
  questionnaires,
  questionnaireKey,
  booklet,
}: {
  questionnaires: Questionnaire[]
  questionnaireKey: QuestionnaireKey
  booklet?: QuestionnaireBooklet
}) => {
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in isQuestionnaireApplicable: ${questionnaireKey}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in isQuestionnaireApplicable: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { userType, type } = questionnaireKeyLookup[questionnaireKey]
  if (!questionnaires.find(({ type }) => type === QuestionnaireType.observational_code)) {
    console.warn('Observational code questionnaire not found in the list of questionnaires')
  }
  return (
    questionnaires
      .filter((q) => !q.booklet || +q.booklet === booklet)
      .filter((q) => q.type === type && (q.user_type === userType || q.type === QuestionnaireType.observational_code))
      .length > 0
  )
}

export const QuestionnaireColumn: React.FC<{
  questionnaires: Questionnaire[]
  questionnaireSummary?: QuestionnaireSummarySaved
  summaryPartial: QuestionnaireSummaryBase
  onQuestionnaireSave: () => void
}> = ({ questionnaires, questionnaireSummary, summaryPartial, onQuestionnaireSave }) => {
  const { accessToken } = useUserState()
  const [showInputModal, setShowInputModal] = useState(false)
  const [showOrphanModal, setShowOrphanModal] = useState(false)
  const [showDeleteManualScoreModal, setShowDeleteManualScoreModal] = useState(false)
  const [deletingManualScore, setDeletingManualScore] = useState(false)
  const [savingQuestionnaire, setSavingQuestionnaire] = useState(false)
  const [adoptingQuestionnaire, setAdoptingQuestionnaire] = useState(false)
  const [manualScore, setManualScore] = useState<number>(NaN)
  const [quickViewQuestionnaire, setQuickViewQuestionnaire] = useState<QuestionnaireSummarySaved | null>(null)

  if (!(summaryPartial.questionnaire_type in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in QuestionnaireColumn: ${summaryPartial.questionnaire_type}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in QuestionnaireColumn: ${summaryPartial.questionnaire_type}\nPlease report this error to support!`
    )
  }
  const { userType, type: questionnaireType } = questionnaireKeyLookup[summaryPartial.questionnaire_type]
  const isObservationalCode = summaryPartial.questionnaire_type === 'observational-code'
  const isCustom = summaryPartial.questionnaire_type === 'custom'
  const isApplicable = isQuestionnaireApplicable({
    questionnaires,
    questionnaireKey: summaryPartial.questionnaire_type,
    booklet: summaryPartial.questionnaire_booklet,
  })

  if (!isApplicable && !isCustom) {
    return (
      <td
        style={{ background: `repeating-linear-gradient(45deg, #edf2fa, #edf2fa 10px, #e0e5f5 10px, #e0e5f5 20px)` }}
      />
    )
  }

  const handleManualScoreSubmit: ComponentProps<typeof PromptModal>['onSubmit'] = (confirm) => {
    if (!confirm) {
      setShowInputModal(false)
      return
    }
    setShowInputModal(false)
    setSavingQuestionnaire(true)
    if (!questionnaireSummary) {
      createQuestionnaire(
        {
          ...summaryPartial,
          manual_score: manualScore,
          date: new Date().toISOString().split('T')[0],
          facilitator_created: true,
          responses: [],
        },
        accessToken
      )
        .then((data) => {
          console.log('Saved!', data)
          setSavingQuestionnaire(false)
          onQuestionnaireSave()
        })
        .catch((e) => {
          setSavingQuestionnaire(false)
        })
    } else {
      updateQuestionnaire(
        {
          ...questionnaireSummary,
          manual_score: manualScore,
          date: new Date().toISOString().split('T')[0],
        },
        accessToken
      )
        .then((data) => {
          console.log('Saved!', data)
          setSavingQuestionnaire(false)
          onQuestionnaireSave()
        })
        .catch((e) => {
          setSavingQuestionnaire(false)
        })
    }
  }

  const handleOrphanAdoption: ComponentProps<typeof QuestionnaireOrphanModal>['onSelect'] = (selectedQuestionnaire) => {
    const manual_score = questionnaireSummary ? questionnaireSummary.manual_score : undefined
    setAdoptingQuestionnaire(true)
    updateQuestionnaire(
      {
        ...selectedQuestionnaire,
        manual_score,
        questionnaire_booklet: summaryPartial.questionnaire_booklet,
        mentor_id: summaryPartial.mentor_id,
        drupal_user_id: summaryPartial.drupal_user_id,
        cadet_mentor_id: summaryPartial.cadet_mentor_id,
        date: selectedQuestionnaire.date || new Date().toISOString().split('T')[0],
        name: selectedQuestionnaire.name || summaryPartial.name,
        cadet_name: selectedQuestionnaire.cadet_name || summaryPartial.cadet_name,
        facilitator_names: selectedQuestionnaire.facilitator_names || summaryPartial.facilitator_names,
      },
      accessToken
    )
      .then((data) => {
        console.log('Adopted!', data)
        if (questionnaireSummary && questionnaireSummary.facilitator_created) {
          deleteQuestionnaire(questionnaireSummary, accessToken)
            .then(() => {
              onQuestionnaireSave()
              setAdoptingQuestionnaire(false)
            })
            .catch(() => {
              Sentry.captureMessage(`Failed to delete facilitator-created questionnaire in process of adopting an existing orphaned questionnaire. ${JSON.stringify(questionnaireSummary)}`, Sentry.Severity.Error) // prettier-ignore
              onQuestionnaireSave()
              setAdoptingQuestionnaire(false)
            })
        } else {
          onQuestionnaireSave()
          setAdoptingQuestionnaire(false)
        }
      })
      .catch((e) => {
        Sentry.captureMessage(`Failed to adopt a questionnaire. ${JSON.stringify(questionnaireSummary)}`, Sentry.Severity.Error) // prettier-ignore
        setAdoptingQuestionnaire(false)
        if (e.code === 409) onQuestionnaireSave()
        alert(
          `Questionnaire linking failed` +
            (e.message ? ` because:\n${e.message}\n\n` : ` because a clash was detected.\n\n`) +
            'This page will reload now to get the latest data.'
        )
      })
  }

  const handleDeleteManualScore: ComponentProps<typeof ConfirmModal>['onSubmit'] = (confirmed) => {
    setShowDeleteManualScoreModal(false)
    if (!confirmed) return
    if (!questionnaireSummary) return // just a formality
    setDeletingManualScore(true)
    deleteQuestionnaire(questionnaireSummary, accessToken)
      .then(() => {
        setDeletingManualScore(false)
        onQuestionnaireSave()
      })
      .catch(() => {
        alert('Failed to delete questionnaire submission')
        setDeletingManualScore(false)
      })
  }

  const scoreless = scorelessQuestionnaireTypes.includes(questionnaireType)
  const parsedManualScore = parseScore(questionnaireSummary?.manual_score)
  const scoreValue: number = typeof parsedManualScore === 'number' ? parsedManualScore : manualScore
  const scoreInputFragment = scoreless ? null : (
    <>
      <TextInput
        inputSize="s"
        value={showInputModal || isNaN(scoreValue) ? '' : scoreValue.toFixed(isObservationalCode || isCustom ? 2 : 0)}
        style={{
          width: 52,
          marginLeft: 15,
          padding: '5px 12px',
          fontFamily: 'eurostile,sans-serif',
          fontWeight: 'bold',
        }}
        onFocus={(e) => {
          e.persist()
          setTimeout(() => {
            e.target.blur()
            setShowInputModal(true)
            setManualScore(scoreValue)
          }, 100)
        }}
        readOnly
      />
      {showInputModal && (
        <PromptModal
          isOpen
          label={
            <>
              {`[${summaryPartial.name}] [${questionnaireKeyLabels[summaryPartial.questionnaire_type]}${
                summaryPartial.questionnaire_booklet
                  ? ` - ${questionnaireBookletLabels[summaryPartial.questionnaire_booklet]}`
                  : ''
              }]`}
              <br />
              {questionnaireSummary &&
                ((!isCustom && questionnaireSummary.score !== null && userType !== 'cadet') ||
                  (isCustom && questionnaireSummary.manual_score !== null)) && (
                  <>
                    <br />
                    Changing the score will take precedence over the existing score:{' '}
                    {isCustom ? questionnaireSummary.manual_score : questionnaireSummary.score}
                  </>
                )}
              {isObservationalCode && (
                <>
                  <br />
                  <small>Score is a decimal number between 1 and 3</small>
                </>
              )}
            </>
          }
          onSubmit={handleManualScoreSubmit}>
          <TextInput
            type="number"
            min={isObservationalCode ? 1 : 0}
            max={isObservationalCode ? 3 : 100}
            step={isObservationalCode || isCustom ? 0.01 : 1}
            value={String(isNaN(manualScore) ? '' : manualScore)}
            onChange={(e) => {
              let value = parseFloat(e.target.value)
              const min = e.target.min ? parseFloat(e.target.min) : undefined
              const max = e.target.max ? parseFloat(e.target.max) : undefined
              if (min !== undefined && value < min) value = min
              if (max !== undefined && value > max) value = max
              setManualScore(value)
            }}
          />
        </PromptModal>
      )}
    </>
  )

  const orphanButtonFragment = (
    <>
      <Tooltip {...tooltipProps} content={`Assign an unlinked questionnaire`}>
        <CircleButton size="xs" children="+" onClick={() => setShowOrphanModal(true)} style={{ fontSize: 16 }} />
      </Tooltip>
      {showOrphanModal && (
        <QuestionnaireOrphanModal
          providerUid={summaryPartial.provider_uid}
          questionnaireType={summaryPartial.questionnaire_type || undefined}
          questionnaireBooklet={summaryPartial.questionnaire_booklet || undefined}
          onClose={() => setShowOrphanModal(false)}
          onSelect={handleOrphanAdoption}
        />
      )}
    </>
  )

  if (adoptingQuestionnaire) {
    return (
      <td>
        <Spinner color="#7D41DF" />
      </td>
    )
  }

  if (!questionnaireSummary)
    return (
      <td style={{ backgroundColor: 'rgba(0,0,0,0.15)' }}>
        {!isCustom && orphanButtonFragment}
        {scoreInputFragment}
      </td>
    )

  const { facilitator_created, score, questionnaire_type } = questionnaireSummary
  const manual_score = parseScore(questionnaireSummary.manual_score)
  const observational_code_score = parseScore(questionnaireSummary.observational_code_score)

  return (
    <td>
      {!facilitator_created && !isCustom && (
        <>
          <Tooltip {...tooltipProps} content={`Questionnaire \n(click to view/unlink)`}>
            <ButtonLink
              role="link"
              style={{
                color: '#7D41DF',
                textDecoration: typeof score === 'number' ? undefined : 'none',
                fontFamily: 'eurostile,sans-serif',
                fontWeight: 500,
              }}
              onClick={() => setQuickViewQuestionnaire(questionnaireSummary)}>
              {questionnaire_type === 'observational-code' ? (
                typeof observational_code_score === 'number' ? (
                  observational_code_score.toFixed(2)
                ) : (
                  <>&mdash; &mdash;</>
                )
              ) : typeof score === 'number' ? (
                <>
                  {(userType === 'cadet' || questionnaire_type.indexOf('feedback') >= 0) && score === 0
                    ? '✔︎'
                    : String(score)}
                </>
              ) : (
                <>&mdash; &mdash;</>
              )}
            </ButtonLink>
          </Tooltip>
          {quickViewQuestionnaire && (
            <QuestionnaireQuickViewModal
              questionnaireUserData={quickViewQuestionnaire}
              onClose={() => setQuickViewQuestionnaire(null)}
              onOrphan={() => {
                setQuickViewQuestionnaire(null)
                onQuestionnaireSave()
              }}
              onDelete={() => {
                setQuickViewQuestionnaire(null)
                onQuestionnaireSave()
              }}
            />
          )}
        </>
      )}
      {facilitator_created && !isCustom && orphanButtonFragment}
      {scoreInputFragment}
      {facilitator_created && (
        <>
          <Tooltip {...tooltipProps} content={`Reset score`}>
            <ButtonLink
              role="link"
              style={{
                color: '#7D41DF',
                textDecoration: 'none',
                fontFamily: 'eurostile,sans-serif',
                fontWeight: 500,
                padding: '0 8px',
              }}
              onClick={() => setShowDeleteManualScoreModal(true)}>
              &times;
            </ButtonLink>
          </Tooltip>
          {showDeleteManualScoreModal && (
            <ConfirmModal
              isOpen
              reverseButtons
              width={520}
              label={`Are you sure you want to reset the score for this questionnaire?`}
              onSubmit={handleDeleteManualScore}
            />
          )}
        </>
      )}
    </td>
  )
}
