/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import parse from 'date-fns/parse'
import format from 'date-fns/format'

import { NavRouteProps } from 'dashboards/types'
import { QuestionnaireSummarySaved, questionnaireKeyLabels } from 'shared/questionnaires/types'
import { SortType } from 'shared/types'

import { Page } from 'dashboards/common/Page'
import { P, Row, Padding } from 'common/ui'
import { Field } from 'common/Field'
import { SelectField } from 'common/FieldInputs'
import { Spinner } from 'common/Spinner'
import { TableContainer as _TableContainer } from './groupOverview/QuestionnaireOrphanModal'
import { QuestionnaireQuickViewModal } from './groupOverview/QuestionnaireQuickViewModal'

import { useFacDashData } from './FacilitatorDashboardState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useSessionStorage } from 'utils/useStorage'
import { Link } from 'react-router-dom'
import { facilitatorDashboardBaseUrl } from './constants'

const ALL = '_ALL_'
const sortSymbols: { [key in SortType]: string } = { ascending: '▲', descending: '▼' }
type SortColumns = Extract<
  keyof QuestionnaireSummarySaved,
  'date' | 'provider_uid' | 'questionnaire_type' | 'questionnaire_booklet' | 'cadet_name' | 'name' | 'facilitator_names'
>

export const OrphanedQuestionnaires: React.FC<NavRouteProps> = ({ route }) => {
  const [providers, loadingProviders] = useFacDashData('providers', [])
  const [providerUid, setProviderUid] = useState<string | null>(null)
  const [quickViewQuestionnaire, setQuickViewQuestionnaire] = useState<QuestionnaireSummarySaved | null>(null)
  const [questionnaires, { loading: loadingQuestionnaires, fetch: refetch }] = useEndpoint<QuestionnaireSummarySaved[]>(
    !loadingProviders && providerUid !== null
      ? `/api/v1/questionnaires/orphaned?${qs.stringify(
          {
            provider_uid: providerUid || providers.map(({ id }) => id),
          },
          { arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`
      : null,
    []
  )

  const [sortColumn, setSortColumn] = useSessionStorage<SortColumns>('sortColumn_facdashOrphanedQuestionnaires', 'date')
  const [sortDirection, setSortDirection] = useSessionStorage<SortType>(
    'sortDir_facdashOrphanedQuestionnaires',
    'descending'
  )

  useEffect(() => {
    if (providers.length > 0) setProviderUid(providers[0].id)
  }, [providers])

  const handleColumnHeaderSelect = (column: SortColumns) => {
    if (column !== sortColumn) setSortColumn(column)
    else setSortDirection((v) => (v === 'ascending' ? 'descending' : 'ascending'))
  }

  const handleDeleteQuestionnaire = (questionnaire: QuestionnaireSummarySaved) => {
    setQuickViewQuestionnaire(null)
    refetch()
  }

  const providerFilterOptions = [
    { label: 'All Providers', value: ALL },
    ...providers.map((provider) => ({ label: provider.name, value: provider.id })),
  ]

  const sortedQuestionnaires = [...(questionnaires || [])].sort((a, b) => {
    const valA =
      sortColumn === 'provider_uid'
        ? providers.find(({ id }) => id === a.provider_uid)?.name || a[sortColumn]
        : a[sortColumn]
    const valB =
      sortColumn === 'provider_uid'
        ? providers.find(({ id }) => id === b.provider_uid)?.name || b[sortColumn]
        : b[sortColumn]
    return valA === valB || valA === undefined || valB === undefined
      ? 0
      : (valA < valB ? -1 : 1) * (sortDirection === 'ascending' ? 1 : -1)
  })

  const renderColumnHeader = (column: SortColumns, label: string, fixedWidth?: number) => (
    <div
      style={{ cursor: 'pointer', ...(fixedWidth ? { width: fixedWidth } : {}) }}
      onClick={() => handleColumnHeaderSelect(column)}>
      {label} {sortColumn === column && sortSymbols[sortDirection]}
    </div>
  )

  return (
    <>
      <Page route={route}>
        <P>
          The following list of unlinked questionnaires were completed via 'DO IT NOW' or 'GET LINK &amp; SHARE'
          administration method for Questionnaire Booklets 1, 2, 3 or 4. They are all unlinked questionnaires initiated
          by any Facilitator in your SAS Provider network.
        </P>
        <P>
          <u>Quick Tips:</u>
          <ul>
            <li>
              During the intake process you may have questionnaires waiting in the below unlinked list until the group
              is created and the Cadet Place is activated.
            </li>
            <li>
              To link Questionnaires to a Cadet in a group that you are the Lead Facilitator for, go to the 'Cadet
              Overview' for your group [<Link to={facilitatorDashboardBaseUrl + '/groups'}>My Groups</Link>] and select
              the 'Assessment' tab.
            </li>
            <li>
              To find or sort unlinked submissions, use the 'Filter Provider' option and sort by any column title
              (ascending or descending order) by selecting the title.
            </li>
            <li>
              To review and delete an incorrect or void submission, select the item and select 'Delete'. If you must
              retain a copy for your records, click 'Print/Download' before deleting.
            </li>
          </ul>
        </P>
        <div style={{ maxWidth: 420 }}>
          <Field label="Filter by SAS Provider">
            <SelectField
              empty={false}
              options={providerFilterOptions}
              value={providerUid || ALL}
              onChange={(value) => setProviderUid(value === ALL ? null : value)}
            />
          </Field>
        </div>
        <TableContainer>
          <Row>
            {renderColumnHeader('provider_uid', 'Provider')}
            {renderColumnHeader('questionnaire_type', 'Type')}
            {renderColumnHeader('questionnaire_booklet', 'Booklet', 60)}
            {renderColumnHeader('cadet_name', 'Cadet Name')}
            {renderColumnHeader('name', 'Respondent')}
            {renderColumnHeader('facilitator_names', 'Facilitator')}
            {renderColumnHeader('date', 'Date')}
          </Row>
          <div className="row-container" style={{ maxHeight: '60vh', overflow: 'auto' }}>
            {loadingQuestionnaires || loadingProviders || questionnaires === null ? (
              <Padding size="m">
                <Spinner color="#7D41DF" />
              </Padding>
            ) : !questionnaires.length ? (
              <P align="center">
                There are currently no unlinked questionnaires
                {providerUid && ` belonging to this SAS Provider`}
              </P>
            ) : (
              sortedQuestionnaires.map((questionnaire, i) => {
                const {
                  id,
                  provider_uid,
                  questionnaire_type,
                  questionnaire_booklet,
                  name,
                  cadet_name,
                  facilitator_names,
                  date,
                  modified,
                } = questionnaire
                return (
                  <Row key={id} onClick={() => setQuickViewQuestionnaire(questionnaire)}>
                    <div>{providers.find(({ id }) => id === provider_uid)?.name}</div>
                    <div>{questionnaireKeyLabels[questionnaire_type]}</div>
                    <div style={{ width: 60 }}>{questionnaire_booklet}</div>
                    <div>{cadet_name}</div>
                    <div>{name}</div>
                    <div>{facilitator_names}</div>
                    <TextHoverToggle
                      data-text={`Created: \n${date}`}
                      data-hover-text={
                        modified
                          ? `Last Modified: \n${format(
                              parse(modified, `yyyy-MM-dd'T'HH:mm:ssxxx`, Date.now()),
                              'yyyy-MM-dd - h:mma'
                            )}`
                          : `Created: ${date}`
                      }
                    />
                  </Row>
                )
              })
            )}
          </div>
        </TableContainer>
      </Page>
      {quickViewQuestionnaire && (
        <QuestionnaireQuickViewModal
          isOrphan
          disableOrphan
          questionnaireUserData={quickViewQuestionnaire}
          onClose={() => setQuickViewQuestionnaire(null)}
          onDelete={() => handleDeleteQuestionnaire(quickViewQuestionnaire)}
        />
      )}
    </>
  )
}

const TableContainer = styled(_TableContainer)`
  & ${Row} {
    & > div {
      width: calc(calc(100% - 60px) / 6);
      padding: 5px;
      font-size: 14px;
    }
  }
  & > ${Row} {
    background-color: #e9dcff;
    color: #6737b5;
  }
  & .row-container ${Row} > div {
    font-size: 12px;
  }
`

const TextHoverToggle = styled.div`
  &::before {
    content: attr(data-text);
    white-space: pre;
  }
  &:hover::before {
    content: attr(data-hover-text);
  }
`
