import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SVG } from 'common/SVG'
import { fontRegular } from 'fonts'
import { Row } from 'common/ui'
import { NavRoute } from 'dashboards/types'
import { Link, matchPath, useLocation } from 'react-router-dom'

export const SidebarNavItem: React.FC<{
  route: NavRoute
  shouldHide: (shouldHideFunc: Exclude<NavRoute['inaccessible'], boolean | undefined>) => boolean
  baseUrl?: string
}> = ({ route, shouldHide, baseUrl = '' }) => {
  const location = useLocation()
  const { title, sidebarTitle, icon, path, exact, subpages = [] } = route
  const rootActivate = matchPath(location.pathname, { path: baseUrl + path, exact: true })
  const anyActive = matchPath(location.pathname, {
    path: baseUrl + path,
    exact: subpages.length > 0 ? undefined : exact,
  })
  const childActive = anyActive && !rootActivate
  const [expanded, setExpanded] = useState<boolean>(() => !!rootActivate || !!childActive)
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (subpages.length > 0) {
      // if (!(childActive && expanded)) setExpanded(v => !v)
      if (!expanded) setExpanded(true)
      if (rootActivate) e.preventDefault()
    }
  }
  useEffect(() => {
    if (!rootActivate && expanded) setExpanded(false)
  }, [rootActivate, expanded])
  return (
    <>
      <SidebarNavItemWrapper to={baseUrl + path} active={anyActive} onClick={handleClick}>
        <div>
          <SVG {...icon} />
        </div>
        <span children={sidebarTitle || title} />
      </SidebarNavItemWrapper>
      {(expanded || childActive) && (
        <>
          {subpages
            // @ts-ignore
            .filter(
              (subpage) =>
                !subpage.inaccessible ||
                (typeof subpage.inaccessible === 'function' && !shouldHide(subpage.inaccessible))
            )
            // @ts-ignore
            .map((subpage, i) => {
              const subpageActive = matchPath(location.pathname, {
                path: baseUrl + path + subpage.path,
                exact: (subpage.subpages || []).length > 0 ? undefined : subpage.exact,
              })
              return (
                <SidebarNavItemWrapper
                  key={i}
                  to={baseUrl + path + subpage.path}
                  active={subpageActive}
                  style={{ paddingLeft: 48, fontSize: 14 }}>
                  <div>{listSvgFragment}</div>
                  <span children={subpage.sidebarTitle || subpage.title} />
                </SidebarNavItemWrapper>
              )
            })}
        </>
      )}
    </>
  )
}

const listSvgFragment = (
  <SVG
    width={8}
    height={10}
    viewboxWidth={8}
    viewboxHeight={10}
    children={
      <path
        d="M7.5 4.13397C8.16667 4.51888 8.16667 5.48113 7.5 5.86603L1.5 9.33013C0.833334 9.71503 5.06336e-07 9.2339 5.39985e-07 8.4641L8.42827e-07 1.5359C8.76476e-07 0.766098 0.833334 0.284973 1.5 0.669873L7.5 4.13397Z"
        fill="#7D41DF"
      />
    }
  />
)

const SidebarNavItemWrapper = styled((props) => <Row {...props} as={Link} />)<{ active: boolean }>`
  ${fontRegular}
  box-sizing: border-box;
  height: 45px;
  padding: 10px 15px;
  align-items: center;
  border-bottom: 1px solid #cdd2e4;
  line-height: 1;
  text-decoration: none !important;
  cursor: pointer;
  color: ${(p) => (p.active ? '#7D41DF' : '#011A46')};
  & svg {
    margin-right: 12px;
    & path {
      fill: ${(p) => (p.active ? '#925BED' : '#011A46')};
    }
  }
`
