/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { NavRouteProps } from 'dashboards/types'

import { Spacer, P, Column, H3, Button, Row } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells } from 'dashboards/common/Cell'
import { SessionCell } from 'dashboards/common/SessionCell'

import { sessionsFilterFuture } from 'dashboards/utils/reducers'
import { useMentorDashData, useMentorDashState } from './MentorDashboardState'
import { InlineCards, InlineCard } from 'dashboards/common/InlineCard'
import { HeroImage } from 'dashboards/common/HeroImage'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { ProductKey } from 'shared/dashboard/types'
import { intersperseSpacers } from 'utils/intersperseSpacers'

export const Home: React.FC<NavRouteProps> = ({ route }) => {
  const {
    mentorUid,
    endpoints: {
      group: { value: group, loading: loadingGroup },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions', [])
  const [mentor] = useMentorDashData('mentor')
  const [primaryMentor] = useMentorDashData('primaryMentor')

  const futureSessions = sessionsFilterFuture(sessions || [])

  const buttons = []

  if (primaryMentor && !primaryMentor?.product_key_expired && mentor?.permission_skill_tracker) {
    buttons.push(
      <AnchorButton
        key={0}
        href={`${process.env.REACT_APP_CADET_URL}/mentor/skill-tracker/${primaryMentor.product_key_uid}`}
        target="_blank">
        Open Skill Tracker
      </AnchorButton>
    )
  }

  if (!primaryMentor?.product_key_expired && mentor?.permission_gadget_pack) {
    buttons.push(
      <LinkButton key={1} to={`/mentor/gadget-pack`}>
        Open Cadet Gadget Pack
      </LinkButton>
    )
  }

  return (
    <Page route={route}>
      <HeroImage />
      <Spacer />

      {buttons.length > 0 && (
        <>
          <Spacer size="l" />
          <Row>{intersperseSpacers(buttons)}</Row>
          <Spacer />
        </>
      )}

      {/*
      <P style={{ maxWidth: 360, margin: 0 }}>
        Ullamcorper rhoncus pretium, tellus massa elit sit elit. Sagittis, vitae enim porta placerat amet magna turpis
        auctor vitae.
      </P>
      <Spacer size="l" />
      <HeaderHr children="Items" />
      <InlineCards>
        <InlineCard image={{ id: -1, filename: 'about:blank', created: '', modified: '' }}>
          <Column flex="1 1 auto">
            <H3 children={`Introduction`} />
            <P>
              Pulvinar quis vitae sed mattis lectus pellentesque at adipiscing maretra lectus tristique nulla adipiscing
              id.
            </P>
            <Spacer size="m" />
          </Column>
          <Column flex="none">
            <div>
              <Button type="button" size="s" theme="dark" children="Play" />
            </div>
          </Column>
        </InlineCard>
        <InlineCard image={{ id: -1, filename: 'about:blank', created: '', modified: '' }}>
          <Column flex="1 1 auto">
            <H3 children={`How to: Use your Dashboard`} />
            <P>
              Pulvinar quis vitae sed mattis lectus pellentesque at adipiscing maretra lectus tristique nulla adipiscing
              id.
            </P>
            <Spacer size="m" />
          </Column>
          <Column flex="none">
            <div>
              <Button type="button" size="s" theme="dark" children="Play" />
            </div>
          </Column>
        </InlineCard>
      </InlineCards>
      <Spacer size="l" />
      <HeaderHr
        sideRoute={`${baseUrl}/meetings`}
        children={<span style={{ color: '#4EBE40' }}>Scheduled Mentor Meetings</span>}
      />
      <Cells>
        {futureSessions.length > 0 ? (
          futureSessions.map((session, i) => (
            <SessionCell key={session.id || i} userType="mentor" mentorUid={mentorUid || undefined} session={session} group={group} />
          ))
        ) : loadingSessions ? (
          <SpinnerWithLabel color="#858cac" label="One moment please..." />
        ) : (
          <P>No upcoming meetings.</P>
        )}
      </Cells>
      */}
    </Page>
  )
}
