import { JSONValueType } from '../session/types'
import { QuestionnaireResponse, QuestionnaireSummary, QuestionnaireUserDataStatus } from './types'

export const UPDATE_STATE = 'UPDATE_QUESTIONNAIRE_STATE'
export const UPDATE_OVERVIEW = 'UPDATE_OVERVIEW'
export const UPDATE_TEXT_VALUE = 'UPDATE_TEXT_VALUE'
export const UPDATE_OPTION_VALUE = 'UPDATE_OPTION_VALUE'

export interface QuestionnaireBaseAction {}

export interface UpdateStateAction extends QuestionnaireBaseAction {
  type: typeof UPDATE_STATE
  state: QuestionnaireSummary
  userDataStatus?: QuestionnaireUserDataStatus
}

export interface UpdateQuestionnaireOverviewAction extends QuestionnaireBaseAction {
  type: typeof UPDATE_OVERVIEW
  field: keyof QuestionnaireSummary
  value: QuestionnaireSummary[this['field']]
}

export interface UpdateQuestionnaireResponseInputAction extends QuestionnaireBaseAction {
  type: typeof UPDATE_TEXT_VALUE
  unique_key: string
  value: JSONValueType
}

export interface UpdateQuestionnaireResponseOptionAction extends QuestionnaireBaseAction {
  type: typeof UPDATE_OPTION_VALUE
  data: QuestionnaireResponse
  updateObservationalCodeScore?: boolean
}

export type ActionTypes =
  | UpdateStateAction
  | UpdateQuestionnaireOverviewAction
  | UpdateQuestionnaireResponseInputAction
  | UpdateQuestionnaireResponseOptionAction
